import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import Footer from "../../../components/Footer/Footer";
import { connect } from "react-redux";
import HeaderDesktop from "../../../components/Desktop/Header/Header";
import SocialMedia from "../../../components/Helpers/SocialMedia";
import Footer from "../../../components/Footer/Footer";
import Routes from "../../../helpers/Routes";
import "./Page404.css";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      muted: true,
      playing: true
    };
  }

  onStartVideo(event) {
    if (this.state.muted === true) {
      var that = this;
      setInterval(function () {
        that.setState({
          muted: false
        });
        that.setState({
          playing: true
        });
      }, 4000);
    }
  }

  render() {
    return (
      <div className="App">
        <HeaderDesktop />
        <br />
        <br />
        <Container className="mainContentContainer">
          <Row style={{ textAlign: "center" }}>
            <Col xs="12" sm="12" md="12" lg="12" style={{ padding: "0px" }}>

              {/* Primeiro texto */}
              <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                  <span className="text404 boldText404">Verificamos que não encontrou o que pretendia.</span>
                </Col>
              </Row>

              {/* Segundo texto */}
              <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                  <span className="text404">
                    Para nos ajudar a resolver o problema, envie um email para o nosso suporte
                    <a className="boldText404" href="mailto:ihavethepower@solfut.com?Assunto=Erro%20Pagina404">
                      {' '} ihavethepower@solfut.com
                    </a>
                  </span>
                </Col>
              </Row>

              <span className="customSpacing404" />

              {/* Imagem */}
              <Row className="imgParent">
                <Col xs="12" sm="12" md="12" lg="12" xl={9} style={{ padding: "0px" }}>
                  <div className="imgContainer">
                    <img className="img404" src='https://ihavethepower.net/wp-content/uploads/2021/03/ihavethepower-404.jpg' />
                  </div>
                </Col>
              </Row>

              <span className="customSpacing404" />
              <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                  <span className="text404 boldText404">Obrigado pela sua disponibilidade</span>
                </Col>
              </Row>
              <br />

              <SocialMedia>
              </SocialMedia>

              <br></br>

              <span className="smallerText404">
                Aproveite a oportunidade de saber mais{" "}
                <a className="boldText404" href={Routes.empresa.sobreNos}>sobre nós</a>,
                <br />
                melhorar o seu dia com as nossas{" "}
                <a className="boldText404" href={Routes.recursosGratis.frases}>Frases inspiradoras</a>
                <br /> e de comprar algo na nossa{" "}
                <a className="boldText404" href={Routes.shop.home}>Loja</a>
              </span>
            </Col>
          </Row>
          <Footer />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(Register);
