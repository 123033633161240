import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from "react-intl";

export default class BottomMenuItem extends Component {

    render() {
        const { link, labelId } = this.props;
        return (
            <a href={link} style={{ color: "rgba(3, 66, 117, 1.0)" }} class="bp3-md-menu-frases-poderosas">
                <FormattedMessage id={labelId} />
            </a>
        )
    }
}
