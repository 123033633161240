import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useCheckoutDisabledInputs = () => {

    const user = useSelector((state: any) => state.user);
    const loginOrRegisterInCheckout = useSelector((state: any) => state.loginOrRegisterInCheckout);
    const isGuestCheckout = useSelector((state: any) => state.isGuestCheckout);
    const [disabledFields, setDisabledFields] = useState<boolean>(false);

    useEffect(() => {
        if (isGuestCheckout) {
            setDisabledFields(false);
            return;
        }

        if (user.userID) {
            setDisabledFields(false);
            return;
        }

        if (loginOrRegisterInCheckout?.toUpperCase() == "LOGIN") {
            setDisabledFields(true);
            return;
        }

        if (loginOrRegisterInCheckout?.toUpperCase() == "REGISTER") {
            setDisabledFields(false);
            return;
        }

        if (loginOrRegisterInCheckout?.toUpperCase() == "") {
            setDisabledFields(true);
            return;
        }

    }, [loginOrRegisterInCheckout, user])

    return { disabledFields };
}

export default useCheckoutDisabledInputs;