export enum Type {
    text,
    currency
}

type LabelType = {
    type: Type.text
}
    | (
        {
            type: Type.currency,
            currency: string
        }
    )

export interface LabelProps {
    text: string,
    labelType: LabelType
}

export default function Label(props: LabelProps) {
    return (
        <span>
            {props.labelType.type === Type.currency &&
                props.labelType.currency} {" "}
            {props.text}
        </span>
    )
}