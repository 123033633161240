import { Component } from 'react';
import CrossDomainEventHandler from "../../helpers/CrossDomainEventHandler";

class ListeningToEvent extends Component {

    constructor(props) {
        super(props);

        CrossDomainEventHandler.startListeningToChanges();
    }

    componentWillUnmount() {
        CrossDomainEventHandler.stopListeningToChanges();
    }

    render() {
        return (null);
    }
}

export default ListeningToEvent;