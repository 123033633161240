import React, { Component } from "react";
import { connect } from "react-redux";
import { sideBarMenu } from "../../../actions/miniCartActions";
import Routes from "../../../helpers/Routes";
import MainMenuIcon from "./Icons/MainMenuIcon";

class LogoHeader extends Component {

  constructor(props) {
    super(props);
    this.openSideBar = this.openSideBar.bind(this);
  }

  openSideBar() {
    this.props.dispatch(sideBarMenu(true));
  }

  render() {
    return (
      <span className="headerTitleSubContainer">
        <span className="headerMainMenuButton" onClick={this.props.onClick}>
          <MainMenuIcon color={"#fff"} />
        </span>
        <a href={Routes.home.home}>
          <img
            alt="logo"
            src="/assets/images/logo-ihtp.svg"
            className="logoimage"
            width="auto"
            style={{
              maxWidth: "95%"
            }}
          />
        </a>
      </span>
    );
  }
}


const mapStateToProps = state => {
  return {
  };
};

export default connect(mapStateToProps)(LogoHeader);