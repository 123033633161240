import { FormattedMessage, FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";
import Label, { Type } from "../../../Common/Label";

export default function CartTotal() {
    const cart = useSelector((state: any) => state.cart);
    const user = useSelector((state: any) => state.user);
    const currencies = useSelector((state: any) => state.currencies);
    const currency = currencies.selectedDescription;

    return (
        <>
            {/* Total */}
            <div>
                {/* Total label */}
                <FormattedMessage id="shop.cart.carrinho.total" />:{" "}

                {/* Total value */}
                <span style={{ fontSize: "20px" }}>
                    <Label
                        text={cart.Total.toFixed(2)}
                        labelType={{
                            type: Type.currency,
                            currency: currency
                        }} />
                </span>
            </div>

            {/* Poupou */}
            {cart.Descontos && cart.Descontos > 0 ? (
                <div style={{ color: "green", fontWeight: "400" }}>

                    {/* Poupou label */}
                    <span style={{ fontSize: "18px" }}>
                        <FormattedMessage id="shop.cart.carrinho.poupar" />{": "}
                    </span>

                    {/* Poupou value */}
                    <span style={{ fontSize: "18px" }}>
                        <Label
                            text={cart.Descontos.toFixed(2)}
                            labelType={{
                                type: Type.currency,
                                currency: currency
                            }} />
                    </span>
                </div>
            ) : null}

            {/* Créditos ou NetPoints*/}
            {["CS", "CT"].includes(user.userType) ? (
                <div style={{ marginBottom: "15px", color: "green" }}>

                    {/* Label Mostrar NetPoints da Encomenda */}
                    <span style={{ fontSize: "15px" }}>
                        <FormattedMessage id="shop.cart.carrinho.netpoints" />{": "}
                    </span>

                    {/* Mostrar valor dos netpoints*/}
                    <span style={{ fontSize: "15px" }}>
                        <FormattedNumber
                            value={cart.Netpoints}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </span>
                </div>
            ) : (
                <div style={{ marginBottom: "15px", color: "green" }}>

                    {/* Label Mostrar Créditos da Encomenda */}
                    <span style={{ fontSize: "15px" }}>
                        <FormattedMessage id="shop.cart.carrinho.creditos" />{": "}
                    </span>

                    {/* Mostrar valor dos Créditos*/}
                    <span style={{ fontSize: "15px" }}>
                        <Label
                            text={(cart.Creditos ?? 0).toFixed(2)}
                            labelType={{
                                type: Type.currency,
                                currency: currency
                            }} />
                    </span>
                </div>
            )}
        </>
    )
}