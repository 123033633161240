import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { logout } from "../../../../actions/miniCartActions";
import CrossDomainEventHandler from "../../../../helpers/CrossDomainEventHandler";
import LogoutIcon from "../Icons/LogoutIcon";

class LogoutButton extends Component {
  logoutAccount() {

    this.props.dispatch(logout()).then(() => {
      CrossDomainEventHandler.sendEvent();
      window.location = "/Carrinho"
    });
  }
  render() {
    return (
      <span onClick={() => this.logoutAccount()} className="headerLogoutButtonContainer">
        <LogoutIcon />
        <a className="headerLogoutTitle" style={{ color: "#ffffff" }}><FormattedMessage id="header.Sair" /></a>
      </span>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

export default connect(mapStateToProps)(LogoutButton);
