import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { default as Routes } from "../../../../helpers/Routes";

export default class ErrorLoadingCart extends Component {
    render() {
        return (
            <div style={{ marginTop: "50px" }}>
                <Row>
                    <Col>
                        <h3>{this.props.errorMessage}</h3>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <NavLink to={Routes.contactos.contactos}>
                            <Button
                                aria-label="Increment"
                                style={{
                                    backgroundColor: "#ca006a",
                                    border: "none",
                                    minWidth: isMobile ? "50%" : "20%"
                                }}>
                                <FormattedMessage id="shop.cart.carrinho.contactar" />
                            </Button>
                        </NavLink>
                    </Col>
                </Row>
            </div>
        )
    }
}
