import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import $ from "jquery";
import FlatButton from "material-ui/FlatButton";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { addShippingAddressCheckout, getShippingAddresses, removeShippingAddress, setShippingAddress, setShippingAddressAsDefault } from "../../../actions/miniCartActions";
import Toast from "../../../helpers/Toast";
import { AddressDTO } from "../../../models/Address/AddressDTO";
import { HasError, ShowOrHideAddShippingAddress, ShowOrHideShippingAddressButtons, ShowOrHideShippingAddresses, ValidateAndAddShippingAddress } from "../../../Utils/UtilsCheckout";
import IHTPButton from "../../Common/IHTPButton";
import CreateShippingAddres from "./CreateShippingAddress";
import EditShippingAddress, { EditShippingDTO } from "./EditShippingAddress";

const customStyles = {
	buttons: {
		minWidth: "100%"
	}
}

export interface MoradaDTO {
	id_cliente_morada: string,
	nome: string,
	nif: string,
	morada: string,
	localidade: string,
	codigoPostal: string,
	principal: string,
	id_pais: string,
	ID_Tipo_Morada: string
}

export default function DadosEnvio() {
	const novaMoradaInitialState = {
		id_cliente_morada: "",
		nome: "",
		nif: "",
		morada: "",
		localidade: "",
		codigoPostal: "",
		principal: "N",
		id_pais: "",
		ID_Tipo_Morada: ""
	}
	const [novaMorada, setNovaMorada] = useState(novaMoradaInitialState);
	const [justChoosingAddress, setJustChoosingAddress] = useState<boolean>(true);
	const [isCopyingAddressLoading, setIsCopyingAddressLoading] = useState<boolean>(false);
	const [adicionar, setAdicionar] = useState<boolean>(true);
	const [remover, setRemover] = useState<boolean>(false);
	const [alterar, setAlterar] = useState<boolean>(false);
	const [hasError, setHasError] = useState<boolean>(false);
	const [editShippingDTO, setEditShippingDTO] = useState<EditShippingDTO>(new EditShippingDTO());
	const [canCopyAddress, setCanCopyAddress] = useState<boolean>(true);
	const [loadingSetAsDefault, setLoadingSetAsDefault] = useState<boolean>(false);
	const [loadingRemove, setLoadingRemove] = useState<boolean>(false);

	const deliveryAddresses = useSelector((state: any) => state.deliveryAddresses);
	const billingAddresses = useSelector((state: any) => state.billingAddresses);
	const errorCode = useSelector((state: any) => state.errorCode);
	const confirmOrderClicked = useSelector((state: any) => state.checkoutData.confirmOrderClicked);
	const selectedBillingAddressId = useSelector((state: any) => state.checkoutData.billingAddress);
	const selectedShippingAddressId = useSelector((state: any) => state.checkoutData.shippingAddress);
	const selectedShippingTypeExternal = useSelector((state: any) => state.checkoutData.TipoTransporteExterno ?? "S");
	const numberOfPhysicalProducts: number = useSelector((state: any) => state.checkoutData.TemProdutosFisicos);

	const intl = useIntl();
	const dispatch = useDispatch();

	useEffect(() => {
		loadDefaultDeliveryAddress();
	}, []);

	const loadDefaultDeliveryAddress = async () => {
		const tmpDeliveryAddresses: any = await dispatch(getShippingAddresses());
		const shippingAddressId = calculateDefaultShippingAddressId(tmpDeliveryAddresses);
		dispatch(addShippingAddressCheckout(shippingAddressId));
	}

	useEffect(() => {
		var updateShippinhAddress = async () => {
			var moradaDTO: MoradaDTO = {
				id_cliente_morada: novaMorada.id_cliente_morada,
				nome: novaMorada.nome,
				nif: novaMorada.nif,
				morada: novaMorada.morada,
				localidade: novaMorada.localidade,
				codigoPostal: novaMorada.codigoPostal,
				principal: novaMorada.principal,
				id_pais: novaMorada.id_pais,
				ID_Tipo_Morada: novaMorada.ID_Tipo_Morada
			}

			await dispatch(setShippingAddress(moradaDTO))
		}

		updateShippinhAddress();
	}, [novaMorada])

	const currentShippingAddress: null | AddressDTO = useMemo(() => {

		if (typeof deliveryAddresses == "undefined" || deliveryAddresses == null || Array.isArray(deliveryAddresses) === false || deliveryAddresses.length <= 0) return null;

		let selectedAddress = deliveryAddresses.find(a => a.ID_Cliente_Morada == selectedShippingAddressId);
		if (selectedAddress == null) return null;

		var newAddress: AddressDTO = {
			nome: selectedAddress.Nome,
			morada: selectedAddress.Morada,
			localidade: selectedAddress.Localidade,
			codigoPostal: selectedAddress.CodigoPostal,
			id_pais: selectedAddress.ID_Pais,
			ID_Tipo_Morada: selectedAddress.ID_Tipo_Morada,
			principal: selectedAddress.Principal
		}

		dispatch(setShippingAddress(newAddress))

		return newAddress;
	}, [selectedShippingAddressId, deliveryAddresses])

	const blockOrNotOtherControls = (value) => {

		switch (value) {
			case 1:
				/*$(".breadCrumbRow").addClass("disabledBox");
				$(".dadosFaturacaoCol").addClass("disabledBox");
				$(".formasEnvioCol").addClass("disabledBox");
				$(".tiposPagamentoCol").addClass("disabledBox");
				$(".codigosPromocionaisCol").addClass("disabledBox");
				$(".valesCol").addClass("disabledBox");
				$(".creditosCol").addClass("disabledBox");
				$(".observacaoCol").addClass("disabledBox");
				$(".totalCol").addClass("disabledBox");*/
				break;

			default:
				/*$(".breadCrumbRow").removeClass("disabledBox");
				$(".dadosFaturacaoCol").removeClass("disabledBox");
				$(".formasEnvioCol").removeClass("disabledBox");
				$(".tiposPagamentoCol").removeClass("disabledBox");
				$(".codigosPromocionaisCol").removeClass("disabledBox");
				$(".valesCol").removeClass("disabledBox");
				$(".creditosCol").removeClass("disabledBox");
				$(".observacaoCol").removeClass("disabledBox");
				$(".totalCol").removeClass("disabledBox");*/
				break;
		}
	}

	const handleChangeShippingAddress = (event, index, value) => {
		if (!remover) {
			setRemover(true);
		}
		if (!alterar) {
			setAlterar(true);
		}

		//Segundo parametro a true (reloadOrder) para atualizar sempre com os portes corretos ao alterar a morada
		dispatch(addShippingAddressCheckout(value, true));
	};

	const handleRemoveShippingAddress = async (e) => {
		e.stopPropagation();

		setLoadingRemove(true);
		var data: any = await dispatch(removeShippingAddress(selectedShippingAddressId));
		setLoadingRemove(false);

		if (data.data.success !== true) {
			Toast.Show("error", data.data.message);
			return;
		}

		const newShippingAddressId = calculateDefaultShippingAddressId(data.moradas);
		dispatch(addShippingAddressCheckout(newShippingAddressId));

		Toast.Show("success", data.data.message);
	}

	useEffect(() => {
		const tmpHasError = HasError(errorCode, ["Envio"]);
		setHasError(tmpHasError);
	}, [errorCode]);

	useEffect(() => {
		if (hasError === true) {
			let el = document.getElementById("shippingAddress");
			if (el != null) el.scrollIntoView({ block: "center" });
		}
	}, [hasError]);

	useEffect(() => {

		//Se existirem produtos físicos e o tipo de transporte for externo, então ativamos as opções
		if (numberOfPhysicalProducts !== 0 && (selectedShippingTypeExternal != null && selectedShippingTypeExternal === "S")) {

			//Se não existirem moradas mostramos opção de criar uma
			if (deliveryAddresses.length <= 0) {
				onOpenCreateShippingAddress();
			} else {

				//Se já tiver moradas, mostramos opção base
				ShowOrHideShippingAddressButtons(1);
				ShowOrHideAddShippingAddress(0);
				ShowOrHideShippingAddresses(1);
				setAdicionar(true);
				setAlterar(true);
				setRemover(true);
				setJustChoosingAddress(true);
			}
		}

	}, [selectedShippingTypeExternal, deliveryAddresses])

	useEffect(() => {
		checkAndSetCanCopy();
	}, [billingAddresses])

	const checkAndSetCanCopy = () => {
		if (typeof billingAddresses == "undefined" || billingAddresses == null || billingAddresses.length <= 0) {
			setCanCopyAddress(false);
		} else {
			setCanCopyAddress(true);
		}
	}

	const calculateDefaultShippingAddressId = (addresses): number => {
		if (typeof addresses === "undefined" || addresses == null || Array.isArray(addresses) === false || addresses.length <= 0) return 0;

		var defaultAddress = addresses.find(a => a.Principal === "S");
		if (defaultAddress != null) {
			return defaultAddress.ID_Cliente_Morada;
		}

		return addresses[addresses.length - 1].ID_Cliente_Morada;
	}

	const handleCopyBillingAddress = async () => {

		if (typeof billingAddresses === "undefined" || billingAddresses == null || billingAddresses.length <= 0) return;

		var morada = billingAddresses.find(a => a.ID_Cliente_Morada == selectedBillingAddressId);
		if (typeof morada === "undefined" || morada == null) return;

		const newAddress: AddressDTO = {
			id_cliente_morada: morada.ID_Cliente_Morada,
			nome: morada.Nome,
			morada: morada.Morada,
			localidade: morada.Localidade,
			codigoPostal: morada.CodigoPostal,
			principal: morada.Principal,
			id_pais: morada.ID_Pais,
			ID_Tipo_Morada: "R"
		}

		setAdicionar(false);
		setRemover(false);
		setAlterar(false);
		setIsCopyingAddressLoading(true);
		const result: any = await ValidateAndAddShippingAddress(newAddress, intl, dispatch, deliveryAddresses);
		setIsCopyingAddressLoading(false);
		setAdicionar(true);
		setRemover(true);
		setAlterar(true);

		if (result.success === false) {
			Toast.Show("error", result.message);
			return;
		}
	}

	const onOpenCreateShippingAddress = () => {
		setAlterar(false);
		setRemover(false);
		setAdicionar(false);
		setJustChoosingAddress(false);

		ShowOrHideShippingAddressButtons(0);
		ShowOrHideAddShippingAddress(1);
		ShowOrHideShippingAddresses(0);

		blockOrNotOtherControls(1);
		dispatch(setShippingAddress(novaMoradaInitialState));
	};

	const onCloseCreateShippingAddress = () => {

		ShowOrHideShippingAddressButtons(1);
		ShowOrHideAddShippingAddress(0);
		ShowOrHideShippingAddresses(1);
		blockOrNotOtherControls(0);

		setAdicionar(true);
		setAlterar(true);
		setRemover(true);
		checkAndSetCanCopy();
		setJustChoosingAddress(true);
	}

	const onOpenEditShippingAddress = (e) => {
		e.stopPropagation();

		setAlterar(false);
		setRemover(false);
		setAdicionar(false);
		setCanCopyAddress(false);
		setJustChoosingAddress(false);

		let newEdit = new EditShippingDTO();
		newEdit.isOpen = true;
		newEdit.shippingAddressId = parseInt(selectedShippingAddressId);
		setEditShippingDTO(newEdit);

		var item = $(".shippingModifyAddress");
		var list = $(".shippingAddresses");

		item.addClass("is-visible");
		list.addClass("is-not-visible");

		ShowOrHideShippingAddressButtons(0)

		blockOrNotOtherControls(1);

		setTimeout(function () {
			item.addClass("is-open");
		}, 20);
	}

	const onCloseEditShippingAddress = () => {
		setAdicionar(true);
		setAlterar(true);
		setRemover(true);
		checkAndSetCanCopy();
		setJustChoosingAddress(true);

		var item = $(".shippingModifyAddress");
		var list = $(".shippingAddresses");

		item.removeClass("is-open");

		ShowOrHideShippingAddressButtons(1)

		item.one("transitionend", function (e) {
			item.removeClass("is-visible");
			list.removeClass("is-not-visible");
		});
		blockOrNotOtherControls(0);
	}

	const setAddressAsDefaultShippingAddress = async () => {

		setLoadingSetAsDefault(true);
		var result: any = await setShippingAddressAsDefault(selectedShippingAddressId);
		setLoadingSetAsDefault(false);

		if (result.success === false) {
			Toast.Show("error", result.message);
			return;
		}

		Toast.Show("success", result.message);

		dispatch(getShippingAddresses());
	}

	return (
		<div className={hasError === true ? "checkoutBox errorBorder"
			: !confirmOrderClicked && selectedShippingAddressId
				? "checkoutBox doneSelected"
				: confirmOrderClicked
					? selectedShippingAddressId
						? "checkoutBox doneSelected"
						: "checkoutBox discountSelected"
					: "checkoutBox"
		}
			id="shippingAddress">
			<Row>
				<Col xs={12} sm={12} md={12} lg={12}>

					{/* Informação na parte de cima: titulo e adicionar*/}
					<Row style={{ textAlign: "center", display: "flex", justifyContent: "space-between" }}>
						<Col xs={9} sm={4} md={5} lg={5} xl={7} style={{ textAlign: "left" }} className="checkoutBoxTitle">
							<FormattedMessage id="billingAddress.dadosEnvioTitle" />
						</Col>

						{canCopyAddress === true && (
							<Col xs={5} sm={5} md={5} lg={5} xl={3} className="colCopyMoradaButton">
								<IHTPButton text={intl.formatMessage({ id: "billingAddress.novaMorada.copyBillingAddressButton" })}
									loading={isCopyingAddressLoading}
									onClick={handleCopyBillingAddress}
								/>
							</Col>
						)}

						{deliveryAddresses.length !== 0 ? (
							<Col xs={3} sm={3} md={2} lg={2} xl={2} className="addButtonShippingAddress">
								<FlatButton
									label={
										<svg
											height="25px"
											viewBox="0 0 512 512"
											width="25px"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
											<path d="m256 365.714844c-10.097656 0-18.285156-8.1875-18.285156-18.285156v-182.859376c0-10.097656 8.1875-18.285156 18.285156-18.285156s18.285156 8.1875 18.285156 18.285156v182.859376c0 10.097656-8.1875 18.285156-18.285156 18.285156zm0 0" />
											<path d="m347.429688 274.285156h-182.859376c-10.097656 0-18.285156-8.1875-18.285156-18.285156s8.1875-18.285156 18.285156-18.285156h182.859376c10.097656 0 18.285156 8.1875 18.285156 18.285156s-8.1875 18.285156-18.285156 18.285156zm0 0" />
										</svg>
									}
									className="addShippingAddress"
									secondary={true}
									disabled={!adicionar}
									onClick={onOpenCreateShippingAddress}
									style={{ minWidth: "fit-content" }}
								/>
							</Col>
						) : null}
					</Row>


					{canCopyAddress == true && (
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} className="colCopyMoradaButtonMobile">
								<IHTPButton text={intl.formatMessage({ id: "billingAddress.novaMorada.copyBillingAddressButton" })}
									loading={isCopyingAddressLoading}
									onClick={handleCopyBillingAddress}
								/>
							</Col>
						</Row>
					)}

					<CreateShippingAddres onClose={onCloseCreateShippingAddress} />

					{/* Modificar morada */}
					<EditShippingAddress onClose={onCloseEditShippingAddress} shippingDTO={editShippingDTO} />


					{/* Escolher moradas */}
					<Row style={{
						textAlign: "center",
						marginTop: "1em"
					}}>
						<Col xs={12} sm={12} md={10} lg={10} xl={10}>
							<div className="shippingAddresses">
								{deliveryAddresses.length > 0 ? (
									<SelectField
										value={selectedShippingAddressId}
										onChange={handleChangeShippingAddress}
										fullWidth={true}
										className="SelectField"
										labelStyle={{ paddingRight: "0px" }}
									>
										{deliveryAddresses.map((address, j) => {
											return (
												<MenuItem
													value={address.ID_Cliente_Morada}
													primaryText={address.Nome + " - " + address.Morada}
												/>
											);
										})}
									</SelectField>
								) : null}
							</div>

							{justChoosingAddress === true && (
								<>
									{/* Definir como padrão */}
									<Row style={{ display: "flex", justifyContent: "right", alignItems: "flex-end" }}>
										<Col xs={12} sm={12} md={12} lg={10} xl={5} style={{ display: "flex", justifyContent: "right", alignItems: "flex-end" }}>
											{currentShippingAddress != null && currentShippingAddress.principal === "S" ? (
												<span style={{
													textAlign: "right",
													fontFamily: "Montserrat", fontWeight: 600, fontSize: "13px", color: "rgb(202, 0, 106)"
												}}><FormattedMessage id="billingAddress.padraoSelecionado" /></span>
											) : (
												<IHTPButton text={intl.formatMessage({ id: "billingAddress.padraoBotao" })}
													loading={loadingSetAsDefault}
													onClick={setAddressAsDefaultShippingAddress} />
											)}
										</Col>
									</Row>
								</>
							)}
						</Col>

						{/* Mostrar os botões de editar e eliminar */}
						{deliveryAddresses.length > 0 ? (
							<>
								<Col xs={6} sm={6} md={1} lg={1} xl={1} className="changeButtonShippingAddress" >
									<FlatButton
										label={
											<svg
												height="25px"
												viewBox="0 0 512 512.00115"
												width="25px"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="m485.191406 26.8125c-35.738281-35.742188-93.929687-35.757812-129.683594 0l-324.195312 324.199219c-.082031.085937-.105469.203125-.191406.289062-1.476563 1.53125-2.632813 3.316407-3.503906 5.28125-.226563.511719-.355469 1.015625-.535157 1.539063-.257812.757812-.625 1.460937-.78125 2.257812l-25.941406 129.679688c-1.207031 6.019531.679687 12.234375 5.015625 16.570312 3.476562 3.472656 8.148438 5.371094 12.964844 5.371094 1.199218 0 2.402344-.117188 3.601562-.355469l129.683594-25.941406c.792969-.160156 1.5-.527344 2.253906-.78125.523438-.179687 1.03125-.308594 1.539063-.535156 1.964843-.875 3.75-2.03125 5.28125-3.503907.085937-.085937.203125-.109374.289062-.195312l324.199219-324.191406c35.75-35.757813 35.75-93.9375.003906-129.683594zm-337.167968 414.972656-77.808594-77.808594 272.320312-272.328124 77.816406 77.816406zm-106.304688 28.5 13.824219-69.105468 55.28125 55.277343zm417.539062-339.726562-12.972656 12.972656-77.816406-77.816406 12.972656-12.972656c21.453125-21.472657 56.359375-21.4375 77.816406 0 21.445313 21.457031 21.445313 56.363281 0 77.816406zm0 0" />
												<path d="m148.023438 382.320312c-4.691407 0-9.382813-1.789062-12.964844-5.375-7.164063-7.164062-7.164063-18.769531 0-25.929687l194.515625-194.519531c7.164062-7.164063 18.773437-7.164063 25.933593 0 7.160157 7.164062 7.164063 18.769531 0 25.933594l-194.519531 194.515624c-3.582031 3.582032-8.273437 5.375-12.964843 5.375zm0 0" />
											</svg>
										}
										style={customStyles.buttons}
										className=""
										secondary={true}
										disabled={!alterar}
										onClick={onOpenEditShippingAddress}
									/>
								</Col>
								<Col xs={6} sm={6} md={1} lg={1} xl={1} className="removeButtonShippingAddress">

									{loadingRemove === true ? (
										<CircularProgress size={24} className="buttonProgress" />
									) : (
										<FlatButton
											label={
												<svg
													height="25px"
													viewBox="-26 0 512 512"
													width="25px"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path d="m441.378906 70.621094h-105.929687v-34.691406c0-19.808594-16.121094-35.929688-35.929688-35.929688h-140.003906c-19.808594 0-35.929687 16.121094-35.929687 35.929688v34.691406h-105.929688c-9.75 0-17.65625 7.894531-17.65625 17.65625 0 9.757812 7.90625 17.652344 17.65625 17.652344h18.40625l14.652344 336.96875c1.679687 38.757812 33.386718 69.101562 72.175781 69.101562h213.257813c38.785156 0 70.488281-30.34375 72.171874-69.101562l14.652344-336.96875h18.40625c9.75 0 17.65625-7.894532 17.65625-17.652344 0-9.761719-7.90625-17.65625-17.65625-17.65625zm-281.863281-35.3125h140.003906l.617188 35.3125h-141.03125zm213.527344 406.070312c-.863281 19.792969-17.066407 35.3125-36.898438 35.3125h-213.257812c-19.828125 0-36.035157-15.519531-36.898438-35.3125l-14.585937-335.449218h316.226562zm0 0" />
													<path d="m229.515625 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
													<path d="m300.136719 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
													<path d="m158.898438 406.070312c-9.753907 0-17.65625-7.898437-17.65625-17.65625v-211.863281c0-9.757812 7.902343-17.652343 17.65625-17.652343 9.75 0 17.652343 7.894531 17.652343 17.652343v211.863281c0 9.757813-7.902343 17.65625-17.652343 17.65625zm0 0" />
												</svg>
											}
											style={customStyles.buttons}
											className=""
											secondary={true}
											disabled={!remover}
											onClick={handleRemoveShippingAddress}
										/>
									)}

								</Col>
							</>
						) : null}
					</Row>
				</Col >
			</Row >
		</div >
	)
}