import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { Col } from 'reactstrap';

export default class FooterColumn extends Component {
    render() {

        var flexAlign = (typeof this.props.title === 'undefined' || this.props.title == null) ? "columnAlignCenter" : "";

        return (
            <Col md={this.props.md} className={`footerColumnContainer ${flexAlign}`} >
                {typeof this.props.title != 'undefined' && this.props.title != null && (
                    <h6 className={"footerColumnTitle"} >{this.props.title}</h6>
                )}
                <ul className={"footerColumnMenuList"}>
                    {this.props.menuItems && this.props.menuItems.map(mi => {
                        return (
                            <li>
                                <a href={mi.link} className="footerColumnMenuItem">
                                    <FormattedMessage id={mi.labelId} />
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </Col>
        )
    }
}
