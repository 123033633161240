import Cookies from 'js-cookie';
import { createRef, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUserToRegister } from "../../../actions/miniCartActions";
import Routes from '../../../helpers/Routes';
import { RegisterPropsDTO } from "../../../models/RegisterPropsDTO";
import IHTPCheckbox from "../../Common/IHTPCheckbox";
import IHTPInput from '../../Common/IHTPInput';
import PasswordCheckList from "../../RegisterComponents/PasswordCheckList";

export default function RegisterCheckout(props: RegisterPropsDTO) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();

    const fullNameInput = createRef<HTMLInputElement>();

    const [fullName, setFullName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const [showPasswordErrors, setShowPasswordErrors] = useState<boolean>(false);
    const [recommendationEmail, setRecommendationEmail] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [acceptedRGPD, setAcceptedRGPD] = useState<boolean>(false);
    const [isRegisterFromFacebook, setIsRegisterFromFacebook] = useState<boolean>(false);
    const [isRegisterFromGoogle, setIsRegisterFromGoogle] = useState<boolean>(false);

    const loadPropsState = () => {
        if (props.facebookData != null && typeof props.facebookData !== 'undefined') {
            setIsRegisterFromFacebook(true);
            setFullName(props.facebookData.name)
            focusFullNameInput();
            setUserToRegisterData();
        } else if (props.googleData != null && typeof props.googleData !== 'undefined') {
            setIsRegisterFromGoogle(true);
            setFullName(props.googleData.name)
            focusFullNameInput();
        }
    }

    const focusFullNameInput = () => {
        if (fullNameInput != null && fullNameInput.current != null) {
            fullNameInput.current.focus();
        }
    }

    const setUserToRegisterData = async () => {
        var newUser = {
            email: props.email,
            nomeCompleto: fullName,
            password: password,
            emailRecomendacao: recommendationEmail,
            telefone: phoneNumber,
            refer: Cookies.get("refer"),
            gdpr: acceptedRGPD === true ? "S" : "N",
            dataNascimento: "",
            localidade: "",
            pais: "",
            isPasswordValid,
            isRegisterFromFacebook,
            facebookData: props.facebookData,
            isRegisterFromGoogle,
            googleData: props.googleData
        }

        await dispatch(setUserToRegister(newUser));
    }

    useEffect(() => {
        loadPropsState();
        return () => { }
    }, [])

    useEffect(() => {
        setUserToRegisterData();
        return () => { }
    }, [fullName, password, recommendationEmail, phoneNumber, acceptedRGPD, isPasswordValid])

    return (
        <>
            {/* Nome completo */}
            <Col xl={2} lg={4} md={4} sm={6} xs={12}>
                <IHTPInput type="text"
                    label={intl.formatMessage({ id: "registar.nomeCompletoTitle" })}
                    placeholder={intl.formatMessage({ id: "registar.nomeCompletoPlaceholder" })}
                    value={fullName}
                    ref={fullNameInput}
                    controlValue={true}
                    onChange={setFullName}
                    required={true} />
            </Col>

            {/* Password */}
            {isRegisterFromFacebook == false && isRegisterFromGoogle == false && (
                <Col xl={2} lg={4} md={4} sm={6} xs={12}>
                    <IHTPInput type="password"
                        label={intl.formatMessage({ id: "registar.passwordTitle" })}
                        placeholder={intl.formatMessage({ id: "registar.passwordPlaceholder" })}
                        value={password}
                        onChange={setPassword}
                        onFocus={() => setShowPasswordErrors(true)}
                        onBlur={() => isPasswordValid == true ? setShowPasswordErrors(false) : null}
                        required={true} />

                    <PasswordCheckList minimumCharacters={8}
                        shouldHaveAtLeastOneLetter={true}
                        shouldHaveAtLeastOneNumber={true}
                        shouldHaveAtLeastOneLowercaseLetter={true}
                        shouldHaveAtLeastOneCapitalLetter={true}
                        password={password}
                        onChange={setIsPasswordValid}
                        showComponent={showPasswordErrors}
                    />
                </Col>
            )}

            {/* Email de Recomendação */}
            <Col xl={2} lg={4} md={4} sm={6} xs={12}>
                <IHTPInput type="text"
                    label={intl.formatMessage({ id: "registar.emailRecomendacaoPlaceholderTitle" })}
                    placeholder={intl.formatMessage({ id: "registar.emailRecomendacaoPlaceholder" })}
                    onChange={setRecommendationEmail} />
            </Col>

            {/* Telefone */}
            <Col xl={2} lg={4} md={4} sm={6} xs={12}>
                <IHTPInput type="text"
                    label={intl.formatMessage({ id: "registar.telefoneTitle" })}
                    placeholder={intl.formatMessage({ id: "registar.telefonePlaceholder" })}
                    onChange={setPhoneNumber} />
            </Col>

            {/* Termos e condições */}
            <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginTop: "10px" }}>
                <IHTPCheckbox value={acceptedRGPD}
                    onChange={setAcceptedRGPD}
                    required={true}
                    textComponent={
                        <span style={{ fontSize: "13px", fontWeight: "500" }}>
                            <FormattedMessage id="registar.politicaDados1" />
                            <a href={Routes.empresa.protecaoDados} className="ihtpLink" target="_blank">
                                <FormattedMessage id="registar.politicaDados2" />
                            </a>.
                        </span>
                    } />
            </Col>
        </>
    )
}