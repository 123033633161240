import React, { Component } from 'react'

export default class MainMenuIcon extends Component {
    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                version="1.1"
                width="40px"
                height="40px"
            >
                <g id="surface1">
                    <path
                        style={{ fill: this.props.color }}
                        d="M 6 22 L 42 22 L 42 26 L 6 26 Z "
                    />
                    <path
                        style={{ fill: this.props.color }}
                        d="M 6 10 L 42 10 L 42 14 L 6 14 Z "
                    />
                    <path
                        style={{ fill: this.props.color }}
                        d="M 6 34 L 42 34 L 42 38 L 6 38 Z "
                    />
                </g>
            </svg>
        )
    }
}
