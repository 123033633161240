import { Col, Container, Row } from "react-bootstrap";
import HeaderDesktop from "../../../components/Desktop/Header/Header";
import Footer from "../../../components/Footer/Footer";
import VerifyEmail from "../../../components/VerifyEmail/VerifyEmail";

export default function VerifyEmailView() {
    return (
        <div className="App">
            <HeaderDesktop />
            <Container className="mainContentContainer">
                <Row style={{ textAlign: "center" }} >
                    <Col xs="12" sm="12" md="12" lg="12">
                        <VerifyEmail />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}
