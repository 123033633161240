import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { fetchCart } from "../../actions/miniCartActions";
import { beginCheckout } from "../../Analytics/DataLayer";
import useCurrencies from "../../hooks/useCurrencies";
import { CreateProductObjectToSendToGoogleAnalytics } from "../../Utils/UtilsCheckout";
import Loading from "../Desktop/Loading/Loading";
import CartIsEmpty from "../Shop/Cart/Components/CartIsEmpty";
import CartItem from "../Shop/Cart/Components/CartItem";
import ErrorLoadingCart from "../Shop/Cart/Components/ErrorLoadingCart";

export default function CartCheckout() {

    const [haveErrorOccurred, setHaveErrorOccurred] = useState<boolean>(false);
    const [loadingCart, setLoadingCart] = useState<boolean>(false);
    const [isCartEmpty, setIsCartEmpty] = useState<boolean>(false);
    const [currency, setCurrency] = useState<string>("");
    const [carrinhoProdutos, setCarrinhoProdutos] = useState<any>([]);
    const [carrinhoPacks, setCarrinhoPacks] = useState<any[]>([]);

    const currencies = useCurrencies();
    const cart = useSelector((state: any) => state.cart);
    const cartLoadError = useSelector((state: any) => state.cartLoadError);

    const dispatch = useDispatch();

    useEffect(() => {
        setLoadingCart(true);
        dispatch(fetchCart());
        setCurrency(currencies.selectedDescription);

    }, [currencies.selectedDescription])

    useEffect(() => {
        var hasError = (cartLoadError != null && cartLoadError.length > 0);
        setHaveErrorOccurred(hasError);

    }, [cartLoadError])

    useEffect(() => {
        if (cart.Carrinho != null && typeof cart.Carrinho !== "undefined") {
            setLoadingCart(false);
            setCarrinhoProdutos(cart.Carrinho);
        }

        if (cart.CarrinhoPack != null && typeof cart.CarrinhoPack !== "undefined") {
            setLoadingCart(false);
            setCarrinhoPacks(cart.CarrinhoPack);
        }

        var isCartEmpty = !((cart.Carrinho || cart.CarrinhoPack) && (cart.Carrinho.length > 0 || cart.CarrinhoPack.length > 0));
        setIsCartEmpty(isCartEmpty);

        if (isCartEmpty == false) {
            sendOrderProductsToGoogleAnalytics();
        }
    }, [cart])

    const sendOrderProductsToGoogleAnalytics = () => {
        var items = CreateProductObjectToSendToGoogleAnalytics(cart.Carrinho, cart.CarrinhoPack);

        beginCheckout(items);
    }

    return (
        <div className="checkoutBox" style={{ display: "flex", flexDirection: "column", alignContent: "flex-start" }}>
            <div className="cartContainerCheckout">
                <div className="cartGridContainerCheckout">
                    {/* Verificar se o Carrinho, está vazio e mostrar mensagem correspondente*/}
                    {haveErrorOccurred == false && loadingCart == false && isCartEmpty === true ? (
                        <div className="cartItemsContainer">
                            <CartIsEmpty />
                        </div>
                    ) : (
                        <>
                            <div className="cartTitleParent">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} className="checkoutBoxTitle" style={{ marginBottom: "2em" }}>
                                        <FormattedMessage id="carrinho.produtos.text" />
                                    </Col>
                                </Row>
                            </div>

                            <div className="cartItemsContainer">
                                {loadingCart === true && haveErrorOccurred === false ? (
                                    <Loading />
                                ) : (
                                    <>
                                        {/* Apresentar Produtos no Carrinho */}
                                        {haveErrorOccurred === false && carrinhoProdutos && carrinhoProdutos.length > 0 && carrinhoProdutos.map((item, j) => {
                                            return <CartItem currency={currency} key={item.ID_Produto} item={item} canUpdateQuantity={false} canDelete={false} isOnCheckout={true} />
                                        })
                                        }

                                        {/* Apresentar Packs no Carrinho */}
                                        {haveErrorOccurred === false && carrinhoPacks && carrinhoPacks.length > 0 && carrinhoPacks.map((item, j) => {
                                            return <CartItem currency={currency} key={item.ID_Pack} item={item} canUpdateQuantity={false} canDelete={false} isOnCheckout={true} />
                                        })
                                        }
                                    </>
                                )}
                            </div>
                        </>
                    )}

                    {haveErrorOccurred == true && (
                        <div className="cartErrorMessage">
                            <ErrorLoadingCart errorMessage={cartLoadError} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}