import React, { useEffect, useState } from 'react'
import GoogleLogin from 'react-google-login';
import { FcGoogle } from 'react-icons/fc';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from "react-toastr";
import { getGoogleAppId } from '../../actions/geralActions';
import { loginWithGoogle } from '../../actions/userActions';
import { funnelStep } from '../../Analytics/DataLayer';
import CrossDomainEventHandler from '../../helpers/CrossDomainEventHandler';
import Routes from '../../helpers/Routes';

interface GoogleLoginProps {
    googleAppId: string,
    buttonDisabled: boolean,
    errorMessage: string,
    googleCode?: string
}

export interface GoogleLoginComponentProps {
    onErrorMessage: (errorMessage: string) => void,
    previousInCheckout: boolean,
    showText: boolean,
    isRegisteringInCheckout: boolean,
    callbackToRegisterInCheckout?: (result: any) => void
}


let container;
export default function GoogleLoginComponent(props: GoogleLoginComponentProps) {

    const [googleLogin, setGoogleLogin] = useState<GoogleLoginProps | null>(null);
    let history = useHistory();
    const intl = useIntl();
    const user = useSelector((state: any) => {
        return state.user;
    });

    useEffect(() => {
        loadGoogleAppId();

        return () => { }
    }, [])

    const loadGoogleAppId = async () => {
        try {
            var result = await getGoogleAppId();
            if (result.success === true) {
                setGoogleLogin({
                    googleAppId: result.obj,
                    buttonDisabled: false,
                    errorMessage: "",
                });
            }
        } catch (exp) {
            setGoogleLogin(null);
        }
    }

    const loginGoogleSuccess = (response) => {
        try {
            loginGoogle(response.code);
        } catch (err) {
            container.clear();
            container.error(<FormattedMessage id="login.erro.google" />);
        }

    }

    //Método de quando existe um erro ao fazer login com Google
    const loginGoogleFailure = (response) => {

        if (response.error === "idpiframe_initialization_failed") {
            setGoogleLogin({
                googleAppId: googleLogin == null ? "" : googleLogin.googleAppId,
                buttonDisabled: true,
                errorMessage: intl.formatMessage({ id: "login.ativarOpcaoCookies" })
            });
        }

        if (response.error === "access_denied") {
            container.clear();
            container.error(<FormattedMessage id="login.erro.dadosGoogle" />);
        }
    }

    const loginGoogle = (googleCode: string) => {
        loginWithGoogle(googleCode)
            .then(result => {

                var x: any = window;

                //Se o pedido tiver sido efetuado com erro
                if (result.success !== true) {
                    container.clear();
                    container.warning(`${result.message}`);

                    // Enviar mensagem para GTM
                    x.dataLayer = x.dataLayer || [];
                    x.dataLayer.push({
                        event: "Login",
                        loginMessage: `Erro - ${result.message}`
                    });

                    //Se pedido efetuado com sucesso 
                } else {

                    //Se utilizador está registado
                    if (result.obj.isUserRegistered === true) {

                        funnelStep(2);

                        // Enviar mensagem para GTM
                        x.dataLayer = x.dataLayer || [];
                        x.dataLayer.push({
                            event: "Login",
                            loginMessage: "Sucesso",
                            userType: user.userType,
                            userID: user.userID
                        });

                        CrossDomainEventHandler.sendEvent();

                        //Se antes estavamos no checkout e viemos para aqui por causa do login
                        //mandamos de volta para o checkout apos fazer login
                        if (props.previousInCheckout === true) {
                            x.location.href = "/shop/checkout";
                        } else {
                            x.location.href = process.env.REACT_APP_MAIN_WEBSITE_SHOP_URL;
                        }

                        //Se utilizador não registado
                    } else {
                        //Mandamos as props do Google para a página de registar
                        var pageType = {
                            pathname: Routes.account.registo,
                            state: {
                                googleData: {
                                    email: result.obj.googleInfo.email,
                                    name: result.obj.googleInfo.name,
                                    accessToken: result.obj.googleInfo.accessToken,
                                    refreshToken: result.obj.googleInfo.refreshToken,
                                    idToken: result.obj.googleInfo.idToken,
                                }
                            }
                        }

                        if (props.isRegisteringInCheckout === false) {
                            history.push(pageType);
                        }
                        else {
                            if (typeof props.callbackToRegisterInCheckout !== 'undefined') {
                                props.callbackToRegisterInCheckout(pageType);
                            }
                        }
                    }
                }
            })
            .catch(err => {
                container.clear();
                container.error("Erro ao fazer login através do Google!");
            })
    }

    return (
        <>
            <ToastContainer
                ref={ref => (container = ref)}
                className="toast-bottom-right"
            />

            {googleLogin != null ? (
                <GoogleLogin
                    clientId={googleLogin.googleAppId}
                    onSuccess={loginGoogleSuccess}
                    onFailure={loginGoogleFailure}
                    isSignedIn={false}
                    uxMode={"popup"}
                    disabled={googleLogin.buttonDisabled}
                    responseType={"code"}
                    accessType={"offline"}
                    prompt={"consent"}
                    render={renderProps => (
                        <button className={props.isRegisteringInCheckout == true ? "googleLoginButtonCheckout" : "googleLoginButton"} onClick={renderProps.onClick} disabled={renderProps.disabled}>
                            <FcGoogle /> {props.showText === true ? <FormattedMessage id="login.entrarGoogle" /> : null}
                        </button>
                    )}
                />)
                : null}

        </>
    )
}
