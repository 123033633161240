import React, { Component } from "react";
import { FaYoutube } from "react-icons/fa";
export default class Youtube extends Component {
  render() {
    return (
      <a className="icons" target="_blank" href="https://www.youtube.com/user/solfut">
        <FaYoutube />
      </a>
    );
  }
}
