import "bootstrap";
import $ from "jquery";
import React from "react";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { getUser } from "./actions/miniCartActions";
import "./index.css";
import { default as IntlProviderWrapper } from './IntlProviderWrapper';
import reducer from "./reducers/index";
import * as serviceWorker from "./serviceWorker";


window.jQuery = $;
window.$ = $;

const store = createStore(reducer, applyMiddleware(thunk));

getUser().then(function (user) {
  ReactDOM.render(
    <Provider store={store}>
      <IntlProviderWrapper user={user} />
    </Provider>,
    document.getElementById("root")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
