import TextField from "@material-ui/core/TextField";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { addObservacao } from "../../actions/miniCartActions";
import "./Checkout.css";
import useCheckoutDisabledInputs from "./useCheckoutDisabledInputs";

export default function Observacao() {
	const intl = useIntl();
	const dispatch = useDispatch();

	const observacoes = useSelector((state: any) => state.checkoutData.observacoes);

	const { disabledFields } = useCheckoutDisabledInputs();

	const handleChange = async (event) => {
		await dispatch(addObservacao(event.target.value));
	};

	return (
		<div className="checkoutBox">
			<Row style={{ minHeight: "100%" }}>
				<Col xs={12} sm={12} md={12} lg={12}>
					<div className="observacaoBox" >
						<div className="checkoutBoxTitle">
							{intl.formatMessage({ id: "observacoes.title" })}
						</div>

						<TextField name="observacoes"
							fullWidth={true}
							multiline={true}
							rows={2}
							rowsMax={8}
							value={observacoes}
							onChange={handleChange}
							placeholder={intl.formatMessage({ id: "observacoes.text" })}
							disabled={disabledFields}
						/>
					</div>
					<br />
					<br />
				</Col>
			</Row>
		</div>
	)
}