import Joi, { TopLevelDomainOptions } from "joi";
import { IntlShape } from "react-intl";

export const EmailSchema = (checkTLD: false | TopLevelDomainOptions | undefined, intl: IntlShape) => {

    const emailRequired: string = intl.formatMessage({ id: "common.email.required" });
    const emailMandatory: string = intl.formatMessage({ id: "common.email.mandatory" });
    const emailEmpty: string = intl.formatMessage({ id: "common.email.empty" });
    const emailValid: string = intl.formatMessage({ id: "common.email.valid" });

    return Joi.string().empty().required().email({ tlds: checkTLD }).messages({
        "any.required": emailRequired,
        "string.base": emailMandatory,
        "string.empty": emailEmpty,
        "string.email": emailValid,
    });
} 