import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { ValidateShippingAddress } from '../../../Utils/UtilsCheckout';
import { addNewShippingAddress, addShippingAddressCheckout, getShippingAddresses, setErrorCodeCheckout, setShippingAddress, verifyOrder } from '../../../actions/miniCartActions';
import Toast from '../../../helpers/Toast';
import { AddressDTO } from '../../../models/Address/AddressDTO';
import IHTPButton from '../../Common/IHTPButton';
import IHTPInput from '../../Common/IHTPInput';
import IHTPSelect, { IHTPSelectItem } from '../../Common/IHTPSelect';
import IHTPSpacer from '../../Common/IHTPSpacer';
import useCheckoutDisabledInputs from '../useCheckoutDisabledInputs';

const novaMoradaInitialState: AddressDTO = {
    id_cliente_morada: "",
    nome: "",
    morada: "",
    localidade: "",
    codigoPostal: "",
    principal: "N",
    id_pais: "",
    ID_Tipo_Morada: "",
    nif: ""
}

export interface CreateShippingAddressProps {
    onClose: () => void
}

export default function CreateShippingAddress(props: CreateShippingAddressProps) {

    const [loadingAddAddress, setLoadingAddAddress] = useState<boolean>(false);
    const intl = useIntl();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.user);
    const userToRegister = useSelector((state: any) => state.userToRegister);

    //shippingAddress é usado para guardar a nova morada de envio globalmente enquanto esta não é criada
    const shippingAddress: AddressDTO = useSelector((state: any) => state.shippingAddress);
    const billingAddress: AddressDTO = useSelector((state: any) => state.billingAddress);
    const billingAddresses = useSelector((state: any) => state.billingAddresses);
    const allPaises = useSelector((state: any) => state.allPaises);

    const hasBillingAddresses = typeof billingAddresses !== "undefined" && billingAddresses != null && Array.isArray(billingAddresses) && billingAddresses.length !== 0;
    const { disabledFields } = useCheckoutDisabledInputs();

    useEffect(() => {
        //Colocamos os dados a limpo
        dispatch(setShippingAddress(novaMoradaInitialState));
    }, [])


    const paisesOptions: IHTPSelectItem[] = useMemo(() => {
        if (typeof allPaises === "undefined" || allPaises == null) return [];

        var paises: IHTPSelectItem[] = [];
        allPaises.forEach(i => {
            paises.push({
                value: i.alpha3,
                label: i.name
            });
        })
        return paises;
    }, [allPaises])

    const defaultAddressOptions: IHTPSelectItem[] = useMemo(() => [
        { value: "N", label: intl.formatMessage({ id: "facturacao.novaMorada.padrao.no" }) },
        { value: "S", label: intl.formatMessage({ id: "facturacao.novaMorada.padrao.yes" }) }
    ], []);

    const setNovaMorada = (address: AddressDTO) => {
        dispatch(setShippingAddress(address));
    }

    const handleFormSubmit = async () => {
        var resultValidation = ValidateShippingAddress(shippingAddress, intl, dispatch);
        if (resultValidation.success === false) {
            Toast.Show("error", resultValidation.message);
            return;
        }

        setLoadingAddAddress(true);
        var resultAdd: any = await dispatch(addNewShippingAddress(shippingAddress));
        setLoadingAddAddress(false);

        if (resultAdd.success === false) {
            Toast.Show("error", resultAdd.message);
            return;
        }

        Toast.Show("success", resultAdd.message);

        //Resetamos a morada
        setNovaMorada(novaMoradaInitialState);

        //Dizemos que já não existe erro (para retirar a borda vermelha)
        dispatch(setErrorCodeCheckout(""))

        dispatch(addShippingAddressCheckout(resultAdd.obj));

        props.onClose();
    }

    const handleCleanAddShippingAddress = () => {
        //Resetamos a morada
        setNovaMorada(novaMoradaInitialState);

        props.onClose();
    }


    //Para não estar sempre a fazer um pedido à api para verificar a encomenda, sempre que se escreve
    //Desta forma, apenas faz o pedido passado 1 segundo sem escrever nada
    const debounceVerifyOrder = useCallback(
        debounce(() => dispatch(verifyOrder()), 1000),
        [],
    )

    useEffect(() => {
        debounceVerifyOrder();
    }, [shippingAddress.codigoPostal, shippingAddress.id_pais])

    useEffect(() => {
        setNovaMorada({ ...shippingAddress, nome: userToRegister.nomeCompleto })
    }, [userToRegister?.nomeCompleto])

    const handleCopyBillingAddress = async () => {
        const newAddress: AddressDTO = {
            nome: billingAddress.nome,
            morada: billingAddress.morada,
            localidade: billingAddress.localidade,
            codigoPostal: billingAddress.codigoPostal,
            principal: billingAddress.principal,
            id_pais: billingAddress.id_pais,
            ID_Tipo_Morada: "R"
        }

        dispatch(setShippingAddress(newAddress));
    }

    return (
        <Row style={{ textAlign: "center" }}>
            <div className="shippingAddAddress">
                <Col xs={12} sm={12} md={12} lg={12}>
                    <Row className="shippingRowTitleAddress">
                        <Col xs={7} sm={7} md={7} lg={7} xl={9} style={{ textAlign: "left", marginLeft: "-15px" }}>
                            <span className="titleOptionsAddress">
                                <FormattedMessage id="billingAddress.novaMorada" />
                            </span>
                        </Col>

                        {hasBillingAddresses === false &&
                            <Col xs={5} sm={5} md={5} lg={5} xl={3} className="colCopyMoradaButton">
                                <IHTPButton text={intl.formatMessage({ id: "billingAddress.novaMorada.copyBillingAddressButton" })}
                                    loading={false}
                                    onClick={handleCopyBillingAddress}
                                />
                            </Col>
                        }
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>
                            <IHTPInput label={intl.formatMessage({ id: "billingAddress.novaMorada.nome.label" })}
                                placeholder={intl.formatMessage({ id: "billingAddress.novaMorada.nome" })}
                                onChange={(text) => setNovaMorada({ ...shippingAddress, nome: text })}
                                type="text" controlValue={true} value={shippingAddress.nome}
                                disabled={disabledFields}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPInput label={intl.formatMessage({ id: "billingAddress.novaMorada.morada.label" })}
                                placeholder={intl.formatMessage({ id: "billingAddress.novaMorada.morada" })}
                                onChange={(text) => setNovaMorada({ ...shippingAddress, morada: text })}
                                type="text" controlValue={true} value={shippingAddress.morada}
                                disabled={disabledFields}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPInput label={intl.formatMessage({ id: "billingAddress.novaMorada.localidade.label" })}
                                placeholder={intl.formatMessage({ id: "billingAddress.novaMorada.localidade" })}
                                onChange={(text) => setNovaMorada({ ...shippingAddress, localidade: text })}
                                type="text" controlValue={true} value={shippingAddress.localidade}
                                disabled={disabledFields}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPInput label={intl.formatMessage({ id: "billingAddress.novaMorada.codigoPostal.label" })}
                                placeholder={intl.formatMessage({ id: "billingAddress.novaMorada.codigoPostal" })}
                                onChange={(text) => setNovaMorada({ ...shippingAddress, codigoPostal: text })}
                                type="text" controlValue={true} value={shippingAddress.codigoPostal}
                                disabled={disabledFields}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPSelect label={intl.formatMessage({ id: "billingAddress.novaMorada.id_pais.label" })}
                                options={paisesOptions}
                                placeholderItem={intl.formatMessage({ id: "billingAddress.novaMorada.id_pais" })}
                                onChange={(text) => setNovaMorada({ ...shippingAddress, id_pais: text })}
                                controlValue={true} value={shippingAddress.id_pais}
                                disabled={disabledFields}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPSelect label={intl.formatMessage({ id: "facturacao.novaMorada.padrao.label" })}
                                options={defaultAddressOptions}
                                hasPlaceholder={false}
                                onChange={(text) => setNovaMorada({ ...shippingAddress, principal: text })}
                                controlValue={true} value={shippingAddress.principal}
                                width="auto"
                                disabled={disabledFields}
                            />

                            <IHTPSpacer verticalSpace={"20px"} />

                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                <Col xl={6} lg={6} md={8} style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "1rem" }}>
                                    {user.userID != null && typeof user.userID != 'undefined'
                                        ?
                                        <>
                                            < IHTPButton text={intl.formatMessage({ id: "facturacao.novaMorada.addMoradasButton" })}
                                                loading={loadingAddAddress}
                                                onClick={handleFormSubmit} />

                                            {hasBillingAddresses && (
                                                <IHTPButton text={intl.formatMessage({ id: "common.button.cancelar" })} buttonStyle="secondary"
                                                    onClick={handleCleanAddShippingAddress} disabled={loadingAddAddress} />
                                            )}
                                        </>
                                        : null
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Row >
    );
}