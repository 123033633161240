import React, { Component } from 'react'

export default class BeConsultantIcon extends Component {
    render() {
        return (
            <img
                src="/assets/images/md-shape-8@2x.png"
                class="bp3-md-menu-shape"
            />
        )
    }
}
