import React, { Component } from 'react'
import { getRedirectByTipoProduto } from '../../actions/geralActions';

export default class RedirectComponent extends Component {

    constructor(props) {
        super(props);

        var categoryName = props.match.params.name;

        getRedirectByTipoProduto(categoryName).then(result => {
            if (result.success === true) {
                var url = result.obj.URL;

                window.location.replace(url);
            } else {
                window.location.replace(process.env.REACT_APP_MAIN_WEBSITE_URL);
            }
            console.log(result);
        }).catch(err => {
            window.location.replace(process.env.REACT_APP_MAIN_WEBSITE_URL);
        })
    }
    render() {
        return null;
    }
}
