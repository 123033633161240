const hyperid = require('hyperid');
const instance = hyperid({
    fixedLength: false,
    urlSafe: false
});

var listener = null;
var firstCookieValue = null;
class CrossDomainEventHandler {

    sendEvent() {
        document.cookie = `ihtpCDToken=${process.env.REACT_APP_MY_DOMAIN}|${this.getOrCreateTabId()}-${instance()}-ihavethepower-${instance()}; path=/; domain=${process.env.REACT_APP_HTTP_DOMAIN}`;
    }

    startListeningToChanges() {

        this.getOrCreateTabId();

        listener = setInterval(() => {

            var cookieValue = this.getCookieValue("ihtpCDToken");

            //Se a cookie estiver vazia não fazemos nada
            if (cookieValue === '') {
                return;
            }

            //Se for a primeira vez que acedemos guardamos o valor da cookie
            if (firstCookieValue == null) {
                firstCookieValue = cookieValue

                //Caso vejamos que a cookie é diferente e não foi alterada por nós fazemos reload
            } else if (firstCookieValue !== cookieValue && !cookieValue.startsWith(`${process.env.REACT_APP_MY_DOMAIN}|${this.getOrCreateTabId()}`)) {
                firstCookieValue = cookieValue;
                window.location.reload();
            }
        }, 2000);
    }

    stopListeningToChanges() {
        clearInterval(listener);
        listener = null;
    }

    getCookieValue(a) {
        var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }

    getOrCreateTabId() {
        var tabId = sessionStorage.getItem("ihtpTabUUI");

        //Se não conseguirmos ter tab id
        if (tabId == null) {
            //Geramos um id
            var tabUi = instance();

            sessionStorage.setItem("ihtpTabUUI", tabUi);
            return tabUi;

        } else {
            return tabId;
        }
    }

}

const crossDomain = new CrossDomainEventHandler();

export default crossDomain;