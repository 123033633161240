//Método para validar se valor é uma data
exports.isDate = function (value) {
    switch (typeof value) {
        case 'number':
            return true;
        case 'string':
            return !isNaN(Date.parse(value));
        case 'object':
            if (value instanceof Date) {
                return !isNaN(value.getTime());
            }
        default:
            return false;
    }
}

exports.RemoveDoubleZeroDecimalPlaces = function (value) {
    try {

        if (typeof value === 'undefined' || value == null || typeof value !== 'string') {
            return value;
        }

        return value.replace(/\.00$/, '');

    } catch (exp) {
        return value;
    }
}

exports.ParseClientIdFromGoogleAnalytics = function (value) {

    try {
        if (typeof value == 'undefined' || value == null || value == "") return null;

        var valueSplitted = value.split(".");

        //Se tiver mais que 4 significa que tem os índices 3 e 4 necessários
        if (valueSplitted.length >= 4) {
            return `${valueSplitted[2]}.${valueSplitted[3]}`;
        } else { //Se não tiver pelo menos quatro, então enviamos o valor default
            return value;
        }


    } catch (exp) {
        console.log(exp.message);
        return value;
    }
}

//Datetime deve ser do tipo data
exports.ConvertDateTimeToJustDate = function (datetime) {
    try {
        return `${datetime.getFullYear()}/${datetime.getMonth() + 1}/${datetime.getDate()}`;
    } catch (exp) {
        return datetime;
    }
}

exports.IsValidNumber = function (value) {
    if (typeof value === "undefined" || value == null || value.trim() == "") return false;

    var valueParsed = parseFloat(value);
    if (isNaN(valueParsed) === true) return false;
    return true;
}

exports.Sleep = function (timeInMiliseconds) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, timeInMiliseconds);
    })
}