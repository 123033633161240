import React, { Component } from "react";
import { FaLinkedin } from "react-icons/fa";
export default class LinkedIn extends Component {
  render() {
    return (
      <a className="icons" href="https://www.linkedin.com/company/solfut-lda---i-have-the-power/"
        target="_blank">
        <FaLinkedin />
      </a>
    );
  }
}