import Collapse from '@material-ui/core/Collapse/Collapse';
import Cookies from 'js-cookie';
import { createRef, useEffect, useState } from 'react';
import { FaFacebookF } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import { loadReCaptcha } from "react-recaptcha-google";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { getGoogleRecaptchaKey } from '../../../actions/geralActions';
import { getPaises } from '../../../actions/miniCartActions';
import Routes from '../../../helpers/Routes';
import { RegisterPropsDTO } from '../../../models/RegisterPropsDTO';
import { RegisterRouteStateDTO } from '../../../models/RegisterRouteStateDTO';
import { UserRegisterDTO } from '../../../models/UserRegisterDTO';
import { UtilsAuthenticationPropsDTO } from '../../../models/UtilsAuthenticationPropsDTO';
import { createObjectUserToRegister, handleRegisterUser, validateUserToRegister } from '../../../Utils/UtilsAuthentication';
import IHTPButton from '../../Common/IHTPButton';
import IHTPCheckbox from '../../Common/IHTPCheckbox';
import IHTPInput from '../../Common/IHTPInput';
import IHTPRecaptcha from '../../Common/IHTPRecaptcha';
import IHTPSelect, { IHTPSelectItem } from '../../Common/IHTPSelect';
import IHTPSpacer from '../../Common/IHTPSpacer';
import PasswordCheckList from '../../RegisterComponents/PasswordCheckList';
import "./Register.css";
import RegisterBenefits from './RegisterBenefits';
import Toast from "../../../helpers/Toast";

export default function Register(props: RegisterPropsDTO) {

    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state: any) => state.user);
    const intl = useIntl();
    const fullNameInput = createRef<HTMLInputElement>();

    const { state: routeState } = useLocation<RegisterRouteStateDTO>();

    const utilsAuthenticationProps: UtilsAuthenticationPropsDTO = {
        dispatch,
        history,
        useHistory: true,
        routeState,
        intl
    }

    const [openExtraField, setOpenExtraField] = useState<boolean>(false);
    const [countries, setCountries] = useState<IHTPSelectItem[]>([]);
    const [email, setEmail] = useState<string>("");
    const [canChangeEmail, setCanChangeEmail] = useState<boolean>(true);

    const [fullName, setFullName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const [showPasswordErrors, setShowPasswordErrors] = useState<boolean>(false);
    const [recommendationEmail, setRecommendationEmail] = useState<string>("");
    const [country, setCountry] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [birthDate, setBirthDate] = useState<string>("");

    const [recaptchaKey, setRecaptchaKey] = useState<string | null>(null);
    const [recaptchaVerified, setRecaptchaVerified] = useState<boolean>(false);
    const [acceptedRGPD, setAcceptedRGPD] = useState<boolean>(false);

    const [loadingRegister, setLoadingRegister] = useState<boolean>(false);

    const [isRegisterFromFacebook, setIsRegisterFromFacebook] = useState<boolean>(false);
    const [isRegisterFromGoogle, setIsRegisterFromGoogle] = useState<boolean>(false);

    const loadCountries = async () => {
        setCountries([]);
        var data: any = await dispatch(getPaises());
        if (!data.data.error) {
            var paises: IHTPSelectItem[] = [];
            data.data.paises.map(item => {
                var selectItem: IHTPSelectItem = {
                    value: item.alpha3,
                    label: item.name
                }
                paises.push(selectItem);
            });
            setCountries(paises);
        }
    }

    const loadRecaptchaKey = async () => {
        var result = await getGoogleRecaptchaKey();
        if (result.success === true) {
            setRecaptchaKey(result.obj);
        }
    }

    const loadRouteState = () => {
        if (typeof routeState !== 'undefined' && routeState != null && typeof routeState == "object" && typeof routeState.email !== 'undefined') {
            setEmail(routeState.email);
            setCanChangeEmail(false);
            focusFullNameInput();
        }
    }

    const focusFullNameInput = () => {
        if (fullNameInput != null && fullNameInput.current != null) {
            fullNameInput.current.focus();
        }
    }

    const loadPropsState = () => {
        if (props.facebookData != null) {
            setIsRegisterFromFacebook(true);
            setEmail(props.facebookData.email);
            setCanChangeEmail(false);
            setFullName(props.facebookData.name)
            focusFullNameInput();
        } else if (props.googleData != null) {
            setIsRegisterFromGoogle(true);
            setEmail(props.googleData.email);
            setCanChangeEmail(false);
            setFullName(props.googleData.name)
            focusFullNameInput();
        }
    }

    useEffect(() => {
        loadRouteState();
        loadPropsState();
        loadRecaptchaKey();
        loadCountries();
        return () => { }
    }, [])

    useEffect(() => {
        if (typeof user !== 'undefined' && user != null && typeof user.userID !== 'undefined') {
            history.replace("/carrinho");
            return;
        }
        return () => { }
    }, [user])


    useEffect(() => {
        loadReCaptcha();
        return () => { }
    }, [recaptchaKey])

    const handleFormSubmit = async () => {

        var newUser: UserRegisterDTO = {
            email: email,
            nomeCompleto: fullName,
            password: password,
            emailRecomendacao: recommendationEmail,
            telefone: phoneNumber,
            dataNascimento: birthDate,
            pais: country,
            localidade: city,
            refer: Cookies.get("refer"),
            gdpr: acceptedRGPD === true ? "S" : "N",
            isFacebookRegister: false,
            isGoogleRegister: false
        }

        var resultValidateUser = validateUserToRegister(utilsAuthenticationProps, newUser, isPasswordValid, setShowPasswordErrors
            , isRegisterFromFacebook, isRegisterFromGoogle);

        if (resultValidateUser.success == false) {
            Toast.Show("error", resultValidateUser.message);
            return;
        }

        var newUserObject = createObjectUserToRegister(newUser, isRegisterFromFacebook, props.facebookData, isRegisterFromGoogle, props.googleData);

        await handleRegisterUser(utilsAuthenticationProps, newUserObject, setLoadingRegister);
    }

    return (
        <Row>
            <Col xs="12" sm="12" md="12" lg="12">
                <div className="registerTitleParent">
                    <div className="registerTitle">
                        {(isRegisterFromGoogle === true) ? (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.5rem" }}>
                                <FormattedMessage id="registar.registoAtraves" />
                                <span style={{ color: "#3B5998", "fontWeight": 500, "fontSize": "28px" }}>
                                    <FormattedMessage id="registar.googleTitle" />
                                </span>
                                <FcGoogle style={{ fontSize: "25px" }} />
                            </div>
                        ) : (isRegisterFromFacebook === true ? (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.5rem" }}>
                                <FormattedMessage id="registar.registoAtraves" />
                                <span style={{ color: "#3B5998", "fontWeight": 500, "fontSize": "28px" }}>
                                    <FormattedMessage id="registar.facebookTitle" />
                                </span>
                                <FaFacebookF style={{ color: "#3B5998", fontSize: "25px" }} />
                            </div>
                        ) : (
                            <FormattedMessage id="registar.titulo" />
                        ))}
                    </div>
                </div>
                <br />

                <Row style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <Col xs={12} sm={12} md={6} lg={5} xl={4} style={{ textAlign: "left" }}>
                        <IHTPInput controlValue={true} value={email} disabled={canChangeEmail == false} type='text' label='Email' placeholder='Insere o teu email' onChange={setEmail} />
                        <IHTPSpacer verticalSpace="10px" />
                        <IHTPInput ref={fullNameInput} controlValue={true} value={fullName} type='text' label={intl.formatMessage({ id: "registar.nomeCompletoTitle" })} placeholder={intl.formatMessage({ id: "registar.nomeCompletoPlaceholder" })} onChange={setFullName} />
                        <IHTPSpacer verticalSpace="10px" />

                        {isRegisterFromFacebook == false && isRegisterFromGoogle == false && (
                            <>
                                <IHTPInput type='password' label={intl.formatMessage({ id: "registar.passwordTitle" })} placeholder={intl.formatMessage({ id: "registar.passwordPlaceholder" })} onChange={setPassword}
                                    onFocus={() => setShowPasswordErrors(true)} onBlur={() => isPasswordValid == true ? setShowPasswordErrors(false) : null} />
                                <IHTPSpacer verticalSpace="10px" />

                                <PasswordCheckList minimumCharacters={8}
                                    shouldHaveAtLeastOneLetter={true}
                                    shouldHaveAtLeastOneNumber={true}
                                    shouldHaveAtLeastOneLowercaseLetter={true}
                                    shouldHaveAtLeastOneCapitalLetter={true}
                                    password={password}
                                    onChange={setIsPasswordValid}
                                    showComponent={showPasswordErrors}
                                />

                                <IHTPSpacer verticalSpace="10px" />
                                <IHTPInput type='text' label={intl.formatMessage({ id: "registar.emailRecomendacaoPlaceholderTitle" })} placeholder={intl.formatMessage({ id: "registar.emailRecomendacaoPlaceholder" })} onChange={setRecommendationEmail} />
                                <IHTPSpacer verticalSpace="10px" />
                                <IHTPInput type='text' label={intl.formatMessage({ id: "registar.telefoneTitle" })} placeholder={intl.formatMessage({ id: "registar.telefonePlaceholder" })} onChange={setPhoneNumber} />
                                <IHTPSpacer verticalSpace="10px" />
                            </>
                        )}

                        <span onClick={() => setOpenExtraField(!openExtraField)} style={{ textAlign: "left", cursor: "pointer", fontWeight: "600", fontSize: "13px", fontFamily: "Montserrat", color: "rgba(71, 73, 79, 1)" }}>
                            {intl.formatMessage({ id: "registar.camposExtra" })} {openExtraField == false ? <MdExpandMore size={20} /> : <MdExpandLess size={20} />}
                        </span>

                        <Collapse in={openExtraField}>
                            <IHTPSpacer verticalSpace="10px" />
                            <div style={{ padding: "1rem", border: "1px solid rgba(71, 73, 79, 0.5)", borderRadius: "1rem" }}>
                                <IHTPSelect label={intl.formatMessage({ id: "registar.paisCountry" })} options={countries} placeholderItem={intl.formatMessage({ id: "registar.paisPlaceholder" })} onChange={setCountry} />
                                <IHTPSpacer verticalSpace="10px" />
                                <IHTPInput type='text' label={intl.formatMessage({ id: "registar.localidadeTitle" })} placeholder={intl.formatMessage({ id: "registar.localidadePlaceholder" })} onChange={setCity} />
                                <IHTPSpacer verticalSpace="10px" />
                                <IHTPInput type='date' label={intl.formatMessage({ id: "registar.dataNascimentoTitle" })} placeholder='' onChange={setBirthDate} />
                            </div>
                        </Collapse>

                        <IHTPSpacer verticalSpace="20px" />
                        <div style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                            <IHTPRecaptcha setRecaptchaVerified={setRecaptchaVerified} />
                        </div>

                        <IHTPSpacer verticalSpace="30px" />
                        <IHTPCheckbox value={acceptedRGPD} onChange={setAcceptedRGPD} textComponent={
                            <span style={{ fontSize: "13px", fontWeight: "500" }}>
                                <FormattedMessage id="registar.politicaDados1" />
                                <a href={Routes.empresa.protecaoDados} className="ihtpLink" target="_blank">
                                    <FormattedMessage id="registar.politicaDados2" />
                                </a>.
                            </span>
                        } />
                        <IHTPSpacer verticalSpace="10px" />
                        <IHTPButton text={intl.formatMessage({ id: "registar.registarButton" })} onClick={handleFormSubmit} loading={loadingRegister}
                            disabled={acceptedRGPD === false || recaptchaVerified === false}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={5} xl={4} >
                        <RegisterBenefits />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
