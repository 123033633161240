import Cookies from 'js-cookie';
import { createRef, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from 'react-redux';
import { setUserToRegister } from '../../../actions/miniCartActions';
import Routes from '../../../helpers/Routes';
import IHTPCheckbox from '../../Common/IHTPCheckbox';
import IHTPInput from "../../Common/IHTPInput";

export default function GuestCheckout() {
    const intl = useIntl();
    const dispatch = useDispatch();

    const emailInput = createRef<HTMLInputElement>();
    const fullNameInput = createRef<HTMLInputElement>();

    const [email, setEmail] = useState<string>("");
    const [fullName, setFullName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [acceptedRGPD, setAcceptedRGPD] = useState<boolean>(false);

    useEffect(() => {
        setUserToRegisterData();
        return () => { }
    }, [email, fullName, phoneNumber, acceptedRGPD])


    const setUserToRegisterData = async () => {
        var newUser = {
            email: email,
            nomeCompleto: fullName,
            password: null,
            emailRecomendacao: '',
            telefone: phoneNumber,
            refer: Cookies.get("refer"),
            gdpr: acceptedRGPD === true ? "S" : "N",
            dataNascimento: "",
            localidade: "",
            pais: "",
            isPasswordValid: true,
            isRegisterFromFacebook: false,
            facebookData: null,
            isRegisterFromGoogle: false,
            googleData: null
        }

        await dispatch(setUserToRegister(newUser));
    }

    return (
        <Row>
            <Col xl={2} lg={4} md={4} sm={6} xs={12}>
                <IHTPInput
                    type="text"
                    onChange={setEmail}
                    controlValue={true}
                    ref={emailInput}
                    value={email}
                    label={intl.formatMessage({ id: "login.enderecoEmail" })}
                    placeholder={intl.formatMessage({ id: "login.enderecoEmailPlaceholder" })}
                    required={true} />
            </Col>

            {/* Nome completo */}
            <Col xl={2} lg={4} md={4} sm={6} xs={12}>
                <IHTPInput type="text"
                    label={intl.formatMessage({ id: "registar.nomeCompletoTitle" })}
                    placeholder={intl.formatMessage({ id: "registar.nomeCompletoPlaceholder" })}
                    value={fullName}
                    ref={fullNameInput}
                    controlValue={true}
                    onChange={setFullName}
                    required={true} />
            </Col>

            {/* Telefone */}
            <Col xl={2} lg={4} md={4} sm={6} xs={12}>
                <IHTPInput type="text"
                    label={intl.formatMessage({ id: "registar.telefoneTitle" })}
                    placeholder={intl.formatMessage({ id: "registar.telefonePlaceholder" })}
                    value={phoneNumber}
                    onChange={setPhoneNumber} />
            </Col>

            {/* Termos e condições */}
            <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginTop: "10px" }}>
                <IHTPCheckbox value={acceptedRGPD}
                    onChange={setAcceptedRGPD}
                    required={true}
                    textComponent={
                        <span style={{ fontSize: "13px", fontWeight: "500" }}>
                            <FormattedMessage id="registar.politicaDados1" />
                            <a href={Routes.empresa.protecaoDados} className="ihtpLink" target="_blank">
                                <FormattedMessage id="registar.politicaDados2" />
                            </a>.
                        </span>
                    } />
            </Col>
        </Row>
    )
}