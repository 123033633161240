import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import HeaderDesktop from "../../../components/Desktop/Header/Header";
import LoginDesktop from "../../../components/Login/Login/Login";
import Footer from "../../../components/Footer/Footer";
import RoutesObject from "../../../helpers/Routes";

class Login extends Component {

  constructor(props) {
    super(props)
  }

  componentWillMount() {

    //Se utilizador logado, mandamos para o carrinho
    if (typeof this.props.isUserLoggedIn !== 'undefined') {
      window.location.href = RoutesObject.shop.cart;
      return false;

    } else { //Caso contrário deixamos fazer login
      return true;
    }
  }


  render() {

    var previousInCheckout = false;

    var state = this.props.location.state;
    if (typeof state !== 'undefined' && state != null) {
      if (typeof state.previousInCheckout !== 'undefined' && state.previousInCheckout === true) {
        previousInCheckout = true;
      }
    }
    return (
      <div className="App">
        <HeaderDesktop />
        <Container className="mainContentContainer">
          <Row style={{ textAlign: "center" }} >
            <Col xs="12" sm="12" md="12" lg="12">
              <LoginDesktop history={this.props.history} previousInCheckout={previousInCheckout} />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default connect(mapStateToProps)(Login);
