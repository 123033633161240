import Skeleton from '@material-ui/lab/Skeleton';
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { removeCredits, selectCredits } from "../../actions/miniCartActions";
import Toast from '../../helpers/Toast';
import useCurrencies from '../../hooks/useCurrencies';
import { IsValidNumber } from "../../Utils/UsefulFunctions";
import { HasError } from "../../Utils/UtilsCheckout";
import IHTPButton from '../Common/IHTPButton';
import IHTPInput from '../Common/IHTPInput';
import IHTPSpacer from '../Common/IHTPSpacer';
import Label, { Type } from '../Common/Label';
import "./Checkout.css";
import useCheckoutDisabledInputs from './useCheckoutDisabledInputs';

export default function Creditos() {

	const [credits, setCredits] = useState<string>("");
	const [hasError, setHasError] = useState<boolean>(false);
	const [loadingCredits, setLoadingCredits] = useState<boolean>(false);

	const creditosPermitidos = useSelector((state: any) => state.checkoutData.creditosPermitidos);
	const currentCheckoutCredits = useSelector((state: any) => state.checkoutData.credits);
	const currencies = useCurrencies();
	const loadingCheckoutPrices = useSelector((state: any) => state.loadingCheckoutPrices);
	const errorCode = useSelector((state: any) => state.errorCode);

	const { disabledFields } = useCheckoutDisabledInputs();

	const intl = useIntl();
	const dispatch = useDispatch();

	var currencySelected = currencies.selected;
	var loadingPrices = loadingCheckoutPrices;

	useEffect(() => {
		let tmpHasError = HasError(errorCode, ["Creditos_Vales", "Creditos_Codigos", "Creditos"]);
		if (hasError !== tmpHasError) {
			setHasError(tmpHasError);
		}
		return () => { }
	}, [errorCode])

	useEffect(() => {
		if (hasError === true) {
			let el = document.getElementById("creditos");
			if (el != null) el.scrollIntoView({ block: "center" });
		}
	}, [hasError])

	const handleChange = (text) => {

		if (IsValidNumber(text) === false) {
			setCredits("");
			return;
		}

		let finalValue = Number(text);
		let maxAllowedCredits = parseFloat(creditosPermitidos);

		if (finalValue <= maxAllowedCredits) {
			setCredits(text);
		}
	};

	const handleFormSubmit = async () => {

		//Verificar o valor dos credits preenchidos
		var tmpCredits = credits;
		if (!credits || credits === "0") {
			tmpCredits = String(creditosPermitidos ?? 0);
		}

		var totalCreditos = 0;

		//Substituimos , para . 
		tmpCredits = tmpCredits.replace(/,/g, ".");

		//Se não for um número ou for um valor menor que 0 mostramos erro
		var creditsInNumber = Number(tmpCredits);
		if (isNaN(creditsInNumber) || creditsInNumber < 0) {
			Toast.Show("error", intl.formatMessage({ id: "creditos.erro.valorInvalido" }));
			return;
		}

		if (typeof currentCheckoutCredits !== "undefined" && currentCheckoutCredits != null && Number.isNaN(Number(currentCheckoutCredits)) == false) {
			totalCreditos = parseFloat(Number(currentCheckoutCredits) + "") + parseFloat(tmpCredits);
		} else {
			totalCreditos = parseFloat(tmpCredits);
		}

		setLoadingCredits(true);
		var data: any = await dispatch(selectCredits(totalCreditos));
		setLoadingCredits(false);

		if (data.success === true) {
			setCredits("");
			Toast.Show("success", intl.formatMessage({ id: "creditos.sucesso" }));
			return;
		}

		setCredits("");
		await dispatch(removeCredits());
		Toast.Show("error", data.message);
	}

	return (
		<div>
			<div
				className={hasError === true ? "checkoutBox errorBorder"
					:/* Validar se existe créditos metendo a disabled se o valor for nulo ou 0 */
					creditosPermitidos === 0 || creditosPermitidos == null
						? "checkoutBox disabledCreditsBox"
						: "checkoutBox "
				}
				id="creditos"
			>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12} className="checkoutBoxTitle">
						<FormattedMessage id="creditos.titulo" />
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>

						<IHTPSpacer verticalSpace='10px' />

						<IHTPInput style={{ textAlign: "center" }} hideLabel={true}
							placeholder={intl.formatMessage({ id: "creditos.inserirvalor" })}
							type="number" min={0}
							onChange={(t) => handleChange(t)} controlValue={true} value={credits}
							disabled={disabledFields} />

						<IHTPSpacer verticalSpace='10px' />

						<div className='discountsText'>
							<span className='creditsAvailableContainer'>
								<span><FormattedMessage id="creditos.utilizados" /></span>
								<span style={{ fontWeight: "600", color: "rgb(202, 0, 106)" }}>
									{loadingPrices === true ? (
										<Skeleton variant="text" width={50} height={24} />
									) : (
										<Label
											text={(currentCheckoutCredits ?? 0).toFixed(2) ?? 0}
											labelType={{
												type: Type.currency,
												currency: currencies.selectedDescription
											}} />
									)}
								</span>
							</span>

							<IHTPSpacer verticalSpace='10px' />

							<span className='creditsAvailableContainer'>
								<span><FormattedMessage id="creditos.consegueutilizar" /></span>
								<span style={{ fontWeight: "600", color: "rgb(202, 0, 106)" }}>
									{loadingPrices === true ? (
										<Skeleton variant="text" width={50} height={24} />
									) : (
										<Label
											text={(creditosPermitidos ?? 0).toFixed(2) ?? 0}
											labelType={{
												type: Type.currency,
												currency: currencies.selectedDescription
											}} />
									)}
								</span>
							</span>

						</div>

						<IHTPSpacer verticalSpace='10px' />

						<div className="buttonsValidarCodigos">
							<IHTPButton onClick={handleFormSubmit}
								text={intl.formatMessage({ id: "creditos.validar" })}
								buttonStyle="secondary"
								loading={loadingCredits}
								disabled={disabledFields}
							/>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	)
}