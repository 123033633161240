import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from "react-intl";

class MainPageButton extends Component {
    render() {
        return (
            <a href={this.props.intl.formatMessage({ id: "header.mainPageURL" })} className="headerLinks">
                <span>
                    <FormattedMessage id="header.mainPage" />
                </span>
            </a>
        )
    }
}
export default injectIntl(MainPageButton);