import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from 'reactstrap'
import { getAddress, modifyBillingAddress, setBillingAddress, setErrorCodeCheckout } from "../../../actions/miniCartActions";
import Toast from "../../../helpers/Toast";
import IHTPButton from '../../Common/IHTPButton'
import IHTPInput from '../../Common/IHTPInput'
import IHTPSelect, { IHTPSelectItem } from '../../Common/IHTPSelect'
import IHTPSpacer from '../../Common/IHTPSpacer'
import { ValidateBillingAddress } from '../../../Utils/UtilsCheckout'

export interface EditBillingAddressProps {
    onClose: () => void,
    billingDTO: EditBillingDTO
}

export class EditBillingDTO {
    isOpen: boolean = false;
    billingAddressId: number | null = null;
}

const novaMoradaInitialState = {
    id_cliente_morada: "",
    nome: "",
    nif: "",
    morada: "",
    localidade: "",
    codigoPostal: "",
    principal: "N",
    id_pais: "",
    ID_Tipo_Morada: ""
}

export default function EditBillingAddress(props: EditBillingAddressProps) {

    const [alterarMorada, setAlterarMorada] = useState(novaMoradaInitialState);
    const [loadingModifyAddress, setLoadingModifyAddress] = useState<boolean>(false);
    const [loadingInput, setLoadingInput] = useState<boolean>(true);
    const dispatch = useDispatch();

    const intl = useIntl();

    const allPaises = useSelector((state: any) => state.allPaises);

    const paisesOptions: IHTPSelectItem[] = useMemo(() => {
        if (typeof allPaises === "undefined" || allPaises == null) return [];

        var paises: IHTPSelectItem[] = [];
        allPaises.forEach(i => {
            paises.push({
                value: i.alpha3,
                label: i.name
            });
        })
        return paises;
    }, [allPaises])


    const [defaultAddressOptions, setDefaultAddressOptions] = useState<IHTPSelectItem[]>([
        { value: "N", label: intl.formatMessage({ id: "facturacao.novaMorada.padrao.no" }) },
        { value: "S", label: intl.formatMessage({ id: "facturacao.novaMorada.padrao.yes" }) }
    ]);

    useEffect(() => {

        if (props.billingDTO.isOpen === true && props.billingDTO.billingAddressId != null) {
            loadAddress(props.billingDTO.billingAddressId);
        }

        return () => { }
    }, [props.billingDTO.isOpen, props.billingDTO.billingAddressId])

    const loadAddress = async (addressId: number) => {
        setLoadingInput(true);
        var resultGetAddress: any = await dispatch(getAddress(addressId));
        setAlterarMorada((prevState) => ({
            ...prevState,
            id_cliente_morada: resultGetAddress.data.address[0].ID_Cliente_Morada,
            nome: resultGetAddress.data.address[0].Nome,
            nif: resultGetAddress.data.address[0].Nif,
            morada: resultGetAddress.data.address[0].Morada,
            localidade: resultGetAddress.data.address[0].Localidade,
            codigoPostal: resultGetAddress.data.address[0].CodigoPostal,
            principal: resultGetAddress.data.address[0].Principal,
            id_pais: resultGetAddress.data.address[0].Pais
        }));

        setLoadingInput(false);
    }


    const handleCleanModifyBillingAddress = () => {
        setAlterarMorada(novaMoradaInitialState);
        props.onClose();
    };

    const handleModifyBillingChangePais = (value: string) => {
        setAlterarMorada((prevState) => ({
            ...prevState,
            id_pais: value,
            nif: ""
        }));
    };

    const handleModifyAddressValidateNif = (value: string) => {
        if (alterarMorada.id_pais === "PRT") {
            if (value.length < 10 && /^\d*$/.test(value)) {
                setAlterarMorada((prevState) => ({
                    ...prevState,
                    nif: value
                }));
            }
        } else {
            setAlterarMorada((prevState) => ({
                ...prevState,
                nif: value
            }));
        }
    }

    const handleFormModifyBillingAddressSubmit = async () => {

        var validateAddress = ValidateBillingAddress(alterarMorada, intl, dispatch);
        if (validateAddress.success === false) {
            Toast.Show("error", validateAddress.message);
            return;
        }

        setLoadingModifyAddress(true);
        var data: any = await dispatch(modifyBillingAddress(alterarMorada));
        setLoadingModifyAddress(false);

        if (data.success != true) {
            Toast.Show("error", `${data.message}`);
            return;
        }

        Toast.Show("success", `${data.message}`);

        await dispatch(setErrorCodeCheckout(""))

        props.onClose();
    }

    return (
        <Row>
            <div className="billingModifyAddress">
                <Col xs={12} sm={12} md={12} lg={12}>

                    <Row className="billingRowTitleAddress">
                        <span className="titleOptionsAddress">
                            <FormattedMessage id="facturacao.modificarMoradaTitle" />
                        </span>
                    </Row>

                    {/* Nome da Morada */}
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>

                            {/* Morada */}
                            <IHTPInput label={intl.formatMessage({ id: "facturacao.modificarMorada.nome.label" })}
                                placeholder={intl.formatMessage({ id: "facturacao.modificarMorada.nome" })}
                                onChange={(text) => setAlterarMorada(prev => ({ ...prev, nome: text }))}
                                value={alterarMorada.nome} controlValue={true} type="text"
                                showLoadingSkeleton={loadingInput}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPInput label={intl.formatMessage({ id: "facturacao.modificarMorada.morada.label" })}
                                placeholder={intl.formatMessage({ id: "facturacao.modificarMorada.morada" })}
                                onChange={(text) => setAlterarMorada(prev => ({ ...prev, morada: text }))}
                                value={alterarMorada.morada} controlValue={true} type="text"
                                showLoadingSkeleton={loadingInput}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            {/* Localidade*/}
                            <IHTPInput label={intl.formatMessage({ id: "facturacao.modificarMorada.localidade.label" })}
                                placeholder={intl.formatMessage({ id: "facturacao.modificarMorada.localidade" })}
                                onChange={(text) => setAlterarMorada(prev => ({ ...prev, localidade: text }))}
                                value={alterarMorada.localidade} controlValue={true} type="text"
                                showLoadingSkeleton={loadingInput}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            {/* Codigo Postal */}
                            <IHTPInput label={intl.formatMessage({ id: "facturacao.modificarMorada.codigoPostal.label" })}
                                placeholder={intl.formatMessage({ id: "facturacao.modificarMorada.codigoPostal" })}
                                onChange={(text) => setAlterarMorada(prev => ({ ...prev, codigoPostal: text }))}
                                value={alterarMorada.codigoPostal} controlValue={true} type="text"
                                showLoadingSkeleton={loadingInput}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            {/* Pais da Morada */}
                            <IHTPSelect label={intl.formatMessage({ id: "facturacao.modificarMorada.id_pais.label" })}
                                options={paisesOptions}
                                placeholderItem={intl.formatMessage({ id: "facturacao.modificarMorada.id_pais" })}
                                onChange={(text) => handleModifyBillingChangePais(text)}
                                controlValue={true}
                                value={alterarMorada.id_pais}
                                showLoadingSkeleton={loadingInput}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            {/* Nif */}
                            <IHTPInput label={intl.formatMessage({ id: "facturacao.modificarMorada.nif.label" })}
                                placeholder={alterarMorada.id_pais
                                    ? intl.formatMessage({ id: "facturacao.modificarMorada.nif" })
                                    : intl.formatMessage({ id: "facturacao.modificarMorada.nif1" })}
                                onChange={(text) => handleModifyAddressValidateNif(text)}
                                value={alterarMorada.nif} controlValue={true} type="text"
                                showLoadingSkeleton={loadingInput}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPSelect label={intl.formatMessage({ id: "facturacao.modificarMorada.padrao.label" })}
                                options={defaultAddressOptions}
                                hasPlaceholder={false}
                                onChange={(text) => setAlterarMorada(prev => ({ ...prev, principal: text }))}
                                controlValue={true} value={alterarMorada.principal}
                                width="auto"
                                showLoadingSkeleton={loadingInput}
                            />

                            <IHTPSpacer verticalSpace={"20px"} />

                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                <Col xl={7} lg={8} md={8} style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "1rem" }}>
                                    <IHTPButton text={intl.formatMessage({ id: "facturacao.modificarMoradaTitle.modifyMoradasButton" })} disabled={loadingInput} loading={loadingModifyAddress} onClick={handleFormModifyBillingAddressSubmit} />
                                    <IHTPButton text={intl.formatMessage({ id: "common.button.cancelar" })} buttonStyle="secondary" onClick={handleCleanModifyBillingAddress} disabled={loadingModifyAddress} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Row>
    )
}
