import React, { Component } from "react";
import { FaFacebook } from "react-icons/fa";

export default class Facebook extends Component {
  render() {
    return (
      <a className="icons" href="https://www.facebook.com/IHTPGLOBAL" target="_blank">
        <FaFacebook />
      </a>
    );
  }
}

