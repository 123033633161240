
import Joi from "joi";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastr";
import { Col, Row } from "reactstrap";
import { recoverPassword } from "../../actions/miniCartActions";
import Routes from "../../helpers/Routes";
import IHTPButton from "../Common/IHTPButton";
import IHTPInput from "../Common/IHTPInput";
import IHTPSpacer from "../Common/IHTPSpacer";
import { EmailSchema } from "../Helpers/JoiValidations";
import "./RecoverPassword.css";

let container;
export default function RecoverPassword() {

    const intl = useIntl();
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const dispatch = useDispatch();
    const isVerified = useSelector((state: any) => state.isVerified);
    const [error, setError] = useState<string>("");
    const history = useHistory();

    const handleFormSubmit = async () => {

        if (isVerified) {

            const emailDTOSchema = Joi.object({
                email: EmailSchema(false, intl)
            });

            var result = emailDTOSchema.validate({ email });
            if (result.error != null) {
                var message = result.error.details[0].message;
                setError(message);
                return;
            }

            setError("");
            setLoading(true);
            var data: any = await dispatch(recoverPassword(email))
            setLoading(false);

            if (data.success === true) {
                container.clear();
                container.success(`${data.message}`);

                setTimeout(() => {
                    history.push(Routes.account.login);
                }, 2000);
            } else {
                setError(`${data.message}`);
            }

        } else {
            setError(intl.formatMessage({ id: "recuperarPassword.dadosNãoPreenchidos" }));
        }
    };

    return (
        <Row>
            <Col xs="12" sm="12" md="12" lg="12">

                <ToastContainer
                    ref={ref => (container = ref)}
                    className="toast-bottom-right"
                />

                {/* Título */}
                <div className="recoverPasswordTitleParent">
                    <div className="recoverPasswordTitle">
                        <FormattedMessage id="recuperarPassword.titulo" />
                    </div>
                </div>
                <br />

                {error != null && error.length > 0 && (
                    <Row style={{ display: "flex", justifyContent: "center" }}>
                        <Col xs={12} sm={12} md={8} lg={5} xl={3}>
                            <div className="emailErrorContainer">
                                <span>{error}</span>
                            </div>
                            <IHTPSpacer verticalSpace="20px" />
                        </Col>
                    </Row>
                )}

                <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col xs={12} sm={12} md={8} lg={5} xl={3}>
                        <IHTPInput label={intl.formatMessage({ id: "recuperarPassword.email" })} placeholder={intl.formatMessage({ id: "recuperarPassword.emailPlaceholder" })}
                            onChange={setEmail} onEnter={handleFormSubmit} type={"text"} />

                        <IHTPSpacer verticalSpace="20px" />

                        <IHTPButton text={intl.formatMessage({ id: "recuperarPassword.recuperarPasswordButton" })} loading={loading}
                            onClick={handleFormSubmit} />

                        <IHTPSpacer verticalSpace="20px" />

                        <div style={{ textAlign: "right" }}>
                            <NavLink className="ihtpLink" to={Routes.account.login}>
                                <FormattedMessage id="recuperarPassword.loginButton" />
                            </NavLink>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}