import Joi from 'joi';
import { createRef, useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { login } from '../../../actions/miniCartActions';
import { verifyEmailLogin } from '../../../actions/userActions';
import { funnelStep } from '../../../Analytics/DataLayer';
import CrossDomainEventHandler from '../../../helpers/CrossDomainEventHandler';
import { default as Routes, default as RoutesObject } from '../../../helpers/Routes';
import { LoginPropsDTO } from '../../../models/LoginPropsDTO';
import { RegisterRouteStateDTO } from '../../../models/RegisterRouteStateDTO';
import IHTPButton from '../../Common/IHTPButton';
import IHTPInput from '../../Common/IHTPInput';
import IHTPSpacer from '../../Common/IHTPSpacer';
import { EmailSchema } from '../../Helpers/JoiValidations';
import "../../LoginComponents/Login.css";
import SocialLogin from '../../LoginComponents/SocialLogin';
import { VerifyEmailRouteState } from '../../VerifyEmail/VerifyEmail';


const URL_TO_REDIRECT_AFTER_LOGIN: string = process.env.REACT_APP_MAIN_WEBSITE_SHOP_URL ?? "";
export default function Login(props: LoginPropsDTO) {

    const history = useHistory();
    const user = useSelector((state: any) => state.user);
    const dispatch = useDispatch();
    const passwordInput = createRef<HTMLInputElement>();
    const intl = useIntl();

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [shouldConfirmEmail, setShouldConfirmEmail] = useState<boolean>(false);
    const [canFullfilLogin, setCanFullfilLogin] = useState<boolean>(false);
    const [loadingVerifyEmail, setLoadingVerifyEmail] = useState<boolean>(false);
    const [loadingLogin, setLoadingLogin] = useState<boolean>(false);

    const verifyEmail = async () => {

        try {
            const emailDTOSchema = Joi.object({
                email: EmailSchema(false, intl)
            });

            var result = emailDTOSchema.validate({ email });
            if (result.error != null) {
                var message = result.error.details[0].message;
                setError(message);
                setLoadingVerifyEmail(false);
                return;
            }

            setLoadingVerifyEmail(true);
            var resultVerify = await verifyEmailLogin(email);
            setLoadingVerifyEmail(false);

            if (resultVerify.success != true) {
                setError(resultVerify.message);
                return;
            }

            if (resultVerify.obj.isRegistered == false) {

                var state: RegisterRouteStateDTO = {
                    email: email,
                    previousInCheckout: props.previousInCheckout
                }
                history.push(Routes.account.registo, state);
                return;
            }

            if (resultVerify.obj.isRegistered == true && resultVerify.obj.isSocialLogin == true) {
                setError(resultVerify.message);
                return;
            }

            if (resultVerify.obj.isRegistered === true && resultVerify.obj.shouldConfirmEmail === true) {
                setError(resultVerify.message);
                setShouldConfirmEmail(true);
                return;
            }

            if (resultVerify.obj.isRegistered === true && resultVerify.obj.isConfirmed === false) {
                var stateEmail: VerifyEmailRouteState = {
                    email: email,
                    previousInCheckout: props.previousInCheckout,
                    shouldLoginAfterConfirm: false
                }
                history.push(Routes.account.verify, stateEmail);
                return;
            }

            setError("");
            setCanFullfilLogin(true);
        } catch (exp) {
            setError(intl.formatMessage({ id: "login.erro.desconhecido" }));
            setCanFullfilLogin(false);
        }
    }

    useEffect(() => {
        if (canFullfilLogin == true) {
            if (passwordInput != null && passwordInput.current != null) {
                passwordInput.current.focus();
            }
        }
    }, [canFullfilLogin])

    const onLoginClick = async () => {
        try {
            const x: any = window;
            setError("");
            setLoadingLogin(true);
            var data: any = await dispatch(login(email, password));
            setLoadingLogin(false);

            //Se conseguirmos fazer login com sucesso
            if (data.success === true) {

                funnelStep(2);
                // Enviar mensagem para GTM
                x.dataLayer = x.dataLayer || [];
                x.dataLayer.push({
                    event: "Login",
                    loginMessage: "Sucesso",
                    userType: user.userType,
                    userID: user.userID
                });

                CrossDomainEventHandler.sendEvent();

                //Se antes estavamos no checkout e viemos para aqui por causa do login
                //mandamos de volta para o checkout apos fazer login
                if (props.previousInCheckout === true) {
                    window.location.href = "/shop/checkout";
                } else {
                    window.location.href = URL_TO_REDIRECT_AFTER_LOGIN;
                }

            } else {
                setError(data.message);

                // Enviar mensagem para GTM
                x.dataLayer = x.dataLayer || [];
                x.dataLayer.push({
                    event: "Login",
                    loginMessage: `Erro - ${data.message}`
                });
            }
        } catch (exp) {
            setError(intl.formatMessage({ id: "login.erro.desconhecido" }));
        }
    }

    const changeEmail = () => {
        setCanFullfilLogin(false);
        setError("");
        setEmail("");
    }

    const recoverPassword = () => {
        history.push(Routes.account.recuperar);
    }

    return (
        <Row>
            <Col xs="12" sm="12" md="12" lg="12">

                <div className="loginTitleParent">
                    <div className="loginTitle">
                        <FormattedMessage id="login.titulo" />
                    </div>
                </div>
                <br />

                {error != null && error.length > 0 && (
                    <Row style={{ display: "flex", justifyContent: "center" }}>
                        <Col xs={12} sm={12} md={8} lg={5} xl={3}>
                            <div className="emailErrorContainer">
                                {shouldConfirmEmail === true ? (
                                    <span>
                                        <FormattedMessage id={"login.deveConfirmarEmail.1"} />
                                        <NavLink className="emailConfirmLink" to={RoutesObject.account.recuperar}>
                                            <FormattedMessage id={"login.deveConfirmarEmail.2"} />
                                        </NavLink>
                                        .
                                    </span>
                                ) : (
                                    <span>{error}</span>
                                )}
                            </div>
                            <IHTPSpacer verticalSpace="20px" />
                        </Col>
                    </Row>
                )}

                {canFullfilLogin === false ? (
                    <Row style={{ display: "flex", justifyContent: "center" }}>
                        <Col xs={12} sm={12} md={8} lg={5} xl={3}>
                            <IHTPInput type="text" onChange={setEmail} onEnter={verifyEmail}
                                label={intl.formatMessage({ id: "login.enderecoEmail" })} placeholder={intl.formatMessage({ id: "login.enderecoEmailPlaceholder" })} />
                            <IHTPSpacer verticalSpace="20px" />
                            <IHTPButton text={intl.formatMessage({ id: "login.entrarOuRegistar" })} loading={loadingVerifyEmail} onClick={verifyEmail} />
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ display: "flex", justifyContent: "center", textAlign: "left" }}>
                        <Col xs={12} sm={12} md={8} lg={5} xl={3}>

                            <div style={{ fontSize: "13px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span style={{ fontWeight: "600", fontFamily: "Montserrat", color: "rgba(71, 73, 79, 1)" }}>{intl.formatMessage({ id: "login.enderecoEmail" })}</span>
                                </div>
                                <span style={{ fontWeight: "500", textAlign: "left", fontFamily: "Montserrat", color: "rgba(71, 73, 79, 1)" }}>{email}</span>
                                <span onClick={changeEmail} className='ihtpLink' style={{ marginLeft: "0.4rem" }}>({intl.formatMessage({ id: "login.enderecoEmailChange" })})</span>
                            </div>

                            <IHTPSpacer verticalSpace="10px" />

                            <IHTPInput ref={passwordInput} type="password" onChange={setPassword} label={intl.formatMessage({ id: "login.palavraPasse" })} onEnter={onLoginClick} placeholder={intl.formatMessage({ id: "login.passwordPlaceholder" })}
                                labelExtraOption={
                                    <span onClick={recoverPassword} className='ihtpLink'>{intl.formatMessage({ id: "login.recuperarPassword" })}</span>
                                }
                            />
                            <IHTPSpacer verticalSpace="20px" />
                            <IHTPButton text={intl.formatMessage({ id: "login.entrar" })} loading={loadingLogin} onClick={onLoginClick} />
                        </Col>
                    </Row>
                )}

                <IHTPSpacer verticalSpace="60px" />
                <hr />
                <SocialLogin previousInCheckout={props.previousInCheckout}
                    xl={6} lg={6} md={6} sm={6} xs={6}
                    showText={true}
                    isRegisteringInCheckout={false} />
            </Col>
        </Row>
    )
}