import React, { useMemo } from 'react'
import { Input } from 'reactstrap'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export interface IHTPSelectProps {
    label: string,
    placeholderItem?: string,
    hasPlaceholder?: boolean,
    options: IHTPSelectItem[],
    onChange: (text: string) => void,
    controlValue?: boolean,
    value?: string | number | undefined,
    width?: string,
    showLoadingSkeleton?: boolean,
    disabled?: boolean
}

export interface IHTPSelectItem {
    value: string | number,
    label: string
}

export default function IHTPSelect(props: IHTPSelectProps) {

    const computedWidth = useMemo(() => {
        if (typeof props.width === "undefined") return "100%";
        return props.width;
    }, [props.width])

    return (
        <>
            <span style={{ fontWeight: "600", fontSize: "13px", fontFamily: "Montserrat", color: "rgba(71, 73, 79, 1)" }}>{props.label}</span>
            {typeof props.showLoadingSkeleton !== "undefined" &&
                props.showLoadingSkeleton === true ? (
                <>
                    <Skeleton style={{ border: "1px solid #ced4da" }} height={"2rem"} />
                </>
            ) : (
                <Input {...props.controlValue == true && { value: props.value }} type="select" disabled={props.disabled} style={{ fontSize: "13px", width: computedWidth }} placeholder="Teste" onChange={(e) => props.onChange(e.target.value)}>
                    {props.hasPlaceholder !== false && (
                        <option value={""}>{props.placeholderItem}</option>
                    )}
                    {props.options.length > 0 && props.options.map(o => {
                        return <option value={o.value}>{o.label}</option>
                    })}
                </Input>
            )}
        </>
    )
}
