import React from 'react'
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface IHTPButtonProps {
    text: string,
    onClick: () => void,
    loading?: boolean,
    disabled?: boolean,
    buttonStyle?: "primary" | "secondary"
}

export default function IHTPButton(props: IHTPButtonProps) {

    const isDisabled = (): boolean => {
        if (typeof props.loading !== 'undefined' && props.loading === true) return true;
        if (typeof props.disabled !== 'undefined' && props.disabled === true) return true;
        return false;
    }

    const isLoading = (): boolean => {
        if (typeof props.loading !== 'undefined' && props.loading === true) return true;
        return false;
    }

    const getBackgroundColor = () => {
        if (props.buttonStyle == "secondary") return "white";
        return "#CA006A";
    }

    const getColor = () => {
        if (props.buttonStyle == "secondary") return "#CA006A";
        return "white";
    }

    const getBorder = () => {
        if (props.buttonStyle == "secondary") return "0.5px solid #CA006A";
        return "";
    }

    return (
        <Button
            style={{
                backgroundColor: getBackgroundColor(),
                color: getColor(),
                width: "100%",
                fontFamily: "Montserrat",
                fontWeight: "500",
                textTransform: "inherit",
                fontSize: "13px",
                opacity: isDisabled() ? 0.6 : 1,
                border: getBorder()
            }}
            disabled={isDisabled()}
            onClick={props.onClick}>
            {props.text}

            {isLoading() === true && (
                <CircularProgress size={18} style={{ marginLeft: "1rem", color: getColor() }} />
            )}
        </Button>
    )
}
