import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import HeaderDesktop from "../../../../components/Desktop/Header/Header";
import Cart from "../../../../components/Shop/Cart/Cart";
import RelatedProductsToCart from "../../../../components/Shop/RelatedProductsToCart/RelatedProductsToCart";

export default class CartView extends Component {

  render() {
    return (
      <div className="App">
        <HeaderDesktop />
        <Container className="mainContentContainer">
          <Row style={{ textAlign: "center" }} >
            <Col xs="12" sm="12" md="12" lg="12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Cart />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}