import { instanceOf } from "prop-types";
import React, { Component } from "react";
import { Cookies, withCookies } from "react-cookie";
import { loadReCaptcha } from "react-recaptcha-google";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { changeCurrencyFE, sendUserID } from "../actions/miniCartActions";
import DetectLeave from "../components/DetectLeave/DetectLeave";
import LoadingFullPage from "../components/Helpers/LoadingFullPage";
import ListeningToEvent from "../components/ListeningToEvent/ListeningToEvent";
import ChangePassword from "../components/Login/ChangePassword/ChangePassword";
import PaymentMB from "../components/Payment/Payment";
import PaymentEbanx from "../components/Payment/PaymentEbanx";
import PaymentMBWay from "../components/Payment/PaymentMBWay";
import PaymentPaypal from "../components/Payment/PaymentPaypal";
import RedirectComponent from "../components/Redirects/RedirectComponent";
import TawkTo from "../components/TawkTo/TawkTo";
// Caminhos para as páginas
import Routes from "../helpers/Routes";
import "./App.css";
import Checkout from "./views/Checkout/Checkout";
import Login from "./views/Login/Login";
import MaintenanceMode from "./views/MaintenanceMode/MaintenanceMode";
import Page404 from "./views/Page404/Page404";
import RecoverPassword from "./views/RecoverPassword/RecoverPassword";
import Register from "./views/Register/Register";
import CartView from "./views/Shop/CartView/CartView";
import { v4 as uuidv4 } from 'uuid';
import VerifyEmailView from "./views/VerifyEmail/VerifyEmailView";
import VerifyEmailLink from "../components/Register/VerifyEmailLink/VerifyEmailLink";

var uniqid = require("uniqid");

//ReactGA.initialize("UA-465340-11");
//ReactGA.pageview(window.location.pathname + window.location.search);

function getAllUrlParams(url) {
  // get query string from url (optional) or window
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0];

    // split our query string into its component parts
    var arr = queryString.split("&");

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split("=");

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof a[1] === "undefined" ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === "string") paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, "");
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === "string") {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect to={Routes.account.login} />
      )
    }
  />
);

const PrivateCheckout = ({
  component: Component,
  loggedIn,
  mustBeLoggedIn,
  carrinho,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      mustBeLoggedIn ? (
        loggedIn ? (
          typeof carrinho === 'undefined' || carrinho == null || carrinho <= 0 ? (
            <Redirect to={Routes.shop.home} />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to={{ pathname: Routes.account.login, state: { previousInCheckout: true } }} />
        ))
        :
        <Component {...props} />
    }
  />
);

const CustomLoginRoute = ({
  component: Component,
  loggedIn,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      loggedIn ? (
        <Redirect to={Routes.shop.cart} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = this.props;
    this.state = {
      loggedIn: false,
      timestamp: "Loading"
    };


    // Para fazer set ao refer
    if (getAllUrlParams(window.location.search).refer) {
      cookies.set("refer", getAllUrlParams(window.location.search).refer, {
        path: "/",
        maxAge: 2147483647,
        domain: process.env.REACT_APP_HTTP_DOMAIN,
      });
    }
    else if (getAllUrlParams(window.location.search).Refer) {
      cookies.set("refer", getAllUrlParams(window.location.search).Refer, {
        path: "/",
        maxAge: 2147483647,
        domain: process.env.REACT_APP_HTTP_DOMAIN,
      });
    }

    // Caso exista userID envia para o servidor, senão cria e envia na mesma
    if (!cookies.get("userID")) {
      var userID = uuidv4()
      cookies.set("userID", userID, {
        domain: process.env.REACT_APP_HTTP_DOMAIN,
        path: "/",
        maxAge: 259200 //userID demora 3 dias a expirar
      });
      sendUserID(userID).then(data => { });
    } else {
      sendUserID(cookies.get("userID")).then(data => { });
    }

    //Verificamos se é um utilizador registado e que não tem a cookie de default currency
    if ((typeof this.props.userID === 'undefined' || this.props.userID == null) && !cookies.get("validateDefaultCurrency")) {
      cookies.set("validateDefaultCurrency", uniqid() + uniqid(), {
        path: "/",
        maxAge: 604800 //Definimos uma semana
      });

      var getClientLanguage = navigator.language;

      //Linguagem do browser do cliente
      switch (getClientLanguage) {

        case "pt-BR": //Se a linguagem do browser for brasileiro
          this.props.dispatch(changeCurrencyFE("BRL"));
          break;

        case "en-US": //Se a linguagem do browser for país US
          this.props.dispatch(changeCurrencyFE("USD"));
          break;

        case "en-GB": //Se a linguagem do browser for país Inglaterra
          this.props.dispatch(changeCurrencyFE("GBP"));
          break;

        case "fr-CH": //Se a linguagem do browser for zona relacionada com Suiça
        case "de-CH":
        case "it-CH":
          this.props.dispatch(changeCurrencyFE("CHF"));
          break;

        default:
          break;
      }
    }

  }

  componentDidMount() {
    loadReCaptcha();
  }

  render() {
    const App = () => (
      <div>
        <LoadingFullPage>
          <DetectLeave />
          <ListeningToEvent />
          <TawkTo locale={this.props.locale} />

          <ToastContainer
            bodyClassName="myToast"
            position="bottom-right"
            autoClose={5000}
            pauseOnFocusLoss={false}
            pauseOnHover={true}
            hideProgressBar={true}
          />

          <Switch>

            <Route exact path={Routes.home.home} render={props => {
              var url = `${process.env.REACT_APP_MAIN_WEBSITE_SHOP_URL}`;
              window.location.replace(url);
            }} />

            {/* <CustomLoginRoute exact path={Routes.account.login} loggedIn={this.props.userID} component={Login} /> */}

            {/* Redirect das categorias para o Novo site */}
            <Route
              path="/Loja/Catalogo/:name"
              render={props => (
                <RedirectComponent {...props} />
              )}
            />

            {/* Redirect para a nova loja quando se tenta obter o catalogo*/}
            <Route
              path="/Loja/Catalogo/"
              render={props => (
                <RedirectComponent {...props} />
              )}
            />

            {/* Registar */}
            <Route exact path={Routes.account.registo} render={(props) => <Register {...props} />} />

            <Route exact path="/myihtp/create">
              <Redirect to={Routes.account.registo} />
            </Route>

            {/* Verificar email através de código */}
            <Route exact path={Routes.account.verify} component={VerifyEmailView} />

            {/* Verificar email através de link */}
            <Route exact path={Routes.account.confirmEmail} component={VerifyEmailLink} />

            {/* Recuperar Password */}
            <Route exact path={Routes.account.recuperar} component={RecoverPassword} />

            {/* Login */}
            <Route exact path={Routes.account.login} render={(props) => <Login isUserLoggedIn={this.props.userID} {...props} />} />

            <Route
              exact
              path={Routes.shop.home}
              render={() => <Redirect to="/Loja/Catalogo/TS" />}
            />
            <Route
              exact
              path={Routes.shop.packs}
              render={() => <Redirect to={Routes.shop.home} />}
            />
            <Route
              exact
              path={Routes.shop.subscricoes}
              render={() => <Redirect to={Routes.shop.home} />}
            />
            <Route
              exact
              path={Routes.shop.promocoes}
              render={() => <Redirect to={Routes.shop.home} />}
            />
            <Route
              exact
              path={Routes.shop.novidades}
              render={() => <Redirect to={Routes.shop.home} />}
            />
            <Route
              exact
              path={Routes.shop.pnlLisboa}
              render={() => <Redirect to={Routes.shop.home} />}
            />
            <Route
              exact
              path={Routes.shop.comentario}
              render={() => <Redirect to={Routes.shop.home} />}
            />
            <Route
              exact
              path={Routes.shop.recomendar}
              render={() => <Redirect to={Routes.shop.home} />}
            />

            {/* Rota antigas das categorias foi comentada */}
            {/* <Route
              exact
              path={Routes.shop.catalogo}
              render={props => (
                <ProductGrid category={props.match.params.category} {...props} />
              )}
            /> */}
            <Route
              exact
              path={Routes.shop.cart}
              render={props => <CartView {...props} />}
            />
            <Route
              path={Routes.shop.detalheproduto}
              render={props => {
                var url = ""
                if (this.props.locale === "en") {
                  url = `${process.env.REACT_APP_MAIN_WEBSITE_PRODUCTDETAIL_URL_EN}${props.match.params.id}`;
                } else {
                  url = `${process.env.REACT_APP_MAIN_WEBSITE_PRODUCTDETAIL_URL_PT}${props.match.params.id}`;
                }
                window.location.replace(url);

                // < DetailsView {...props.match.params} />
              }}
            />
            <Route
              path="/Curso/:id/:name"
              render={props => (
                <Redirect
                  to={
                    "/Loja/DetalheProduto/" +
                    props.match.params.id +
                    "/" +
                    props.match.params.name
                  }
                />
              )}
            />
            <Route
              path="/Produto/:id/:name"
              render={props => (
                <Redirect
                  to={
                    "/Loja/DetalheProduto/" +
                    props.match.params.id +
                    "/" +
                    props.match.params.name
                  }
                />
              )}
            />
            <Route
              path="/Pack/:id/:name"
              render={props => (
                <Redirect
                  to={
                    "/Loja/DetalheProduto/" +
                    props.match.params.id +
                    "/" +
                    props.match.params.name
                  }
                />
              )}
            />

            <PrivateCheckout
              loggedIn={this.props.userID}
              mustBeLoggedIn={false}
              carrinho={true}
              previousInCheckout={true}
              exact
              path="/shop/checkout"
              component={Checkout}
            />
            <Route
              exact
              path="/shop/payment/:hash"
              component={PaymentMB}
              render={props => <PaymentMB {...props.match.params} />}
            />
            <PrivateRoute
              loggedIn={this.props.userID}
              exact
              path="/shop/paymentpaypal"
              component={PaymentPaypal}
            />
            <PrivateRoute
              loggedIn={this.props.userID}
              exact
              path="/shop/paymentebanx"
              component={PaymentEbanx}
            />
            <PrivateRoute
              loggedIn={this.props.userID}
              exact
              path="/shop/paymentmbway"
              component={PaymentMBWay}
            />

            {/* FIM DAS LANDING PAGES*/}
            <Route component={Page404} />
          </Switch>
        </LoadingFullPage>
      </div>
    );

    if (this.state.timestamp === "Loading") {
      return (
        <Switch>
          <App />
        </Switch>
      );
    } else if (this.state.timestamp === "Online") {
      return (
        <Switch>
          <App />
        </Switch>
      );
    } else if (this.state.timestamp === "Offline") {
      return <MaintenanceMode />;
    }
  }
}

const mapStateToProps = state => {
  return {
  };
};

export default withCookies(connect(mapStateToProps)(App));
