import Skeleton from '@material-ui/lab/Skeleton';
import { ImCart } from 'react-icons/im';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { RemoveDoubleZeroDecimalPlaces } from '../../../../Utils/UsefulFunctions';
import Label, { Type } from '../../../Common/Label';

type RelatedProductItemProps = {
    Loading: true,
    Key: number
}
    | (
        {
            Loading: false,
            ItemTitle: string,
            ImageURL: string,
            ItemId: number,
            Price: number,
            PriceToOtherUsers: number,
            PriceWithoutDiscount: number,
            Currency: string,
            OnButtonClick: (itemId: number) => void,
            Key: number
        })


export default function RelatedProductItem(props: RelatedProductItemProps) {
    const intl = useIntl();
    let hrefLink = "";

    if (props.Loading == false) {
        hrefLink = `${intl.formatMessage({ id: "mainWebsite.productRedirectURL" })}${props.ItemId}`;
    }

    return (
        <div className="relatedProductItemContainerOrder">
            <div className="relatedProductItemSubContainerOrder">
                <Row style={{ padding: "0px", margin: "0px" }} key={props.Key}>
                    <Col xs={12} sm={12} md={12} xl={12} style={{ padding: "0px", margin: "0px" }}>
                        <a href={hrefLink} className="itemImgContainerOrder">
                            {props.Loading === true ? (
                                <Skeleton variant="rect" width={"100%"} height={"100%"} />
                            ) : (
                                <img className="itemImgOrder" alt="Imagem do Artigo" src={`https://doremon.ihavethepower.net/assets/uploads/${props.ImageURL}`} />
                            )}
                        </a>
                    </Col>

                    <Col xs={12} sm={12} md={12} xl={12} style={{ padding: "0px", margin: "0px" }} className="itemSecondColOrder">
                        <div className="itemTitleOrder">
                            {props.Loading === true ? (
                                <Skeleton variant="rect" width={"90%"} height={30} />
                            ) : (
                                <a href={hrefLink} title={props.ItemTitle}>
                                    {props.ItemTitle}
                                </a>
                            )}

                        </div>

                        <div className="itemPriceOrder">
                            {props.Loading === true ? (
                                <Skeleton variant="rect" width={100} height={30} />
                            ) : (
                                <div>
                                    {props.PriceToOtherUsers &&
                                        <div className="priceToOtherUsersOrder">
                                            (
                                            <Label
                                                text={RemoveDoubleZeroDecimalPlaces(props.PriceToOtherUsers)}
                                                labelType={{
                                                    type: Type.currency,
                                                    currency: props.Currency
                                                }} />
                                            )
                                        </div>
                                    }

                                    <Label
                                        text={RemoveDoubleZeroDecimalPlaces(props.Price)}
                                        labelType={{
                                            type: Type.currency,
                                            currency: props.Currency
                                        }} />
                                </div>
                            )}
                        </div>

                        <div className="itemReadMoreOrder">
                            {props.Loading === true ? (
                                <Skeleton variant="rect" width={"40%"} height={20} />
                            ) : (
                                <a href={hrefLink}>
                                    <FormattedMessage id="shop.cart.produtosRelacionados.lerMais" />
                                </a>
                            )}
                        </div>

                    </Col>

                    <div className="itemButtonContainerOrder">
                        {props.Loading === true ? (
                            <Skeleton variant="rect" width={200} height={30} style={{ marginTop: "12px" }} />

                        ) : (
                            <div className="itemButtonOrder" onClick={() => props.OnButtonClick(props.ItemId)}>
                                <div className="itemButtonIconOrder">
                                    <ImCart />
                                </div>
                                <FormattedMessage id="shop.cart.produtosRelacionados.adicionar" />
                            </div>
                        )}
                    </div>
                </Row>
            </div>
        </div >
    )
}