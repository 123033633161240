import { FormControl } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FlatButton, MenuItem, SelectField } from "material-ui";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css';
import { useDispatch, useSelector } from "react-redux";
import { changePayment, getPaymentTypes } from "../../actions/miniCartActions";
import { addPhoneNumber, getPhoneNumbers } from "../../actions/userActions";
import Toast from "../../helpers/Toast";
import Button2 from "../Forms/Button";

const styleLabel = {
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Roboto",
  color: "rgba(0, 0, 0, 0.3)"
}

interface DialogChangePaymentMethodProps {
  selectedValue: number,
  open: boolean,
  onClose: (selectedValue: number) => void,
  orderId: number,
  hash: string,
  paymentType: string,
  updatePaymentFunction: () => void
}

interface Parcelas {
  value: number
}

interface PhoneNumbers {
  Id: number,
  Descricao: string
}

export default function DialogChangePaymentMethod(props: DialogChangePaymentMethodProps) {
  const [paymentType, setPaymentType] = useState<string>("");
  const [pagamentoEmParcelas, setPagamentoEmParcelas] = useState<boolean>(false);
  const [parcelas, setParcelas] = useState<Parcelas[]>([]);
  const [parcelaEscolhida, setParcelaEscolhida] = useState<number>(1);
  const [pagamentoPorTelemovel, setPagamentoPorTelemovel] = useState<boolean>(false);
  const [numeroTelemovelSelecionado, setNumeroTelemovelSelecionado] = useState<string | null>(null);
  const [telemoveis, setTelemoveis] = useState<PhoneNumbers[]>([]);
  const [criarTelemovel, setCriarTelemovel] = useState<boolean>(false);
  const [novoTelemovelNumero, setNovoTelemovelNumero] = useState<string>("");
  const [novoTelemovelIndicativo, setNovoTelemovelIndicativo] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const intl = useIntl();
  const dispatch = useDispatch();

  const paymentTypes = useSelector((state: any) => state.paymentTypes);

  const handleClose = () => {
    //Limpar a informação 
    cleanInformation();
    props.onClose(props.selectedValue);
  };

  const handleChangeInstallments = (evento, index, value) => {
    setParcelaEscolhida(value);
  };

  const loadPhoneNumbers = () => {
    getPhoneNumbers()
      .then(result => {
        let telemoveis: [] = [];
        if (result.success === true) {
          telemoveis = result.obj
        }

        setTelemoveis(telemoveis);
      })
      .catch(err => {
        setTelemoveis([]);
      })
  }

  const handleChangePaymentType = (event, index, value) => {
    //Procuramos o pagamento atual selecionado
    var currentPayment = paymentTypes.find(p => p.ID_TipoPagamento === value);

    //Verificamos se é um pagamento em parcelas
    var pagamentoEmParcelas = (currentPayment.EmParcelas === true);

    //Verificamos se é um pagamento por telemovel
    var numeroTelemovel = null;
    var pagamentoPorTelemovel = (currentPayment.PagamentoPorTelemovel === true);

    //Adicionamos o número de parcelas
    var parcelas: Parcelas[] = [];
    for (let i = 1; i <= currentPayment.NMaxParcelas; i++) {
      parcelas.push({ value: i });
    }

    //Se tivermos pagamento por telemovel definido, vamos buscar os numeros
    if (pagamentoPorTelemovel) {
      loadPhoneNumbers();
    }

    setPaymentType(value);
    setPagamentoEmParcelas(pagamentoEmParcelas);
    setParcelas(parcelas);
    setParcelaEscolhida(1);
    setPagamentoPorTelemovel(pagamentoPorTelemovel);
    setNumeroTelemovelSelecionado(null)

  };

  const alterarTipoPagamento = async (e) => {
    e.stopPropagation();

    //Validação extra para o tipo de pagamento estar selecionado
    if (paymentType == null) {
      Toast.Show("error", intl.formatMessage({ id: "pagamento.alterarTipoPagamento.erro.naoSelecionouTipo" }))
      return;
    }

    //Validação extra para obrigar a ter Nº de Parcelas
    if (parcelaEscolhida <= 0 && pagamentoEmParcelas === true) {
      Toast.Show("error", intl.formatMessage({ id: "pagamento.alterarTipoPagamento.erro.naoSelecionouParcelas" }))
      return;
    }

    //Validação extra se o utilizador, não selecionou um número de telemóvel
    if (pagamentoPorTelemovel === true && numeroTelemovelSelecionado == null) {
      Toast.Show("error", intl.formatMessage({ id: "pagamento.alterarTipoPagamento.erro.naoSelecionouTelemovel" }))
      return;
    }

    setLoading(true);

    changePayment(paymentType, props.orderId, props.hash, parcelaEscolhida, novoTelemovelIndicativo + " " + novoTelemovelNumero)
      .then(data => {
        setLoading(false);
        if (data.success === false) {
          Toast.Show("error", data.message);
          return;
        }

        setPagamentoEmParcelas(false);
        setPagamentoPorTelemovel(false);
        setCriarTelemovel(false);

        props.updatePaymentFunction();
        handleClose();

        Toast.Show("success", data.message);
      })
      .catch(error => {
        Toast.Show("error", intl.formatMessage({ id: "pagamento.alterarTipoPagamento.erro.naoSelecionouTelemovel" }));

        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(getPaymentTypes());
  }, [])

  //Limpar a informação dos estados
  const cleanInformation = () => {
    setPaymentType("");
    setPagamentoEmParcelas(false);
    setParcelas([]);
    setParcelaEscolhida(1);
    setPagamentoPorTelemovel(false);
    setNumeroTelemovelSelecionado(null);
    setTelemoveis([]);
    setCriarTelemovel(false);
    setNovoTelemovelNumero("")
    setNovoTelemovelIndicativo("")
  }

  //Método para abrir o formulário de adicionar telemóvel
  const clickToAddPhoneNumberForm = () => {
    setCriarTelemovel(true);
    setNumeroTelemovelSelecionado(null)
  }

  //Método para esconder o formulário de adicionar telemóvel
  const clickToHidePhoneNumberForm = () => {
    setCriarTelemovel(false);
  }

  //Método para adicionar novo número de telemóvel
  const addNewPhoneNumber = () => {
    if (novoTelemovelIndicativo === "351" && novoTelemovelNumero.length !== 9) {
      Toast.Show("error", intl.formatMessage({ id: "pagamento.erro.telemovelInvalido" }))
      return;
    }

    if (novoTelemovelIndicativo == null || novoTelemovelNumero == null) {
      Toast.Show("error", intl.formatMessage({ id: "pagamento.erro.telemovelInvalido" }))
      return;
    }

    var phoneNumber = novoTelemovelIndicativo + " " + novoTelemovelNumero;
    addPhoneNumber(phoneNumber)
      .then(result => {
        if (result.success === false) {
          Toast.Show("error", result.message)
        }

        loadPhoneNumbers();
        setCriarTelemovel(false);
        setNumeroTelemovelSelecionado(result.obj);
        Toast.Show("success", intl.formatMessage({ id: "pagamento.sucesso.adicionarTelemovel" }))
      })
      .catch(err => {
        Toast.Show("error", intl.formatMessage({ id: "pagamento.erro.erroAoAdicionarTelemovel" }))
      });
  }

  //Método para permitir escolher tlm perante os valores existentes
  const handleChangePhoneNumber = (value, index, value2) => {

    try {
      var numero: string | null = null;
      var tmpNumero = telemoveis.find(t => t.Id == value2);
      if (tmpNumero != null) {
        numero = tmpNumero.Descricao;
      }

      //Se numero estiver a null, significa que não encontramos o id do numero selecionado, 
      //nos numeros que vieram do servidor, por isso os dados não terão sido carregados corretamente
      //algo que nunca deve ocorrer, mas só para precaver
      if (numero == null) {
        Toast.Show("error", "Erro ao carregar dados do Servidor");
        return;
      }

      var numeroComIndicativo = numero.trimStart().trimEnd().split(" ");

      //Verificamos se é um número válido
      if (numeroComIndicativo.length !== 2) {
        const message = intl.formatMessage({ id: "pagamento.alterarTipoPagamento.numeroTelemovelInvalido.p1" }) + intl.formatMessage({ id: "pagamento.alterarTipoPagamento.numeroTelemovelInvalido.p2" });
        Toast.Show("error", message);

        setNumeroTelemovelSelecionado(null);
        setNovoTelemovelNumero("");
        setNovoTelemovelIndicativo("");
        return;
      }

      setNumeroTelemovelSelecionado(value2);
      setNovoTelemovelNumero(numeroComIndicativo[1]);
      setNovoTelemovelIndicativo(numeroComIndicativo[0]);

    } catch (expection) {
      Toast.Show("error", "Erro ao escolher Número de Telemóvel")
    }
  }

  //Guardar o valor do novo telemovel
  const handleChangeNewPhoneNumber = (value, data, event, formattedValue) => {
    var dialCode = data.dialCode;
    var rawPhone = value.slice(data.dialCode.length)
    setNovoTelemovelNumero(rawPhone);
    setNovoTelemovelIndicativo(dialCode)

  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        style={{ minHeight: "100%" }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <Container>
          <Row>
            <Col xs="10" sm="10" md="11" lg="11" style={{ textAlign: "left" }}>
              <DialogTitle id="simple-dialog-title">
                <FormattedMessage id="pagamento.alterarTipoPagamento" />
              </DialogTitle>
            </Col>
            <Col xs="2" sm="2" md="1" lg="1"
              onClick={handleClose}
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "right",
                right: "10px"
              }}
            >
              <svg
                width="33px"
                height="33px"
                viewBox="0 0 33 33"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Menu"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="MD"
                    transform="translate(-1311.000000, -21.000000)"
                    fill="#000000"
                  >
                    <g
                      id="close-(1)"
                      transform="translate(1311.000000, 21.000000)"
                    >
                      <path
                        d="M32.6096072,0.390441109 C32.0890191,-0.130147036 31.245005,-0.130147036 30.7244169,0.390441109 L0.390441109,30.7243524 C-0.130147036,31.2449405 -0.130147036,32.0889546 0.390441109,32.6095428 C0.650702954,32.8699335 0.991917965,33 1.33306852,33 C1.67421908,33 2.01536964,32.8698691 2.27569594,32.6094783 L32.6096072,2.27556703 C33.1301309,1.75504334 33.1301309,0.911029253 32.6096072,0.390441109 Z"
                        id="Path"
                      />
                      <path
                        d="M32.6095985,30.7243524 L2.27557092,0.390441109 C1.75504634,-0.130147036 0.910966357,-0.130147036 0.390441776,0.390441109 C-0.130147259,0.9109648 -0.130147259,1.75497888 0.390441776,2.27556703 L30.7244694,32.6095428 C30.9847317,32.8698691 31.3259472,33 31.6670984,33 C32.0082495,33 32.3494651,32.8698691 32.609663,32.6096072 C33.1301231,32.0889546 33.1301231,31.2449405 32.6095985,30.7243524 Z"
                        id="Path"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </Col>
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <FormControl
                    fullWidth={true}
                  >
                    <SelectField
                      floatingLabelText={intl.formatMessage({
                        id: "pagamento.acao"
                      })}
                      value={paymentType}
                      onChange={handleChangePaymentType}
                      fullWidth={true}
                      style={{ textAlign: "center" }}
                      className="SelectField"
                    >
                      {paymentTypes.map((paymentType, j) => {
                        return paymentType.ID_TipoPagamento !== "NA" && paymentType.ID_TipoPagamento !== props.paymentType ? (
                          <MenuItem
                            value={paymentType.ID_TipoPagamento}
                            primaryText={paymentType.Descricao}
                          />
                        ) : null;
                      })}
                    </SelectField>
                    {pagamentoEmParcelas &&
                      <>
                        <SelectField
                          floatingLabelText={
                            intl.formatMessage({
                              id: "pagamento.parcelas"
                            })
                          } fullWidth={true} style={{ textAlign: "center" }}
                          className="SelectField" onChange={handleChangeInstallments} value={parcelaEscolhida}
                        >
                          {parcelas.length > 0 ?
                            (parcelas.map(item => {
                              return <MenuItem
                                value={item.value}
                                primaryText={item.value + "" + ((item.value < 2) ? " Mês" : " Meses")}
                              />
                            }))
                            : (null)
                          }
                        </SelectField>
                      </>
                    }


                    {/* Adicionar telemóvel MBWAY*/}
                    <div hidden={!pagamentoPorTelemovel} style={{ margin: "10px", padding: "10px", backgroundColor: "white", border: "1px solid grey", textAlign: "center" }}>
                      <span style={styleLabel}>
                        {intl.formatMessage({
                          id: "pagamento.telemovel"
                        })}
                      </span>

                      {/* Botão para adicionar um novo numero de telemovel*/}
                      <Row>
                        <Col>
                          <FlatButton
                            label={
                              <svg
                                height="25px"
                                viewBox="0 0 512 512"
                                width="25px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
                                <path d="m256 365.714844c-10.097656 0-18.285156-8.1875-18.285156-18.285156v-182.859376c0-10.097656 8.1875-18.285156 18.285156-18.285156s18.285156 8.1875 18.285156 18.285156v182.859376c0 10.097656-8.1875 18.285156-18.285156 18.285156zm0 0" />
                                <path d="m347.429688 274.285156h-182.859376c-10.097656 0-18.285156-8.1875-18.285156-18.285156s8.1875-18.285156 18.285156-18.285156h182.859376c10.097656 0 18.285156 8.1875 18.285156 18.285156s-8.1875 18.285156-18.285156 18.285156zm0 0" />
                              </svg>
                            }
                            secondary={true}
                            onClick={clickToAddPhoneNumberForm}
                          />
                        </Col>
                      </Row>

                      <br />
                      {criarTelemovel !== true ? (
                        <Row>
                          <Col>
                            {telemoveis.length > 0 ? (
                              <SelectField
                                floatingLabelText={intl.formatMessage({
                                  id: "pagamento.selecionarTelemoveis"
                                })}
                                value={numeroTelemovelSelecionado}
                                onChange={handleChangePhoneNumber}
                                fullWidth={true}
                                style={{ fontWeight: "bold", textAlign: "center", paddingRight: "0px" }}
                                className="SelectField"
                                labelStyle={{ paddingRight: "0px" }}
                              >
                                <MenuItem
                                  value={null} primaryText={"Selecione um Telemóvel"}
                                />
                                {telemoveis.map((tlm, j) => {
                                  return (
                                    <MenuItem
                                      value={tlm.Id}
                                      primaryText={tlm.Descricao}
                                    />
                                  );
                                })}
                              </SelectField>
                            ) : null}
                          </Col>
                        </Row>
                      ) : (
                        <div>
                          <br />
                          <label><FormattedMessage id="pagamento.adicionarNumeroTelemovel" /></label>
                          <Row>
                            <Col>
                              <PhoneInput
                                searchPlaceholder={intl.formatMessage({
                                  id: "pagamento.procurarTelemovel"
                                })}
                                placeholder={intl.formatMessage({
                                  id: "pagamento.introduzirNumeroTelemovelPlaceholder"
                                })}
                                country="pt"
                                copyNumbersOnly={false}
                                enableSearch={true} onChange={handleChangeNewPhoneNumber}
                              />
                            </Col>
                          </Row>
                          <Button2
                            action={addNewPhoneNumber}
                            type="primary"
                            title={intl.formatMessage({
                              id: "pagamento.guardarNumeroTelemovelButton"
                            })}
                            style={{ "margin-top": "10px" }}
                          />
                          <Button2
                            action={clickToHidePhoneNumberForm}
                            title={"Cancelar"}
                            style={{ "margin-top": "10px", marginLeft: "5px" }}
                          />
                        </div>
                      )}


                    </div>

                  </FormControl>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                  <Button disabled={loading} variant="contained" color="secondary" onClick={alterarTipoPagamento}>
                    <FormattedMessage id="pagamento.alterarTipoPagamento.alterarButton" />
                    {loading === true && (
                      <CircularProgress size={18} className="circularProgress" />
                    )}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Row>
          <br />
        </Container>
      </Dialog>
    </div>
  );
}