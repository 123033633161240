export interface IHTPRequiredIndicationProps {
    required?: boolean
}

export default function IHTPRequiredIndication(props: IHTPRequiredIndicationProps) {
    return (
        <div style={{ gap: "1rem", fontSize: "13px" }}>
            {props.required === true ? <span style={{ color: "red", fontWeight: "bold" }}>*</span> : null}
        </div>
    )
}