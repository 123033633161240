import $ from "jquery";
import postalCodes from 'postal-codes-js';
import { addBillingAddressCheckout, addNewBillingAddress, addNewShippingAddress, addShippingAddressCheckout, getBillingAddresses, getShippingAddresses, setBillingAddress, setErrorCodeCheckout, setShippingAddress } from "../actions/miniCartActions";
import { setUserCheckoutDefaultValues } from "../actions/userActions";
import { view_cart } from "../Analytics/DataLayer";
import { MessagingHelper } from "../models/MessagingHelper";

export function HasError(errorCode: string, codesToCompare: string[]) {
    if (errorCode != null && errorCode != '' && typeof errorCode != 'undefined') {
        const upperCaseCodesToCompare = codesToCompare.map(a => a.toUpperCase());

        if (upperCaseCodesToCompare.includes(errorCode.toUpperCase())) {
            return true;
        }
    }

    return false;
}

export function ValidateBillingAddress(billingAddress: any, intl: any, dispatch: any) {
    var result = {
        success: false,
        message: ""
    }

    if (billingAddress == "" || typeof billingAddress == 'undefined' || billingAddress == null) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.nomeMoradaVazio" })

        dispatch(setErrorCodeCheckout("Faturacao"));
        return result;
    }

    let regex = new RegExp(/^\d{4}([-]\d{3})$/g);
    if (!billingAddress.nome) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.nomeMoradaVazio" })

        dispatch(setErrorCodeCheckout("Faturacao"));
        return result;
    }

    if (!billingAddress.morada) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.ruaVazia" })

        dispatch(setErrorCodeCheckout("Faturacao"));
        return result;
    }

    if (!billingAddress.localidade) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.localidadeVazia" })

        dispatch(setErrorCodeCheckout("Faturacao"));
        return result;
    }

    if (!billingAddress.codigoPostal) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.codPostalVazio" })

        dispatch(setErrorCodeCheckout("Faturacao"));
        return result;
    }

    if (!billingAddress.id_pais) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.paisVazio" })

        dispatch(setErrorCodeCheckout("Faturacao"));
        return result;
    }

    if (billingAddress.id_pais === "PRT" && !validateNIF(billingAddress.nif)) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.nifInvalidoPT" })

        dispatch(setErrorCodeCheckout("Faturacao"));
        return result;
    }

    if (billingAddress.id_pais === 'PRT' && regex.test(billingAddress.codigoPostal) === false) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.codPostalInvalidoPT" })

        dispatch(setErrorCodeCheckout("Faturacao"));
        return result;
    }

    if (postalCodes.validate(billingAddress.id_pais, billingAddress.codigoPostal) === false) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.codPostalInvalido" })

        dispatch(setErrorCodeCheckout("Faturacao"));
        return result;
    }

    result.success = true;
    result.message = "";
    return result;
}

function validateNIF(value) {
    const nif = typeof value === "string" ? value : value.toString();
    const validationSets = {
        one: ["1", "2", "3", "5", "6", "8"],
        two: [
            "45",
            "70",
            "71",
            "72",
            "74",
            "75",
            "77",
            "79",
            "90",
            "91",
            "98",
            "99"
        ]
    };

    if (nif.length !== 9) {
        return false;
    }

    if (
        !validationSets.one.includes(nif.substr(0, 1)) &&
        !validationSets.two.includes(nif.substr(0, 2))
    ) {
        return false;
    }

    let total =
        nif[0] * 9 +
        nif[1] * 8 +
        nif[2] * 7 +
        nif[3] * 6 +
        nif[4] * 5 +
        nif[5] * 4 +
        nif[6] * 3 +
        nif[7] * 2;
    let modulo11 = Number(total) % 11;

    const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;

    return checkDigit === Number(nif[8]);
}

export function AddBillingAddress(billingAddress: any, dispatch: any, intl: any) {
    var result = new MessagingHelper();

    return new Promise<MessagingHelper>(async (resolve) => {
        try {
            dispatch(addNewBillingAddress(billingAddress)).then(data => {
                var item = $(".billingAddAddress");
                var list = $(".billingAddresses");
                item.removeClass("is-open");

                item.one("transitionend", function (e) {
                    item.removeClass("is-visible");
                    list.removeClass("is-not-visible");
                });

                $(".breadCrumbRow").removeClass("disabledBox");
                $(".dadosEnvioCol").removeClass("disabledBox");
                $(".formasEnvioCol").removeClass("disabledBox");
                $(".tiposPagamentoCol").removeClass("disabledBox");
                $(".codigosPromocionaisCol").removeClass("disabledBox");
                $(".valesCol").removeClass("disabledBox");
                $(".creditosCol").removeClass("disabledBox");
                $(".observacaoCol").removeClass("disabledBox");
                $(".totalCol").removeClass("disabledBox");

                result.success = data.success;
                result.message = data.message;
                resolve(result);
            });
        }
        catch (exp) {
            result.success = false;
            result.message = intl.formatMessage({ id: "billingAddress.erro.erroInesperado" });
            resolve(result);
        }
    })
}

export function ShowOrHideBillingAddressButtons(value: number) {
    var buttonEdit = $(".changeButtonBillingAddress");
    var buttonRemove = $(".removeButtonBillingAddress");
    var buttonAdd = $(".addButtonBillingAddress");

    switch (value) {
        case 1: //show
            buttonEdit.removeClass("is-not-visible");
            buttonRemove.removeClass("is-not-visible");
            buttonAdd.removeClass("is-not-visible");

            buttonEdit.addClass("is-visible");
            buttonRemove.addClass("is-visible");
            buttonAdd.addClass("is-visible");
            break;
        default:
            buttonEdit.removeClass("is-visible");
            buttonRemove.removeClass("is-visible");
            buttonAdd.removeClass("is-visible");

            buttonEdit.addClass("is-not-visible");
            buttonRemove.addClass("is-not-visible");
            buttonAdd.addClass("is-not-visible");
            break;
    }
}

export async function ValidateAndAddBillingAddress(billingAddress: any, intl: any, dispatch: any, billingAddresses: any) {
    var result = {
        success: false,
        message: "",
        addresses: []
    }

    var resultValidation = ValidateBillingAddress(billingAddress, intl, dispatch);
    if (resultValidation.success === false) {
        result.success = false;
        result.message = resultValidation.message;

        return result;
    }

    var resultAdd: any = await AddBillingAddress(billingAddress, dispatch, intl);
    result.success = resultAdd.success;
    result.message = resultAdd.message;

    if (resultAdd.success === true || billingAddresses.length > 0) {
        ShowOrHideBillingAddressButtons(1);
    }
    if (billingAddresses.length = 0) {
        ShowOrHideBillingAddressButtons(0);
    }

    var addresses = await dispatch(getBillingAddresses());

    dispatch(setBillingAddress(billingAddress));

    var numberOfAddresses = addresses.length;
    if (numberOfAddresses >= 0 && addresses[numberOfAddresses - 1]) {
        dispatch(addBillingAddressCheckout(addresses[numberOfAddresses - 1].ID_Cliente_Morada));
    }

    result.addresses = addresses;
    return result;
}

export function ValidateShippingAddress(shippingAddress: any, intl: any, dispatch: any) {
    var result = {
        success: false,
        message: ""
    }

    if (shippingAddress == "" || typeof shippingAddress == 'undefined' || shippingAddress == null) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.nomeMoradaVazio" })

        dispatch(setErrorCodeCheckout("Envio"));
        return result;
    }


    let regex = new RegExp(/^\d{4}([-]\d{3})$/g);
    if (!shippingAddress.nome) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.nomeMoradaVazio" })

        dispatch(setErrorCodeCheckout("Envio"));
        return result;
    }

    if (!shippingAddress.morada) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.ruaVazia" })

        dispatch(setErrorCodeCheckout("Envio"));
        return result;
    }

    if (!shippingAddress.localidade) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.localidadeVazia" })

        dispatch(setErrorCodeCheckout("Envio"));
        return result;
    }

    if (!shippingAddress.codigoPostal) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.codPostalVazio" })

        dispatch(setErrorCodeCheckout("Envio"));
        return result;
    }

    if (!shippingAddress.id_pais) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.paisVazio" })

        dispatch(setErrorCodeCheckout("Envio"));
        return result;
    }

    if (shippingAddress.id_pais === 'PRT' && regex.test(shippingAddress.codigoPostal) === false) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.codPostalInvalidoPT" })

        dispatch(setErrorCodeCheckout("Envio"));
        return result;
    }

    if (postalCodes.validate(shippingAddress.id_pais, shippingAddress.codigoPostal) === false) {
        result.success = false;
        result.message = intl.formatMessage({ id: "billingAddress.erro.codPostalInvalido" })

        dispatch(setErrorCodeCheckout("Envio"));
        return result;
    }

    result.success = true;
    result.message = "";
    return result;
}

export function AddShippingAddress(shippingAddress: any, dispatch: any, intl: any) {
    var result = new MessagingHelper();

    return new Promise<MessagingHelper>(async (resolve) => {
        try {
            dispatch(addNewShippingAddress(shippingAddress)).then(data => {
                result.success = data.success;
                result.message = data.message;

                resolve(result);
            });
        }
        catch (exp) {
            result.success = false;
            result.message = intl.formatMessage({ id: "billingAddress.erro.erroInesperado" });
            resolve(result);
        }
    })
}

export function ShowOrHideShippingAddressButtons(value: number) {
    var buttonEdit = $(".changeButtonShippingAddress");
    var buttonRemove = $(".removeButtonShippingAddress");
    var buttonAdd = $(".addButtonShippingAddress");

    switch (value) {
        case 1: //show

            buttonEdit.removeClass("is-not-visible");
            buttonRemove.removeClass("is-not-visible");
            buttonAdd.removeClass("is-not-visible");

            buttonEdit.addClass("is-visible");
            buttonRemove.addClass("is-visible");
            buttonAdd.addClass("is-visible");
            break;
        default:
            buttonEdit.removeClass("is-visible");
            buttonRemove.removeClass("is-visible");
            buttonAdd.removeClass("is-visible");

            buttonEdit.addClass("is-not-visible");
            buttonRemove.addClass("is-not-visible");
            buttonAdd.addClass("is-not-visible");
            break;
    }
}

export async function ValidateAndAddShippingAddress(shippingAddress: any, intl: any, dispatch: any, shippingAddresses: any) {
    var result = {
        success: false,
        message: ""
    }

    var resultValidation = ValidateShippingAddress(shippingAddress, intl, dispatch);
    if (resultValidation.success === false) {
        result.success = false;
        result.message = resultValidation.message;

        return result;
    }

    var resultAdd: any = await AddShippingAddress(shippingAddress, dispatch, intl);
    result.message = resultAdd.message;

    if (resultAdd.success === false) {
        result.success = false;
        return result;
    }

    result.success = true;

    var data = await dispatch(getShippingAddresses());

    dispatch(setShippingAddress(shippingAddress));

    var numberOfAddresses = data.length;
    if (numberOfAddresses >= 0 && data[numberOfAddresses - 1]) {
        dispatch(addShippingAddressCheckout(data[numberOfAddresses - 1].ID_Cliente_Morada));
    }

    setTimeout(() => {
        ShowOrHideShippingAddresses(1);
        ShowOrHideAddShippingAddress(0);

        if (resultAdd.success === true || shippingAddresses.length > 0) {
            ShowOrHideShippingAddressButtons(1);
        }
        if (shippingAddresses.length = 0) {
            ShowOrHideShippingAddressButtons(0);
        }
    }, 500)

    return result;
}

export function ShowOrHideShippingAddresses(value) {
    var list = document.querySelector(".shippingAddresses");
    if (list != null) {
        switch (value) {
            case 1: //Show

                if (list.classList.contains("is-not-visible")) {
                    list.classList.remove("is-not-visible");
                    list.classList.add("is-visible");
                }
                break;
            default: //Hide
                if (!list.classList.contains("is-not-visible")) {
                    list.classList.add("is-not-visible");
                }
                break;
        }
    }
}

export function ShowOrHideAddShippingAddress(value) {
    var item = document.querySelector(".shippingAddAddress");
    if (item != null) {
        switch (value) {
            case 1: //Show

                if (!item.classList.contains("is-visible")) {
                    item.classList.add("is-visible");
                }

                if (!item.classList.contains("is-open")) {
                    setTimeout(function () {
                        item != null && item.classList.add("is-open");
                    }, 50);
                }

                break;
            default: //Hide

                if (item.classList.contains("is-open")) {
                    item.classList.remove("is-open");
                }

                if (item.classList.contains("is-visible")) {
                    setTimeout(function () {
                        item != null && item.classList.remove("is-visible");
                    }, 250);
                }
                break;
        }
    }
}


export function ShowOrHideBillingAddresses(value) {
    var list = document.querySelector(".billingAddresses");
    if (list != null) {
        switch (value) {
            case 1: //Show

                if (list.classList.contains("is-not-visible")) {
                    list.classList.remove("is-not-visible");
                    list.classList.add("is-visible");
                }
                break;
            default: //Hide
                if (!list.classList.contains("is-not-visible")) {
                    list.classList.add("is-not-visible");
                }
                break;
        }
    }
}

export function ShowOrHideAddBillingAddress(value) {
    var item = document.querySelector(".billingAddAddress");
    if (item != null) {
        switch (value) {
            case 1: //Show

                if (!item.classList.contains("is-visible")) {
                    item.classList.add("is-visible");
                }

                if (!item.classList.contains("is-open")) {
                    setTimeout(function () {
                        item != null && item.classList.add("is-open");
                    }, 50);
                }

                break;
            default: //Hide

                if (item.classList.contains("is-open")) {
                    item.classList.remove("is-open");
                }

                if (item.classList.contains("is-visible")) {
                    setTimeout(function () {
                        item != null && item.classList.remove("is-visible");
                    }, 250);
                }
                break;
        }
    }
}


export async function SetUserDefaultValues(dispatch: any, transportType: string, paymentType: string, telephone: string) {
    var newDefaultValues = {
        transportType: transportType,
        paymentType: paymentType,
        telephone: telephone
    };

    setTimeout(async () => {
        await dispatch(setUserCheckoutDefaultValues(newDefaultValues));
    }, 1000);
}

export function IfNullOrUndefinedReturnDefault(value: any, defaultValue: any) {
    if (value == null || typeof value == 'undefined') return defaultValue;

    return value;
}

export function CreateProductObjectToSendToGoogleAnalytics(products: any[], packs: any[]) {
    var items: any = [];

    if (typeof products !== 'undefined' && products !== null) {
        products.forEach((product): any => {
            items.push({
                id: "P" + product.ID_Produto,
                nome: product.Descricao,
                preco: product.subtotal ?? 0,
                tipoProduto: "P-" + product.ID_TipoProduto,
                quantidade: product.Quantidade
            });
        });
    }

    if (typeof packs !== 'undefined' && packs !== null) {
        packs.forEach((pack): any => {
            items.push({
                id: "K" + pack.ID_Pack,
                nome: pack.Descricao,
                preco: pack.subtotal ?? 0,
                tipoProduto: "K" + pack.ID_TipoPack,
                quantidade: pack.Quantidade
            });
        })
    }

    return items
}

export function SendCartToGA(cart: any) {
    if (typeof cart !== 'undefined' && cart !== null) {
        var items = CreateProductObjectToSendToGoogleAnalytics(cart.Carrinho, cart.CarrinhoPack);
        view_cart(items);
    }
}