import { FormattedMessage } from "react-intl";
import { register } from "../actions/miniCartActions";
import { registerWithFacebook, registerWithGoogle } from "../actions/userActions";
import { funnelStep } from "../Analytics/DataLayer";
import { VerifyEmailRouteState } from "../components/VerifyEmail/VerifyEmail";
import CrossDomainEventHandler from "../helpers/CrossDomainEventHandler";
import Routes from "../helpers/Routes";
import Toast from "../helpers/Toast";
import { MessagingHelper } from "../models/MessagingHelper";
import { RegisterFacebookDataDTO } from "../models/RegisterFacebookDataDTO";
import { RegisterGoogleDataDTO } from "../models/RegisterGoogleDataDTO";
import { FacebookUserRegisterDTO, GoogleUserRegisterDTO, UserRegisterDTO, UserRegisterDTOSchema } from "../models/UserRegisterDTO";
import { UtilsAuthenticationPropsDTO } from "../models/UtilsAuthenticationPropsDTO";
import { ConvertDateTimeToJustDate, isDate } from "./UsefulFunctions";


export function validateUserToRegister(props: UtilsAuthenticationPropsDTO, newUser: UserRegisterDTO, isPasswordValid: boolean, setShowPasswordErrors: any
    , isRegisterFromFacebook: boolean, isRegisterFromGoogle: boolean) {
    var response = new MessagingHelper();

    var result = UserRegisterDTOSchema(props.intl).validate(newUser, { allowUnknown: true });
    if (result.error != null) {
        var message = result.error.details[0].message;
        response.success = false;
        response.message = message;

        return response;
    }

    if (isRegisterFromFacebook == false && isRegisterFromGoogle == false && isPasswordValid == false) {
        setShowPasswordErrors(true);

        response.success = false;
        response.message = props.intl.formatMessage({ id: "registar.passwordInvalida" });

        return response;
    }

    //Se a data de nascimento estiver preenchida
    if (newUser.dataNascimento != null && newUser.dataNascimento.trim().length > 0) {

        //Se o valor da data de nascimento não for válido
        if (!isDate(newUser.dataNascimento)) {
            response.success = false;
            response.message = props.intl.formatMessage({ id: "registar.dataNascimento" });

            return response;
        }

        newUser.dataNascimento = ConvertDateTimeToJustDate(new Date(newUser.dataNascimento));
    }

    if (newUser.gdpr != 'S') {
        response.success = false;
        response.message = props.intl.formatMessage({ id: "registar.avisoRGPD" });

        return response;
    }

    response.success = true;
    response.obj = newUser;
    return response;
}

export function createObjectUserToRegister(newUser: UserRegisterDTO, isRegisterFromFacebook: boolean, facebookData: RegisterFacebookDataDTO | undefined
    , isRegisterFromGoogle: boolean, googleData: RegisterGoogleDataDTO | undefined) {

    if (isRegisterFromFacebook === true) {
        var facebookUser: FacebookUserRegisterDTO = {
            ...newUser,
            facebookAccessToken: facebookData!.accessToken,
            facebookId: facebookData!.id,
            isFacebookRegister: true,
            isGoogleRegister: false
        }
        return facebookUser;
    } else if (isRegisterFromGoogle === true) {
        var googleUser: GoogleUserRegisterDTO = {
            ...newUser,
            googleAccessToken: googleData!.accessToken,
            googleIdToken: googleData!.idToken,
            googleRefreshToken: googleData!.refreshToken,
            isGoogleRegister: true,
            isFacebookRegister: false
        }
        return googleUser;
    } else {
        return newUser;
    }
}

export async function handleRegisterUser(props: UtilsAuthenticationPropsDTO, newUser: UserRegisterDTO | FacebookUserRegisterDTO | GoogleUserRegisterDTO, setLoading: any) {
    if (newUser.isFacebookRegister == true) {
        return await facebookRegister(props, newUser as FacebookUserRegisterDTO, setLoading);
    }
    else if (newUser.isGoogleRegister == true) {
        return await googleRegister(props, newUser as GoogleUserRegisterDTO, setLoading);
    }
    else {
        return await normalRegister(props, newUser.email, newUser, setLoading);
    }
}


async function normalRegister(props: UtilsAuthenticationPropsDTO, email: string, user: UserRegisterDTO, setLoading: any) {
    var response = new MessagingHelper();

    try {
        setLoading(true);
        var data: any = await props.dispatch(register(user));
        setLoading(false);

        if (data.success === true) {
            funnelStep(1);

            var state: VerifyEmailRouteState = {
                email: email,
                previousInCheckout: getPreviousInCheckout(props.routeState),
                shouldLoginAfterConfirm: true
            }

            if (props.useHistory == true) {
                props.history.push(Routes.account.verify, state);
            }
        } else {
            Toast.Show("error", data.message);

            response.success = false;
            response.message = data.message;

            return response;
        }
    } catch (exp) {
        setLoading(false);
        Toast.Show("error", props.intl.formatMessage({ id: "registar.erroRegisto" }));

        response.success = false;
        response.message = props.intl.formatMessage({ id: "registar.erroRegisto" });

        return response;

    }

    response.success = true;
    return response;
}

async function googleRegister(props: UtilsAuthenticationPropsDTO, user: GoogleUserRegisterDTO, setLoading: any) {
    var response = new MessagingHelper();

    try {
        setLoading(true);
        var result: any = await props.dispatch(registerWithGoogle(user, user.googleAccessToken, user.googleRefreshToken, user.googleIdToken))
        setLoading(false);

        if (result.success === true) {
            Toast.Show("success", props.intl.formatMessage({ id: "registar.sucesso" }));

            CrossDomainEventHandler.sendEvent();
            setTimeout(function () {
                window.location.href = "/carrinho";
            }, 1200);

        } else {
            Toast.Show("error", result.message);

            response.success = false;
            response.message = result.message;

            return response;
        }
    } catch (exp) {
        Toast.Show("error", props.intl.formatMessage({ id: "registar.erroGoogle" }));

        response.success = false;
        response.message = props.intl.formatMessage({ id: "registar.erroGoogle" });

        return response;
    }

    response.success = true;
    return response;
}

async function facebookRegister(props: UtilsAuthenticationPropsDTO, user: FacebookUserRegisterDTO, setLoading: any) {
    var response = new MessagingHelper();

    try {
        setLoading(true);
        var result: any = await props.dispatch(registerWithFacebook(user, user.facebookId, user.facebookAccessToken))
        setLoading(false);

        if (result.success === true) {
            Toast.Show("success", props.intl.formatMessage({ id: "registar.sucesso" }));

            CrossDomainEventHandler.sendEvent();
            setTimeout(function () {
                window.location.href = "/carrinho";
            }, 1200);

        } else {
            Toast.Show("error", result.message);

            response.success = false;
            response.message = result.message;

            return response;
        }

    } catch (ex) {
        Toast.Show("error", props.intl.formatMessage({ id: "registar.erroFacebook" }));

        response.success = false;
        response.message = props.intl.formatMessage({ id: "registar.erroFacebook" });

        return response;
    }

    response.success = true;
    return response;
}

function getPreviousInCheckout(routeState: any) {
    if (typeof routeState === "undefined" || routeState == null || typeof routeState != "object") return false;
    if (typeof routeState.previousInCheckout === "undefined") return false;
    return routeState.previousInCheckout;
}