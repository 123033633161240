import React, { Component } from 'react'

export default class BlogIcon extends Component {
    render() {
        return (
            <svg
                height="28px"
                viewBox="0 0 512 512"
                width="28px"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g>
                    <g>
                        <g>
                            <path
                                fill="#034275"
                                id="WordPress"
                                d="M0,215.059c0,118.786,96.274,215.059,215.059,215.059c118.759,0,215.059-96.272,215.059-215.059
C430.117,96.303,333.817,0,215.059,0C96.274,0,0,96.303,0,215.059z M16.572,215.059c0-28.775,6.196-56.082,17.191-80.78
l94.679,259.398C62.242,361.541,16.572,293.616,16.572,215.059z M215.059,413.541c-19.48,0-38.3-2.838-56.077-8.06l59.568-173.032
l61.008,167.128c0.396,0.989,0.887,1.862,1.4,2.702C260.348,409.564,238.156,413.541,215.059,413.541z M375.466,211.337
c11.323-28.317,15.112-50.955,15.112-71.084c0-7.309-0.49-14.09-1.372-20.421c15.495,28.287,24.307,60.711,24.307,95.232
c0,73.235-39.671,137.151-98.662,171.538L375.466,211.337z M332.669,150.299c7.533,13.212,16.377,30.203,16.377,54.742
c0,17-5.055,38.366-15.107,64.175l-19.807,66.123l-71.733-213.328c11.976-0.651,22.71-1.895,22.71-1.895
c10.697-1.27,9.418-16.96-1.246-16.372c0,0-32.161,2.539-52.913,2.539c-19.508,0-52.264-2.539-52.264-2.539
c-10.702-0.588-11.943,15.751-1.272,16.372c0,0,10.146,1.244,20.846,1.895l30.894,84.727L165.73,336.977L93.435,122.011
c11.974-0.651,22.736-1.895,22.736-1.895c10.704-1.27,9.395-16.96-1.272-16.372c0,0-32.135,2.539-52.913,2.539
c-3.72,0-8.093-0.098-12.757-0.231c35.5-53.884,96.506-89.475,165.831-89.475c51.674,0,98.723,19.767,134.02,52.131
c-0.849-0.067-1.698-0.168-2.548-0.168c-19.541,0-33.342,16.972-33.342,35.209C313.193,120.122,322.621,133.959,332.669,150.299z"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}
