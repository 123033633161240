import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export default class CustomMenuItem extends Component {
    render() {

        const { icon, labelId, link, onClick, isClickable } = this.props;
        return (
            <Row>
                {/* Icon */}
                <Col xs="3" sm="3" md="3" lg="4">
                    <a href={link} onClick={isClickable ? onClick : null}>
                        {icon}
                    </a>
                </Col>
                {/* Text*/}
                <Col xs="9" sm="9" md="9" lg="8">
                    <a href={link} className="bp3-md-menu-frases-poderosas"
                        onClick={isClickable ? onClick : null}
                        style={{
                            cursor: "pointer"
                        }}>
                        <FormattedMessage id={labelId} />
                    </a>
                </Col>
            </Row>
        )
    }
}
