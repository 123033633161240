import React, { Component } from 'react'
import tawkTo from "tawkto-react";

const tawkToPropertyId = '55e6cced27904b7b2844be4f'

const tawkToLanguagesKeys = [
    {
        language: "en",
        key: "19up5d94u"
    },
    {
        language: "pt",
        key: "default"
    }
]

export default class TawkTo extends Component {

    componentDidMount() {
        var tawkToKey = 'default';
        var tmp = tawkToLanguagesKeys.find(t => t.language == this.props.locale);

        if (tmp != null) {
            tawkToKey = tmp.key;
        }

        tawkTo(tawkToPropertyId, tawkToKey)
        window.Tawk_API = window.Tawk_API || {};
        window.Tawk_API.customStyle = {
            zIndex: 50
        };
    }

    render() {
        return null;
    }
}
