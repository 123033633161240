import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Label, { Type } from "../Common/Label";
import "./Payment.css";

export interface PaymentChequeProps {
  total: number,
  currency: string
}

export default function PaymentCheque({ total, currency }: PaymentChequeProps) {
  return (
    <div>
      <Row className="payment">
        <Col xs={12} sm={12} md={12} lg={12}>
          <p>
            <b>
              <FormattedMessage id="pagamento.alterarTipoPagamento.cheque.valor" />{" "}
              <span className="colorPink">
                <Label
                  text={total.toFixed(2)}
                  labelType={{
                    type: Type.currency,
                    currency: currency
                  }} />
              </span>
            </b>
          </p>
          <p>
            <b><FormattedMessage id="pagamento.alterarTipoPagamento.cheque.nomeAPassar" /></b>
            <br />
            <FormattedMessage id="pagamento.alterarTipoPagamento.cheque.nomeAPassar.nome" />
          </p>
        </Col>
      </Row>
    </div>
  );
}