import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import Facebook from "./SocialMediaIcons/Facebook";
import Instagram from "./SocialMediaIcons/Instagram";
import Linkedin from "./SocialMediaIcons/Linkedin";
import Youtube from "./SocialMediaIcons/Youtube";
import "./SocialMedia.css";

class SocialMedia extends Component {
  render() {
    return (
      <div className="socialMediaContainer">
        <Facebook />
        <Instagram />
        <Youtube />
        <Linkedin />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default withCookies(connect(mapStateToProps)(SocialMedia));
