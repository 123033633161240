import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { ReCaptcha } from "react-recaptcha-google";
import { connect } from "react-redux";
import { isVerifiedRecaptcha } from "../../../actions/miniCartActions";
import HeaderDesktop from "../../../components/Desktop/Header/Header";
import RecoverPasswordDesktop from "../../../components/RecoverPassword/RecoverPassword";
import Footer from "../../../components/Footer/Footer";
import "./RecoverPassword.css";

let container;

class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  componentDidMount() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  verifyCallback(response) {
    this.props.dispatch(isVerifiedRecaptcha(true));
    if (response) {
      this.setState({
        isVerified: true
      });
    } else {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  render() {
    return (
      <div className="App">
        <HeaderDesktop />
        <Container className="mainContentContainer">
          <Row style={{ textAlign: "center" }} >
            <Col xs="12" sm="12" md="12" lg="12">
              <RecoverPasswordDesktop email="Endereço de Email" />
              <ReCaptcha
                ref={el => {
                  this.captchaDemo = el;
                }}
                size="invisible"
                render="explicit"
                sitekey="6LcjwKcUAAAAAB0AMiECn6QGrAvSi3VVhGjcGHNH"
                onloadCallback={this.onLoadRecaptcha}
                verifyCallback={this.verifyCallback}
              />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified
  };
};

export default connect(mapStateToProps)(RecoverPassword);
