import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from "react-intl";

class ShopButton extends Component {
    render() {
        return (
            <a href={this.props.intl.formatMessage({ id: "header.storeURL" })} className="headerLinks">
                <span>
                    <FormattedMessage id="header.store" />
                </span>
            </a>
        )
    }
}

export default injectIntl(ShopButton);