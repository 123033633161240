import React, { forwardRef, useState } from 'react';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import IHTPRequiredIndication from './IHTPRequiredIndication';

export type IHTPInputProps = IHTPInputBaseProps & ({
    hideLabel?: false | undefined;
    label: string;
} | {
    hideLabel: true;
})

export type IHTPInputBaseProps = {
    labelExtraOption?: JSX.Element;
    placeholder: string;
    onChange: (text: string) => void;
    onEnter?: () => void;
    onFocus?: (value: any) => void;
    onBlur?: (value: any) => void;
    type: "text" | "password" | "date" | "number";
    controlValue?: boolean;
    value?: string | number | undefined;
    disabled?: boolean;
    showLoadingSkeleton?: boolean;
    style?: React.CSSProperties
    min?: number;
    required?: boolean;
}

function IHTPInput(props: IHTPInputProps, ref) {

    const [visible, setVisible] = useState<boolean>(false);

    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            if (typeof props.onEnter !== 'undefined') {
                props.onEnter();
            }
        }
    }

    const onFocusOut = (e: any) => {
        if (typeof props.onBlur !== 'undefined') {
            props.onBlur(e);
        }
    }
    const onFocusIn = (e: any) => {
        if (typeof props.onFocus !== 'undefined') {
            props.onFocus(e);
        }
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: `${typeof props.labelExtraOption != 'undefined' ? "space-between" : ""}` }}>
                {props.hideLabel !== true && (
                    <>
                        <span style={{ fontWeight: "600", fontSize: "13px", fontFamily: "Montserrat", color: "rgba(71, 73, 79, 1)" }}>{props.label}</span>

                        <IHTPRequiredIndication required={props.required} />
                    </>
                )}

                {typeof props.labelExtraOption != 'undefined' && (
                    props.labelExtraOption
                )}

            </div>

            {typeof props.showLoadingSkeleton !== "undefined" &&
                props.showLoadingSkeleton === true ? (
                <>
                    <Skeleton style={{ border: "1px solid #ced4da" }} height={"2rem"} />
                </>
            ) : (
                <InputGroup onBlur={onFocusOut} onFocus={onFocusIn}>
                    <Input style={{ fontSize: "13px", ...props.style }} {...props.controlValue == true && { value: props.value }} disabled={props.disabled} innerRef={ref} placeholder={props.placeholder} type={visible === true ? "text" : props.type}
                        {...typeof props.min !== "undefined" && props.type == "number" && { min: props.min }}
                        onChange={(e) => props.onChange(e.target.value)}
                        onKeyPress={onKeyPress} />

                    {typeof props.type != 'undefined' && props.type == "password" && (
                        <button className='ihtpInputAction input-group-text'>
                            {visible ? <MdVisibility onClick={() => setVisible(false)} size={20} />
                                : <MdVisibilityOff onClick={() => setVisible(true)} size={20} />}
                        </button>
                    )}
                </InputGroup>
            )}
        </>
    )
}

export default forwardRef(IHTPInput);
