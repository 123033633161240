import _ from 'lodash';
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import HeaderDesktop from "../../../components/Desktop/Header/Header";
import DesktopRegister from "../../../components/Register/Register/Register";
import Footer from "../../../components/Footer/Footer";

// https://www.codementor.io/blizzerand/building-forms-using-react-everything-you-need-to-know-iz3eyoq4y

class Register extends Component {
  constructor(props) {
    super(props);
  }

  isFacebookDateAvailable() {

    if (_.isUndefined(this.props.location) === true || _.isNull(this.props.location) === true) {
      return false;
    }

    if (_.isUndefined(this.props.location.state) === true || _.isNull(this.props.location.state) === true) {
      return false;
    }

    if (_.isUndefined(this.props.location.state.facebookData) === true || _.isNull(this.props.location.state.facebookData) === true) {
      return false;
    }

    return true;
  }

  isGoogleDataAvailable() {

    if (_.isUndefined(this.props.location) === true || _.isNull(this.props.location) === true) {
      return false;
    }

    if (_.isUndefined(this.props.location.state) === true || _.isNull(this.props.location.state) === true) {
      return false;
    }

    if (_.isUndefined(this.props.location.state.googleData) === true || _.isNull(this.props.location.state.googleData) === true) {
      return false;
    }

    return true;
  }

  render() {

    var facebookData = null;
    var googleData = null;

    if (this.isFacebookDateAvailable() === true) {
      facebookData = this.props.location.state.facebookData;
    }

    if (this.isGoogleDataAvailable() === true) {
      googleData = this.props.location.state.googleData;
    }

    return (
      <div className="App">
        <HeaderDesktop />
        <Container className="mainContentContainer">
          <Row style={{ textAlign: "center" }} >
            <Col xs="12" sm="12" md="12" lg="12">
              <DesktopRegister facebookData={facebookData} googleData={googleData} />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(Register);
