import React, { Component } from 'react'
import { connect } from "react-redux";
import Loading from '../Desktop/Loading/Loading';

class LoadingFullPage extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                {this.props.loadingAllPage === true && (
                    <div style={{ flexDirection: "column", minHeight: "100vh", minWidth: "100vw", color: "blue", position: "absolute", left: 0, right: 0, top: 0, bottom: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src="/assets/images/md-logo-ihtpweb-05sombra-1.png" class="bp3-md-menu-logoihtpweb05sombra1" />
                        <span style={{ padding: "50px" }}></span>
                        <Loading />
                    </div>
                )}

                <div className="fullPageLoading" style={{ opacity: this.props.loadingAllPage ? 0 : 1 }}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        loadingAllPage: state.loadingAllPage
    };
};

export default connect(mapStateToProps)(LoadingFullPage);
