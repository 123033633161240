import React, { Component } from 'react'

export default class ProfileIcon extends Component {
    render() {
        return (
            <svg
                width="28px"
                height="28px"
                viewBox="0 0 25 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g
                    id="Symbols"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                >
                    <g
                        id="HeaderSM"
                        transform="translate(-689.000000, -18.000000)"
                        fill="#034275"
                        fill-rule="nonzero"
                    >
                        <g
                            id="profile"
                            transform="translate(689.000000, 18.000000)"
                        >
                            <path
                                d="M13,14 C16.8650557,14 20,10.8601998 20,6.98926095 C20,3.11832213 16.8650093,0 13,0 C9.13499068,0 6,3.13980023 6,6.98926095 C6,10.8387217 9.13494427,14 13,14 Z M13,1.8279627 C15.8343402,1.8279627 18.1533922,4.15052599 18.1533922,6.98926095 C18.1533922,9.82799591 15.8343402,12.1505127 13,12.1505127 C10.1656598,12.1505127 7.84660783,9.82794942 7.84660783,6.98921446 C7.84660783,4.1504795 10.1656134,1.8279627 13,1.8279627 Z"
                                id="Shape"
                            />
                            <path
                                d="M0.882585237,24 L24.1174148,24 C24.6100225,24 25,23.6216874 25,23.1438144 C25,18.6637221 21.2438546,15 16.6050796,15 L8.3949204,15 C3.77668921,15 0,18.6437928 0,23.1438144 C0,23.6216874 0.389977459,24 0.882585237,24 Z M8.3949204,16.7123713 L16.6050796,16.7123713 C19.9712475,16.7123713 22.7421774,19.1415952 23.1732425,22.2875857 L1.82675754,22.2875857 C2.25777825,19.1615245 5.02875246,16.7123713 8.3949204,16.7123713 Z"
                                id="Shape"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}
