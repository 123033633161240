import "moment/locale/pt";

export function getOrderDetails(id_encomenda, ID_Cliente) {
  return new Promise((resolve, reject) => {
    return fetch("/encomendas/getdetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Encomenda: id_encomenda,
        ID_Cliente: ID_Cliente
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
}