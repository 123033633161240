import Skeleton from '@material-ui/lab/Skeleton';
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { removePromotionalCode, selectPromotionalCode } from "../../actions/miniCartActions";
import Toast from '../../helpers/Toast';
import useCurrencies from '../../hooks/useCurrencies';
import { HasError } from "../../Utils/UtilsCheckout";
import IHTPButton from '../Common/IHTPButton';
import IHTPInput from '../Common/IHTPInput';
import IHTPSpacer from '../Common/IHTPSpacer';
import Label, { Type } from '../Common/Label';
import "./Checkout.css";
import useCheckoutDisabledInputs from './useCheckoutDisabledInputs';

export interface CodigosPromocionaisProps {
	user: any
}

export default function CodigosPromocionais() {
	const [promotionalCode, setPromotionalCode] = useState<string>("");
	const [hasError, setHasError] = useState<boolean>(false);
	const [loadingPromotionalCode, setLoadingPromotionalCode] = useState<boolean>(false);

	const checkoutData = useSelector((state: any) => state.checkoutData);
	const currencies = useCurrencies();
	const loadingCheckoutPrices = useSelector((state: any) => state.loadingCheckoutPrices);
	const errorCode = useSelector((state: any) => state.errorCode);

	const intl = useIntl();
	const dispatch = useDispatch();

	var currencySelected = currencies.selected;
	var loadingPrices = loadingCheckoutPrices;

	const { disabledFields } = useCheckoutDisabledInputs();

	useEffect(() => {
		let tmpHasError = HasError(errorCode, ["Vales_Codigos", "Creditos_Vales"]);
		setHasError(tmpHasError);
		return () => { }
	}, [errorCode])

	useEffect(() => {
		if (hasError === true) {
			let el = document.getElementById("codigoPromo");
			if (el != null) el.scrollIntoView({ block: "center" });
		}
	}, [hasError])

	const handleFormSubmit = async () => {

		if (promotionalCode == null || promotionalCode.length <= 0) {
			Toast.Show("error", intl.formatMessage({ id: "codigopromo.vazio" }));
			return;
		}

		setLoadingPromotionalCode(true);
		var data: any = await dispatch(selectPromotionalCode(promotionalCode));
		setLoadingPromotionalCode(false);

		if (data.error) {
			setPromotionalCode("");

			var resultRemove: any = await dispatch(removePromotionalCode());
			if (resultRemove.message !== undefined) {
				Toast.Show("error", data.message);
			}
			Toast.Show("error", data.error);
		} else {
			setPromotionalCode("");
			Toast.Show("success", intl.formatMessage({ id: data.success }));
		}
	}

	return (
		<div>
			<div
				className={hasError === true ? "checkoutBox errorBorder"
					: /* Validar se existe créditos metendo a disabled se o valor for nulo ou 0 */
					checkoutData.ValorDescontoCP === 0 ||
						checkoutData.ChaveCodigoPromocional
						? "checkoutBox disabledCodigoBox"
						: "checkoutBox "
				}
				id="codigoPromo"
			>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12} className="checkoutBoxTitle">
						<FormattedMessage id="codigopromo.titulo" />
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>

						<IHTPSpacer verticalSpace='10px' />

						<IHTPInput style={{ textAlign: "center" }} hideLabel={true} placeholder={intl.formatMessage({ id: "codigopromo.inserircodigo" })} type="text"
							onChange={setPromotionalCode} controlValue={true} value={promotionalCode} disabled={disabledFields} />

						<IHTPSpacer verticalSpace='10px' />

						<div className='discountsText'>
							<span>
								{checkoutData.ChaveCodigoPromocional ? (
									<b>{checkoutData.ChaveCodigoPromocional}</b>
								) : (
									intl.formatMessage({ id: "codigopromo.nenhumcodigo" })
								)}
							</span>

							<IHTPSpacer verticalSpace='10px' />

							<span className='promotionalCodeUsedContainer'>
								<span><FormattedMessage id="codigopromo.descontoUtilizado" /></span>
								<span style={{ fontWeight: "600", color: "rgb(202, 0, 106)" }}>
									{loadingPrices === true ? (
										<Skeleton variant="text" width={50} height={24} />
									) : (
										<Label
											text={(checkoutData.ValorDescontoCP ?? 0).toFixed(2)}
											labelType={{
												type: Type.currency,
												currency: currencies.selectedDescription
											}} />
									)}
								</span>
							</span>

						</div>
						<IHTPSpacer verticalSpace='10px' />
						<div className="buttonsValidarCodigos">
							<IHTPButton onClick={handleFormSubmit}
								text={intl.formatMessage({ id: "codigopromo.validar" })}
								buttonStyle="secondary"
								loading={loadingPromotionalCode}
								disabled={disabledFields}
							/>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
}