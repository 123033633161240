import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useDispatch} from "react-redux";
import { ToastContainer } from "react-toastr";
import "../../../components/Register/VerifyEmail.css";
import Routes from "../../../helpers/Routes";
import IHTPButton from "../../Common/IHTPButton";
import IHTPSpacer from "../../Common/IHTPSpacer";
import HeaderDesktop from "../../Desktop/Header/Header";
import { verifyEmailLink } from '../../../actions/miniCartActions';
import { FormattedMessage } from 'react-intl';

let container

export default function VerifyEmailLink() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        //Obter o parametro id
        const urlParams = new URLSearchParams(window.location.search);
        console.log(window.location.href);
        const idParam = urlParams.get("id");

        if(idParam !== null){

            //Enviar para api para confirmar conta
            verifyLink(idParam);
        }

        setLoading(false);
    },[] );

    const verifyLink = async (id: string) => {
        
        setLoading(true);
        setError("");

        const responseVerifyEmail: any = await dispatch(verifyEmailLink(id));
        console.log("teste:", responseVerifyEmail);
        setLoading(false);

        if (responseVerifyEmail.success !== true) {
            setError(responseVerifyEmail.message);
            return;
        }
    }

    return (
        <div className="App">
            <HeaderDesktop />
            <ToastContainer
                ref={ref => (container = ref)}
                className="toast-bottom-right"
            />

            <Container className="mainContentContainer">
                <Row style={{ textAlign: "center" }} >
                    <Col xs="12" sm="12" md="12" lg="12">
                    {!loading &&(
                    <>
                        <div className="changePasswordTitleParent">
                            <div className="changePasswordTitle">
                                <FormattedMessage id="confirmarEmail.tituloVerifyEmailLink" />
                            </div>
                        </div>
                        <br />
                        </>
                    )}
                        <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col xs={12} sm={12} md={8} lg={6} xl={4} style={{ fontSize: "13px", fontWeight: "500" }}>

                        <IHTPSpacer verticalSpace='35px' />
                        {loading === true ? (
                            <>
                                <CircularProgress size={24} className="buttonProgress" />
                            </>
                        ) :    error != null && error.length > 0 ? (
                            <>
                                <div className="emailErrorContainer">
                                    <span>{error}</span>
                                </div>
                                <IHTPSpacer verticalSpace="20px" />
                            </>
                        ):(
                            <>
                                <span style={{ color: "rgba(71, 73, 79, 1)" }}>
                                    <FormattedMessage id="confirmarEmail.successLink" />
                                </span>

                                <IHTPSpacer verticalSpace='25px' />
                                <NavLink className="fake-link" id="fake-link-1" style={{ textDecoration: "none", padding: "0px 15px 0px 10px" }} to={Routes.account.login}>
                                <IHTPButton text={"Entrar"} loading={loading} onClick={()=>null} />
                                </NavLink>
                               
                                {/* <IHTPButton text={intl.formatMessage({ id: "login.entrar" })} loading={loading} onClick={()=>{}} /> */}
                            </>
                        )}
                    </Col>
                </Row>                   
                 </Col>
                </Row>
            </Container>
        </div>
    );
}