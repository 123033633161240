import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";

class LoadingIcon extends Component {
  render() {
    return (
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
          <div class="lds-roller">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default withCookies(connect(mapStateToProps)(LoadingIcon));
