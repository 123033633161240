import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import GoogleLoginComponent from './GoogleLoginComponent';
import FacebookLoginComponent from './FacebookLoginComponent';
import { SocialLoginPropsDTO } from '../../models/SocialLoginPropsDTO';

export default function SocialLogin(props: SocialLoginPropsDTO) {

    const [googleErrorMessage, setGoogleErrorMessage] = useState<string>("");

    return (
        <>
            <Row style={{ display: "flex", alignItems: "center", alignContent: "center" }}>
                <Col xl={props.xl} lg={props.lg} md={props.md} sm={props.sm} xs={props.xs} className="colsFBButton">
                    <FacebookLoginComponent previousInCheckout={props.previousInCheckout}
                        showText={props.showText}
                        isRegisteringInCheckout={props.isRegisteringInCheckout}
                        callbackToRegisterInCheckout={props.callbackToRegisterInCheckout} />
                </Col>

                <Col xl={props.xl} lg={props.lg} md={props.md} sm={props.sm} xs={props.xs} className="colsGButton">
                    <GoogleLoginComponent
                        onErrorMessage={(message) => setGoogleErrorMessage(message)}
                        previousInCheckout={props.previousInCheckout}
                        showText={props.showText}
                        isRegisteringInCheckout={props.isRegisteringInCheckout}
                        callbackToRegisterInCheckout={props.callbackToRegisterInCheckout} />
                </Col>
            </Row>

            {
                googleErrorMessage != null && googleErrorMessage.length > 0 ?
                    <Row style={{ marginTop: "25px" }}>
                        <Col>
                            <label style={{ fontSize: "15px" }}>{googleErrorMessage}</label>
                        </Col>
                    </Row>
                    : null
            }
        </>
    )
}
