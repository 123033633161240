import { Fab } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Toast from '../../../helpers/Toast';
import IHTPButton from '../../Common/IHTPButton';
import IHTPInput from '../../Common/IHTPInput';
import IHTPSelect, { IHTPSelectItem } from '../../Common/IHTPSelect';
import IHTPSpacer from '../../Common/IHTPSpacer';
import postalCodes from 'postal-codes-js';
import { getAddress, modifyShippingAddress } from '../../../actions/miniCartActions';
import { ValidateShippingAddress } from '../../../Utils/UtilsCheckout';

const alterarMoradaInitialState = {
    id_cliente_morada: "",
    nome: "",
    morada: "",
    localidade: "",
    codigoPostal: "",
    principal: "S",
    id_pais: "",
    ID_Tipo_Morada: ""
}

export class EditShippingDTO {
    isOpen: boolean = false;
    shippingAddressId: number | null = null;
}

export interface EditShippingAddressProps {
    onClose: () => void,
    shippingDTO: EditShippingDTO
}

export default function EditShippingAddress(props: EditShippingAddressProps) {

    const [alterarMorada, setAlterarMorada] = useState(alterarMoradaInitialState);
    const [loadingUpdateAddress, setLoadingUpdateAddress] = useState<boolean>(false);
    const [loadingInput, setLoadingInput] = useState<boolean>(true);
    const intl = useIntl();

    const dispatch = useDispatch();
    const allPaises = useSelector((state: any) => state.allPaises);
    const paisesOptions: IHTPSelectItem[] = useMemo(() => {
        if (typeof allPaises === "undefined" || allPaises == null) return [];

        var paises: IHTPSelectItem[] = [];
        allPaises.forEach(i => {
            paises.push({
                value: i.alpha3,
                label: i.name
            });
        })
        return paises;
    }, [allPaises])

    const [defaultAddressOptions, setDefaultAddressOptions] = useState<IHTPSelectItem[]>([
        { value: "N", label: intl.formatMessage({ id: "facturacao.novaMorada.padrao.no" }) },
        { value: "S", label: intl.formatMessage({ id: "facturacao.novaMorada.padrao.yes" }) }
    ]);

    useEffect(() => {

        if (props.shippingDTO.isOpen === true) {
            loadEditAddress(props.shippingDTO.shippingAddressId);
        }

        return () => { }
    }, [props.shippingDTO.isOpen, props.shippingDTO.shippingAddressId])


    const loadEditAddress = async (addressId: any) => {
        setLoadingInput(true);
        var data: any = await dispatch(getAddress(addressId));
        setLoadingInput(false);

        setAlterarMorada({
            id_cliente_morada: data.data.address[0].ID_Cliente_Morada,
            nome: data.data.address[0].Nome,
            morada: data.data.address[0].Morada,
            localidade: data.data.address[0].Localidade,
            codigoPostal: data.data.address[0].CodigoPostal,
            principal: data.data.address[0].Principal,
            id_pais: data.data.address[0].Pais,
            ID_Tipo_Morada: data.data.address[0].ID_Tipo_Morada
        });
    }

    const handleFormModifyShippingAddressSubmit = async () => {

        var resultValidation = ValidateShippingAddress(alterarMorada, intl, dispatch);
        if (resultValidation.success === false) {
            Toast.Show("error", resultValidation.message);
            return;
        }

        setLoadingUpdateAddress(true);
        const data: any = await dispatch(modifyShippingAddress(alterarMorada))
        setLoadingUpdateAddress(false);

        if (data.success !== true) {
            Toast.Show("error", data.message);
            return;
        }

        Toast.Show("success", data.message);

        props.onClose();
    }

    const handleCleanModifyShippingAddress = () => {
        props.onClose();
    }

    return (
        <Row style={{ textAlign: "center" }}>
            <div className="shippingModifyAddress">
                <Col xs={12} sm={12} md={12} lg={12}>

                    {/*Linha com titulo e botão de cancelar alteração*/}
                    <Row className="shippingRowTitleAddress">
                        <span className="titleOptionsAddress">
                            <FormattedMessage id="billingAddress.modificarMoradaTitle" />
                        </span>

                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>
                            <IHTPInput label={intl.formatMessage({ id: "billingAddress.modificarMorada.nome.label" })}
                                placeholder={intl.formatMessage({ id: "billingAddress.modificarMorada.nome" })}
                                onChange={(text) => setAlterarMorada(prev => ({ ...prev, nome: text }))}
                                type="text" controlValue={true} value={alterarMorada.nome}
                                showLoadingSkeleton={loadingInput}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPInput label={intl.formatMessage({ id: "billingAddress.modificarMorada.morada.label" })}
                                placeholder={intl.formatMessage({ id: "billingAddress.modificarMorada.morada" })}
                                onChange={(text) => setAlterarMorada(prev => ({ ...prev, morada: text }))}
                                type="text" controlValue={true} value={alterarMorada.morada}
                                showLoadingSkeleton={loadingInput}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPInput label={intl.formatMessage({ id: "billingAddress.modificarMorada.localidade.label" })}
                                placeholder={intl.formatMessage({ id: "billingAddress.modificarMorada.localidade" })}
                                onChange={(text) => setAlterarMorada(prev => ({ ...prev, localidade: text }))}
                                type="text" controlValue={true} value={alterarMorada.localidade}
                                showLoadingSkeleton={loadingInput}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPInput label={intl.formatMessage({ id: "billingAddress.modificarMorada.codigoPostal.label" })}
                                placeholder={intl.formatMessage({ id: "billingAddress.modificarMorada.codigoPostal" })}
                                onChange={(text) => setAlterarMorada(prev => ({ ...prev, codigoPostal: text }))}
                                type="text" controlValue={true} value={alterarMorada.codigoPostal}
                                showLoadingSkeleton={loadingInput}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPSelect label={intl.formatMessage({ id: "billingAddress.modificarMorada.id_pais.label" })}
                                options={paisesOptions}
                                placeholderItem={intl.formatMessage({ id: "billingAddress.modificarMorada.id_pais" })}
                                onChange={(text) => setAlterarMorada(prev => ({ ...prev, id_pais: text }))}
                                controlValue={true} value={alterarMorada.id_pais}
                                showLoadingSkeleton={loadingInput}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPSelect label={intl.formatMessage({ id: "facturacao.modificarMorada.padrao.label" })}
                                options={defaultAddressOptions}
                                hasPlaceholder={false}
                                onChange={(text) => setAlterarMorada(prev => ({ ...prev, principal: text }))}
                                controlValue={true} value={alterarMorada.principal}
                                width="auto"
                                showLoadingSkeleton={loadingInput}
                            />

                            <IHTPSpacer verticalSpace={"20px"} />

                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                <Col xl={6} lg={6} md={8} style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "1rem" }}>
                                    <IHTPButton text={intl.formatMessage({ id: "billingAddress.modificarMorada.addMoradasButton" })}
                                        loading={loadingUpdateAddress}
                                        disabled={loadingInput}
                                        onClick={handleFormModifyShippingAddressSubmit} />

                                    <IHTPButton text={intl.formatMessage({ id: "common.button.cancelar" })} buttonStyle="secondary"
                                        onClick={handleCleanModifyShippingAddress} disabled={loadingUpdateAddress} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Row>
    )
}
