import { Button, Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { isMobile } from "react-device-detect";
import "./Payment.css";

export interface PaymentCartaoCreditoProps {
  urlPagamentoCT: string
}

export default function PaymentCartaoCredito({ urlPagamentoCT }: PaymentCartaoCreditoProps) {
  return (
    <Row className="payment" >
      <Col xs={12} sm={12} md={12} lg={12}>
        <p><FormattedMessage id="pagamento.alterarTipoPagamento.CartaoCredito.p1" /></p>
        <Button
          aria-label="Increment"
          style={{
            backgroundColor: "#ca006a",
            border: "none",
            minWidth: isMobile ? "50%" : "20%"
          }}
          href={urlPagamentoCT}>
          <FormattedMessage id="pagamento.alterarTipoPagamento.CartaoCredito.p2" />
        </Button>
      </Col>
    </Row>
  );
}
