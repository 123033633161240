import Skeleton from '@material-ui/lab/Skeleton';
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { checkout_Encomendar_Old, funnelStep } from "../../Analytics/DataLayer";
import { ParseClientIdFromGoogleAnalytics } from "../../Utils/UsefulFunctions";
import { createObjectUserToRegister, validateUserToRegister } from '../../Utils/UtilsAuthentication';
import { ValidateBillingAddress, ValidateShippingAddress } from '../../Utils/UtilsCheckout';
import { confirmOrder, createClientAndConfirmOrder, removeCredits, removePromotionalCode, removeVouchers, setErrorCodeCheckout, verifyOrder } from "../../actions/miniCartActions";
import { getOrderDetails } from "../../actions/myihtpActions";
import Toast from '../../helpers/Toast';
import useCurrencies from '../../hooks/useCurrencies';
import { MessagingHelper } from '../../models/MessagingHelper';
import { UserRegisterDTO } from '../../models/UserRegisterDTO';
import { UtilsAuthenticationPropsDTO } from '../../models/UtilsAuthenticationPropsDTO';
import IHTPRecaptcha from '../Common/IHTPRecaptcha';
import Label, { Type } from '../Common/Label';
import Loading from "../Desktop/Loading/Loading";
import useCheckoutDisabledInputs from './useCheckoutDisabledInputs';

export interface TotalProps {
	showMoreDetailsOnMount: boolean,
	user: any
}

export default function Total(props: TotalProps) {

	const [buttonCheckout, setButtonCheckout] = useState<boolean>(false);
	const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
	const [showMoreDetails, setShowMoreDetails] = useState<boolean>(props.showMoreDetailsOnMount);
	const [showPasswordErrors, setShowPasswordErrors] = useState<boolean>(false);
	const [recaptchaVerified, setRecaptchaVerified] = useState<boolean>(false);

	const checkoutData = useSelector((state: any) => state.checkoutData);
	const currencies = useCurrencies();
	const loadingCheckoutPrices = useSelector((state: any) => state.loadingCheckoutPrices);
	const billingAddress = useSelector((state: any) => state.billingAddress);
	const shippingAddress = useSelector((state: any) => state.shippingAddress);
	const userToRegister = useSelector((state: any) => state.userToRegister);
	const loginOrRegisterInCheckout = useSelector((state: any) => state.loginOrRegisterInCheckout);
	const cart = useSelector((state: any) => state.cart);
	const isGuestCheckout = useSelector((state: any) => state.isGuestCheckout);

	const intl = useIntl();
	const dispatch = useDispatch();
	const cookies = useCookies();
	const { disabledFields } = useCheckoutDisabledInputs();

	useEffect(() => {
		loadOrder();
		return () => { }
	}, [])

	const loadOrder = async () => {
		try {
			var data: any = await dispatch(verifyOrder());
			if (data.message !== undefined) {
				Toast.Show("error", data.message);
			}
		} catch (exp) { }
	}

	const removeCreditsClick = async (e) => {
		e.stopPropagation();
		try {
			var data: any = await dispatch(removeCredits())
			if (data.message !== undefined) {
				Toast.Show("error", data.message);
			}
		} catch (exp) { }
	}

	const removeVouchersClick = async (e) => {
		e.stopPropagation();
		try {
			var data: any = await dispatch(removeVouchers());
			if (data.message !== undefined) {
				Toast.Show("error", data.message);
			}
		} catch (exp) { }
	};

	const removePromotionalCodeClick = async e => {
		e.stopPropagation();
		try {
			var data: any = await dispatch(removePromotionalCode())
			if (data.message !== undefined) {
				Toast.Show("error", data.message);
			}

		} catch (exp) { }
	};

	const encomendar = async () => {
		try {
			if ((props.user.userID == null || typeof props.user.userID == 'undefined') && isGuestCheckout === false) {
				if (loginOrRegisterInCheckout?.toUpperCase() == "LOGIN") {
					dispatch(setErrorCodeCheckout("Login"));
					Toast.Show("error", intl.formatMessage({ id: "login.erro.naoFeito" }));
					return;
				}

				if (loginOrRegisterInCheckout?.toUpperCase() == "REGISTER" && Object.keys(userToRegister).length == 0) {
					dispatch(setErrorCodeCheckout("Register"));
					Toast.Show("error", intl.formatMessage({ id: "registar.erro.camposObrigatoriosPorPreencher" }));
					return;
				}

				if (loginOrRegisterInCheckout?.toUpperCase() == "") {
					dispatch(setErrorCodeCheckout("Login"));
					Toast.Show("error", intl.formatMessage({ id: "login.erro.email.vazio" }));
					return;
				}
			}

			if (loginOrRegisterInCheckout?.toUpperCase() == "REGISTER" || isGuestCheckout) {
				var responseValidateUser = await validateUserToCreate();
				if (responseValidateUser.success === false) {
					Toast.Show("error", responseValidateUser.message);
					return;
				}
			}

			//Vamos buscar a cookie que contem o client id do user
			var gaClientId = cookies["_ga"];

			//Fazemos parse do id de cliente do utilizador
			gaClientId = ParseClientIdFromGoogleAnalytics(gaClientId);

			setButtonCheckout(true);
			setPaymentLoading(true);

			//Quer dizer que está a inserir uma nova morada de faturação
			//Então vamos tentar adicioná-la primeiro.
			if (checkoutData.ProdutosTodosGratuitos !== true) {
				if (checkoutData.billingAddress == 0) {
					let resultValidateBillingAddress = ValidateBillingAddress(billingAddress, intl, dispatch);
					if (resultValidateBillingAddress.success === false) {
						Toast.Show("error", resultValidateBillingAddress.message);

						setButtonCheckout(false);
						setPaymentLoading(false);
						return;
					}
				}
			}

			//Quer dizer que está a inserir uma nova morada de envio
			//Então vamos tentar adicioná-la primeiro.
			if (checkoutData.TemProdutosFisicos === 1 && checkoutData.TipoTransporteExterno === 'S') {
				if (checkoutData.shippingAddress == 0) {
					let resultValidateShippingAddress = ValidateShippingAddress(shippingAddress, intl, dispatch);
					if (resultValidateShippingAddress.success === false) {
						Toast.Show("error", resultValidateShippingAddress.message);

						setButtonCheckout(false);
						setPaymentLoading(false);
						return;
					}
				}
			}


			var data: any = null;
			//É para criar o cliente
			//Então vamos fazer o pedido HTTP a outro endpoint
			//Este endpoint recebe os dados do cliente a ser criado, os dados das moradas e os dados da encomenda
			if (loginOrRegisterInCheckout?.toUpperCase() == "REGISTER" || isGuestCheckout) {
				data = await createUserAndOrder(gaClientId);
			}
			else {
				data = await dispatch(confirmOrder(gaClientId));
			}

			//Se não tiver sido feito com sucesso
			if (data.success === false) {

				//Ficamos com a mensagem
				var message = data.message;

				//Se a mensagem estiver undefined
				if (typeof message === 'undefined' || message == null) {

					//Vamos buscar uma com o valor default
					message = intl.formatMessage({ id: "total.erro.finalizar" });
				}

				setPaymentLoading(false);
				setButtonCheckout(false);
				Toast.Show("error", message);
				return;
			}

			var obj = data.obj;

			var userID = props.user.userID;
			if (Object.keys(userToRegister).length != 0) {
				userID = obj.ID_Cliente;
			}

			// Obter o detalhe de um produto
			const result: any = await getOrderDetails(obj.ID_Encomenda, userID);

			//Se conseguirmos obter os dados com sucesso da API
			if (result.success === true) {

				var detalhes = result.obj.Detalhes[0];
				sendDataToAnalytics(detalhes);

				const successMessage = data.message ? data.message : intl.formatMessage({ id: "total.sucesso" });
				Toast.Show("success", successMessage);

				setTimeout(function () {
					var x: Window = window;
					x.location = "/shop/payment/" + obj.HashEncomenda;
				}, 2000);
			}
		} catch (err) {
			Toast.Show("error", intl.formatMessage({ id: "total.erro.finalizar" }));
		};
	}

	const sendDataToAnalytics = (ordeDetails: any) => {
		// Dados de encomenda
		// var order = {
		// 	IDEncomenda: ordeDetails.ID_Encomenda,
		// 	Affiliation: "Loja Online",
		// 	precoFinal: ordeDetails.PrecoFinal,
		// 	precoTaxa: ordeDetails.IvaTotal,
		// 	precoEnvio: ordeDetails.CustoTransporte,
		// 	codigoPromocional: ordeDetails.CodigoPromocional
		// };

		// var items = CreateProductObjectToSendToGoogleAnalytics(cart.Carrinho, cart.CarrinhoPack);

		// Criar o datalayer e enviar datalayer
		//checkout_Encomendar(order, items);


		// Dados para o GA antigo
		var order_old = {
			IDEncomenda: ordeDetails.ID_Encomenda,
			Affiliation: "Loja Online",
			precoFinal: 0.01, //Fizemos este "hack" para a encomenda pode ser contabilizada no Analytics
			precoTaxa: 0,
			precoEnvio: 0
		};

		// Dados de produtos
		var products = [];

		// Criar o datalayer e enviar datalayer
		checkout_Encomendar_Old(order_old, products);

		// Efetuar uma encomenda
		funnelStep(7);
	}

	const createUserAndOrder = async (gaClientId: any) => {
		var newUser: UserRegisterDTO = {
			email: userToRegister.email,
			nomeCompleto: userToRegister.nomeCompleto,
			password: userToRegister.password,
			emailRecomendacao: userToRegister.emailRecomendacao,
			telefone: userToRegister.telefone,
			dataNascimento: userToRegister.dataNascimento,
			pais: userToRegister.pais,
			localidade: userToRegister.localidade,
			refer: userToRegister.refer,
			gdpr: userToRegister.gdpr,
			isFacebookRegister: false,
			isGoogleRegister: false
		}

		var newUserObject = createObjectUserToRegister(newUser, userToRegister.isRegisterFromFacebook, userToRegister.facebookData
			, userToRegister.isRegisterFromGoogle, userToRegister.googleData);

		var data: any = await dispatch(createClientAndConfirmOrder(gaClientId, newUserObject, billingAddress, shippingAddress, isGuestCheckout));

		return data;
	}

	const validateUserToCreate = async () => {
		var response = new MessagingHelper();

		var propsAuthentication: UtilsAuthenticationPropsDTO = {
			dispatch,
			history: null,
			useHistory: false,
			intl
		}

		var newUser: UserRegisterDTO = {
			email: userToRegister.email,
			nomeCompleto: userToRegister.nomeCompleto,
			password: userToRegister.password,
			emailRecomendacao: userToRegister.emailRecomendacao,
			telefone: userToRegister.telefone,
			dataNascimento: userToRegister.dataNascimento,
			pais: userToRegister.pais,
			localidade: userToRegister.localidade,
			refer: userToRegister.refer,
			gdpr: userToRegister.gdpr,
			isFacebookRegister: false,
			isGoogleRegister: false
		}

		var resultValidateUser = validateUserToRegister(propsAuthentication, newUser, userToRegister.isPasswordValid, setShowPasswordErrors
			, userToRegister.isRegisterFromFacebook, userToRegister.isRegisterFromGoogle);

		if (resultValidateUser.success == false) {
			await dispatch(setErrorCodeCheckout("Register"));
		}

		response.success = resultValidateUser.success;
		response.message = resultValidateUser.message;
		return response;
	}

	const CustomizedSketelon = () => {
		return (<Skeleton variant="text" width={75} height={24} />);
	}

	const handleShowDetails = (value) => {
		setShowMoreDetails(value);
	}

	var loadingPrices = loadingCheckoutPrices;

	return (
		<Row style={{ minHeight: "100%" }}>
			<Col xs={12} sm={12} md={12} lg={12}>
				<div className="checkout-cart-total">
					{paymentLoading ? (
						<Loading />
					) : (
						<div>

							{/* Titulo do Formulário */}
							<div style={{ textAlign: "center" }} className="checkoutBoxTitle">
								{intl.formatMessage({ id: "total.titulo" })}
							</div>

							{showMoreDetails === true ?
								<div id="checkout-show-less">
									<Button
										className="btn btn-primary"
										onClick={() => handleShowDetails(false)}
									>
										<AiOutlineMinusCircle size={15} /> Esconder Detalhes
									</Button>

									{/* 1º listagem */}
									<ul>

										{/* Sub Total */}
										<li>
											{intl.formatMessage({ id: "total.subtotal" })}{" "}
											<span>
												{loadingPrices === true ? (
													CustomizedSketelon()
												) : (
													<Label
														text={(checkoutData.Subtotal ?? 0).toFixed(2)}
														labelType={{
															type: Type.currency,
															currency: currencies.selectedDescription
														}} />
												)}
											</span>
										</li>

										{/* Portes */}
										<li>
											{intl.formatMessage({ id: "total.portes" })}{" "}
											<span>
												{loadingPrices === true ? (
													CustomizedSketelon()
												) : (
													<Label
														text={(checkoutData.Portes ?? 0).toFixed(2)}
														labelType={{
															type: Type.currency,
															currency: currencies.selectedDescription
														}} />
												)}
											</span>
										</li>

										{/* Iva */}
										<li>
											{intl.formatMessage({ id: "total.iva" })}{" "}
											<span>
												{loadingPrices === true ? (
													CustomizedSketelon()
												) : (
													<Label
														text={(checkoutData.Iva ?? 0).toFixed(2)}
														labelType={{
															type: Type.currency,
															currency: currencies.selectedDescription
														}} />
												)}
											</span>
										</li>

										{/* Código Promocional */}
										{checkoutData.ChaveCodigoPromocional ? (
											<li>
												<div className="discountAbleToRemove">
													- {intl.formatMessage({ id: "total.codigoPromocional" })}{" "}

													{loadingPrices === true ? (
														CustomizedSketelon()
													) : (
														<span className='removeDiscountButtonContainer'>
															<span className="removeDiscountButton" onClick={removePromotionalCodeClick}>
																{intl.formatMessage({ id: "total.remover" })}
															</span>
															<Label
																text={(checkoutData.ValorDescontoCP ?? 0).toFixed(2)}
																labelType={{
																	type: Type.currency,
																	currency: currencies.selectedDescription
																}} />
														</span>
													)}
												</div>
											</li>
										) : null}

										{/* Vouchers */}
										{checkoutData.vouchers ? (
											<li>
												<div className="discountAbleToRemove">
													- {intl.formatMessage({ id: "total.vales" })}{" "}

													{loadingPrices === true ? (
														CustomizedSketelon()
													) : (
														<span className="removeDiscountButtonContainer">
															<span className="removeDiscountButton" onClick={removeVouchersClick}>
																{intl.formatMessage({ id: "total.remover" })}
															</span>
															<Label
																text={(checkoutData.vouchers ?? 0).toFixed(2)}
																labelType={{
																	type: Type.currency,
																	currency: currencies.selectedDescription
																}} />
														</span>
													)}
												</div>
											</li>
										) : null}

										{/* Créditos */}
										{checkoutData.credits ? (
											<li>
												<div className="discountAbleToRemove">
													- {intl.formatMessage({ id: "total.creditos" })}{" "}

													{loadingPrices === true ? (
														CustomizedSketelon()
													) : (
														<span className='removeDiscountButtonContainer'>
															<span className="removeDiscountButton" onClick={removeCreditsClick}>
																{intl.formatMessage({ id: "total.remover" })}
															</span>
															<Label
																text={(checkoutData.credits ?? 0).toFixed(2)}
																labelType={{
																	type: Type.currency,
																	currency: currencies.selectedDescription
																}} />
														</span>
													)}
												</div>
											</li>
										) : null}

										{/* Descontos */}
										<li>
											<div className={checkoutData.Descontos > 0 ? "hasGlobalDiscount" : ""}>
												- {intl.formatMessage({ id: "total.desconto" })}{" "}

												{loadingPrices === true ? (
													CustomizedSketelon()
												) : (
													<span>
														<Label
															text={(checkoutData.Descontos ?? 0).toFixed(2)}
															labelType={{
																type: Type.currency,
																currency: currencies.selectedDescription
															}} />
													</span>
												)}

											</div>
										</li>
									</ul>

								</div>
								:
								<div id="checkout-show-more">
									<Button
										className="btn btn-primary"
										onClick={() => handleShowDetails(true)}
									>
										<AiOutlinePlusCircle size={15} /> Mostrar Detalhes
									</Button>
								</div>}


							<div className="inputTotal">

								{/* Label do valor a pagar */}
								<Row style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center", margin: "0px", padding: "0px" }}>
									<Col style={{ padding: "0px", textAlign: "left" }}>
										{intl.formatMessage({ id: "total.valorPagar" })}{" "}
									</Col>

									{/* Valor a Pagar */}
									<Col style={{ padding: "0px", textAlign: "right", display: "flex", justifyContent: "flex-end" }}>

										{loadingPrices === true ? (
											<Skeleton width={150} height={33} variant="text" />
										) : (
											<Label
												text={(checkoutData.Total ?? 0).toFixed(2)}
												labelType={{
													type: Type.currency,
													currency: currencies.selectedDescription
												}} />
										)}

									</Col>
								</Row>
							</div>

							{/*Divisória*/}
							<p className="center" style={{ textAlign: "right" }} />

							<br />
							<IHTPRecaptcha setRecaptchaVerified={setRecaptchaVerified} />
							<br />

							<div className="col-12 center">
								<Button className="btn btn-primary" id="encomendarButton" name="encomendarButton" onClick={encomendar}
									disabled={buttonCheckout || loadingPrices === true || disabledFields || recaptchaVerified === false ? true : false}>
									<FormattedMessage id="total.Finalizar" />
								</Button>
							</div>
						</div>
					)}
				</div>
			</Col>
		</Row >
	)
}