import React, { createRef, useEffect, useState } from 'react'
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-google";
import { getGoogleRecaptchaKey } from '../../actions/geralActions';

export interface IHTPRecaptchaProps {
    setRecaptchaVerified: (isVerified: boolean) => void
}

export default function IHTPRecaptcha(props: IHTPRecaptchaProps) {

    const [recaptchaKey, setRecaptchaKey] = useState<string | null>(null);
    const recaptchaElement = createRef<any>();

    const loadRecaptchaKey = async () => {
        var result = await getGoogleRecaptchaKey();
        if (result.success === true) {
            setRecaptchaKey(result.obj);
        }
    }

    const verifyCallback = (response) => {
        if (response) {
            props.setRecaptchaVerified(true);
        } else {
            resetRecaptcha();
        }
    }

    useEffect(() => {
        loadRecaptchaKey();
        return () => { }
    }, [])

    useEffect(() => {
        if (recaptchaKey != null) {
            loadReCaptcha();
        }
        return () => { }
    }, [recaptchaKey])

    const resetRecaptcha = () => {
        if (recaptchaElement != null && recaptchaElement.current != null) {
            recaptchaElement.current.reset();
            recaptchaElement.current.execute();
        }
    }

    const recaptchaExpired = () => {
        props.setRecaptchaVerified(false);
    }

    if (recaptchaKey == null) return null;
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <ReCaptcha
                ref={recaptchaElement}
                render="explicit"
                size="normal"
                sitekey={recaptchaKey}
                onloadCallback={resetRecaptcha}
                verifyCallback={verifyCallback}
                expiredCallback={recaptchaExpired}
            />
        </div>
    )
}
