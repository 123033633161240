import { isInteger } from "lodash";
import { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { removeFromCart, removeFromCart_Old } from "../../../Analytics/DataLayer";
import { SendCartToGA } from "../../../Utils/UtilsCheckout";
import { clearCart, fetchCart, getProductDetailsWP, incrementPackCart, incrementarProdutoCart } from "../../../actions/miniCartActions";
import Toast from "../../../helpers/Toast";
import useCurrencies from "../../../hooks/useCurrencies";
import Loading from "../../Desktop/Loading/Loading";
import "./Cart.css";
import CartIsEmpty from "./Components/CartIsEmpty";
import CartItem from "./Components/CartItem";
import CartOrderButtons from "./Components/CartOrderButtons";
import CartTotal from "./Components/CartTotal";
import ErrorLoadingCart from "./Components/ErrorLoadingCart";
import TrashIcon from "./Components/TrashIcon";

export default function Cart() {

  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const cart = useSelector((state: any) => state.cart);
  const currencies = useCurrencies();
  const loadingCart = useSelector((state: any) => state.loadingCart);
  const cartLoadError = useSelector((state: any) => state.cartLoadError);

  const isCartEmpty = !((cart.Carrinho || cart.CarrinhoPack) && (cart.Carrinho.length > 0 || cart.CarrinhoPack.length > 0));
  const currency = currencies.selectedDescription;
  const haveErrorOccurred = (cartLoadError != null && cartLoadError.length > 0);

  useEffect(() => {
    var willTryToAddItem = false;
    var allURLParams = new URLSearchParams(window.location.search);

    //Verificamos se estamos a tentar adicionar primeiro um produto
    if (allURLParams.has('addProduct') && allURLParams.get('addProduct') != null) {

      willTryToAddItem = true;

      var addProduct = validateParamIsProperInt(allURLParams.get('addProduct'));
      if (addProduct != null) {

        //Adicionamos o produto ao carrinho        
        dispatch(incrementarProdutoCart(addProduct, "ADD"));

        var data: any = dispatch(fetchCart())
        if (data.success === true) {
          SendCartToGA(data.obj);
        }
      }

      //Apagamos a query da route
      allURLParams.delete('addProduct');
      history.replace(
        location.pathname,
      );
    }

    //Verificamos se estamos a tentar adicionar primeiro um pack
    if (allURLParams.has('addPack') && allURLParams.get('addPack') != null) {

      willTryToAddItem = true;

      var addPack = validateParamIsProperInt(allURLParams.get('addPack'));
      if (addPack != null) {
        //Adicionamos o produto ao carrinho
        dispatch(incrementPackCart(addPack));
        var data: any = dispatch(fetchCart())
        if (data.success === true) {
          SendCartToGA(data.obj);
        }
      }

      //Apagamos a query da route
      allURLParams.delete('addPack');
      history.replace(
        location.pathname,
      );
    }

    //Se não estivermos a adicionar nenhum produto, apenas carregamos dados
    if (willTryToAddItem === false) {
      var data: any = dispatch(fetchCart())
      if (data.success === true) {
        SendCartToGA(data.obj);
      }
    }
  }, [])

  //Validamos se o parametro é um inteiro válido
  const validateParamIsProperInt = (value: string | null): number | null => {
    if (value == null) return null;
    if (value.length <= 0) return null;

    var tmp = Number(value);

    if (isNaN(tmp)) return null;

    if (isInteger(tmp)) return tmp;

    return null;
  }

  const limpar = (e: any) => {
    e.stopPropagation();
    dispatch(clearCart());
    window.location.href = "/";
  };

  const removeAll = () => {
    dispatch(clearCart());
    removeFromGoogleAnalytics(cart);
    var data: any = dispatch(fetchCart());
    if (data.success === true) {
      SendCartToGA(data.obj);
    }
  };

  const removeFromGoogleAnalytics = (cart) => {
    //Remover produtos
    if (typeof cart.Carrinho !== 'undefined' && cart.Carrinho !== null && cart.Carrinho.length > 0) {
      cart.Carrinho.forEach(item => {
        getProductInfo(item.ID_Produto, item.ID_TipoProduto).then((productFromDb: any) => {
          var product = {
            ID_Produto: item.ID_Produto,
            ID_Pack: null,
            Descricao: item.Descricao,
            ID_TipoProduto: item.ID_TipoProduto,
            ID_TipoPack: null,
            quantity: item.Quantidade,
            price: productFromDb?.price ?? 0
          }
          removeFromCart(product);
          removeFromCart_Old(product);
        });
      });
    }

    //Remover packs
    if (typeof cart.CarrinhoPack !== 'undefined' && cart.CarrinhoPack !== null && cart.CarrinhoPack.length > 0) {
      cart.CarrinhoPack.forEach(item => {
        getProductInfo(item.ID_Pack, item.ID_TipoPack).then((productFromDb: any) => {
          var product = {
            ID_Produto: null,
            ID_Pack: item.ID_Pack,
            Descricao: item.Descricao,
            ID_TipoProduto: null,
            ID_TipoPack: item.ID_TipoPack,
            quantity: item.Quantidade,
            price: productFromDb?.price ?? 0
          }
          removeFromCart(product);
          removeFromCart_Old(product);
        });
      });
    }
  }

  const getProductInfo = (id_item, tipo_item) => {
    return new Promise((resolve) => {
      getProductDetailsWP(id_item, tipo_item).then(result => {
        if (result.success === true) {
          resolve(result.obj);
          return;
        }
      });
    })
  };

  const addProductToCart = (id) => {
    dispatch(incrementarProdutoCart(id, "ADD"));
    dispatch(fetchCart());

    Toast.Show("success", intl.formatMessage({ id: "shop.cart.produtosRelacionados.mensagemAdicionar" }));
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignContent: "flex-start" }}>
      <div className="cartContainer">
        <div className="cartGridContainer">

          {/* Titulo da Página */}
          <div className="cartTitleParent">
            <div className="cartTitle">
              <FormattedMessage id="shop.cart.carrinho.title" />
              <hr />
            </div>
          </div>


          {/* Verificar se o Carrinho, está vazio e mostrar mensagem correspondente*/}
          {haveErrorOccurred == false && loadingCart == false && isCartEmpty === true ? (
            <div className="cartItemsContainer">
              <CartIsEmpty />
            </div>
          ) : (

            <div className="cartItemsContainer">

              {loadingCart === true && haveErrorOccurred === false ? (
                <Loading />
              ) : (
                <div>
                  {/* Apresentar Produtos no Carrinho */}
                  {haveErrorOccurred === false && cart.Carrinho && cart.Carrinho.length > 0 && cart.Carrinho.map((item, j) => {
                    return <CartItem currency={currency} key={item.ID_Produto} item={item} canUpdateQuantity={true} canDelete={true} isOnCheckout={false} />
                  })
                  }

                  {/* Apresentar Packs no Carrinho */}
                  {haveErrorOccurred === false && cart.CarrinhoPack && cart.CarrinhoPack.length > 0 && cart.CarrinhoPack.map((item, j) => {
                    return <CartItem currency={currency} key={item.ID_Pack} item={item} canUpdateQuantity={true} canDelete={true} isOnCheckout={false} />
                  })
                  }
                </div>
              )}

            </div >
          )}


          {/* <div className="cartRelatedItems">
            <RelatedProductsToCart onAddProductClick={this.AddProductToCart} />
          </div> */}

          <div className="cartPossibleActionsBeforeCheckout">
            {haveErrorOccurred === false && isCartEmpty === false && (
              <div className="cartPossibleActionsBeforeCheckoutContainer">

                <div className="cartPossibleActionTrash">
                  <div style={{ float: "right", minWidth: "100%" }}>
                    <Button aria-label="Decrement" className="cartRemoveAllItems"
                      onClick={() => { removeAll(); }}>
                      <TrashIcon color="white" />
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>

          {haveErrorOccurred == true && (
            <div className="cartErrorMessage">
              <ErrorLoadingCart errorMessage={cartLoadError} />
            </div>
          )}
        </div>

        {/* Linha para mostrar o total e o que pagar em DESKTOP */}
        {haveErrorOccurred == false && isCartEmpty === false && (
          <div className="cartBottomCheckoutParent">
            <div className="cartBottomCheckoutContainer">
              <Row style={{ width: "100%", margin: "0px", backgroundColor: "white" }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                  <div>
                    <div className="center" style={{ backgroundColor: "white" }}>
                      {loadingCart === true && haveErrorOccurred === false ?
                        <Loading />
                        :
                        <CartTotal />
                      }
                    </div>

                    <CartOrderButtons />
                  </div>
                </Col >
              </Row >
            </div>
          </div>
        )}
      </div>
    </div >
  );
}