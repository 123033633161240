import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { addBillingAddressCheckout, addNewBillingAddress, getBillingAddresses, setBillingAddress, setErrorCodeCheckout } from '../../../actions/miniCartActions';
import Toast from '../../../helpers/Toast';
import { AddressDTO } from '../../../models/Address/AddressDTO';
import IHTPButton from '../../Common/IHTPButton';
import IHTPInput from '../../Common/IHTPInput';
import IHTPSelect, { IHTPSelectItem } from '../../Common/IHTPSelect';
import IHTPSpacer from '../../Common/IHTPSpacer';
import { ValidateBillingAddress } from '../../../Utils/UtilsCheckout';
import useCheckoutDisabledInputs from '../useCheckoutDisabledInputs';

export interface CreateBillingAddressProps {
    onClose: () => void
}

const novaMoradaInitialState: AddressDTO = {
    id_cliente_morada: "",
    nome: "",
    nif: "",
    morada: "",
    localidade: "",
    codigoPostal: "",
    principal: "N",
    id_pais: "",
    ID_Tipo_Morada: ""
}

export default function CreateBillingAddress(props: CreateBillingAddressProps) {
    const [loadingAddAddress, setLoadingAddAddress] = useState<boolean>(false);
    const intl = useIntl();
    const dispatch = useDispatch();

    const user = useSelector((state: any) => state.user);
    const [defaultAddressOptions, setDefaultAddressOptions] = useState<IHTPSelectItem[]>([
        { value: "N", label: intl.formatMessage({ id: "facturacao.novaMorada.padrao.no" }) },
        { value: "S", label: intl.formatMessage({ id: "facturacao.novaMorada.padrao.yes" }) }
    ]);

    //billingAddress é usado para guardar a nova morada de faturação globalmente enquanto esta não é criada
    const billingAddress: AddressDTO = useSelector((state: any) => state.billingAddress);
    const billingAddresses = useSelector((state: any) => state.billingAddresses);
    const allPaises = useSelector((state: any) => state.allPaises);
    const userToRegister = useSelector((state: any) => state.userToRegister);

    const { disabledFields } = useCheckoutDisabledInputs();

    useEffect(() => {
        //Colocamos os dados a limpo
        dispatch(setBillingAddress(novaMoradaInitialState));
    }, [])

    const paisesOptions: IHTPSelectItem[] = useMemo(() => {
        if (typeof allPaises === "undefined" || allPaises == null) return [];

        var paises: IHTPSelectItem[] = [];
        allPaises.forEach(i => {
            paises.push({
                value: i.alpha3,
                label: i.name
            });
        })
        return paises;
    }, [allPaises])

    const setNovaMorada = (address: AddressDTO) => {
        dispatch(setBillingAddress(address));
    }

    const handleAddAddressValidateNif = (value: string) => {
        if (billingAddress.id_pais !== "PRT") {
            setNovaMorada({
                ...billingAddress,
                nif: value
            });
            return;
        }

        if (value.length < 10 && /^\d*$/.test(value)) {
            setNovaMorada({
                ...billingAddress,
                nif: value
            });
        }
    }

    const handleAddBillingChangePais = (value: string) => {
        setNovaMorada({
            ...billingAddress,
            id_pais: value,
            nif: ""
        });
    }

    const handleFormSubmit = async () => {

        var resultValidation = ValidateBillingAddress(billingAddress, intl, dispatch);
        if (resultValidation.success === false) {
            Toast.Show("error", `${resultValidation.message}`);
            return;
        }

        setLoadingAddAddress(true);
        var resultAdd: any = await dispatch(addNewBillingAddress(billingAddress));
        setLoadingAddAddress(false);

        if (resultAdd.success === false) {
            Toast.Show("error", `${resultAdd.message}`);
            return;
        }

        Toast.Show("success", `${resultAdd.message}`);

        setNovaMorada(novaMoradaInitialState);

        //Dizemos que já não existe erro (para retirar a borda vermelha)
        dispatch(setErrorCodeCheckout(""))

        dispatch(addBillingAddressCheckout(resultAdd.obj));

        props.onClose();
    }

    const handleCleanAddBillingAddress = async () => {
        setNovaMorada(novaMoradaInitialState);
        props.onClose();
    };

    useEffect(() => {
        setNovaMorada({ ...billingAddress, nome: userToRegister.nomeCompleto })
    }, [userToRegister?.nomeCompleto])

    return (
        <Row style={{ textAlign: "center" }}>
            <div className="billingAddAddress">
                <Col xs={12} sm={12} md={12} lg={12}>
                    <Row className="billingRowTitleAddress">
                        <span className="titleOptionsAddress">
                            <FormattedMessage id="facturacao.novaMorada" />
                        </span>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>
                            <IHTPInput label={intl.formatMessage({ id: "facturacao.novaMorada.nome.label" })}
                                placeholder={intl.formatMessage({ id: "facturacao.novaMorada.nome" })}
                                onChange={(text) => setNovaMorada({ ...billingAddress, nome: text })}
                                type="text" controlValue={true} value={billingAddress.nome}
                                disabled={disabledFields}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPInput label={intl.formatMessage({ id: "facturacao.novaMorada.morada.label" })}
                                placeholder={intl.formatMessage({ id: "facturacao.novaMorada.morada" })}
                                onChange={(text) => setNovaMorada({ ...billingAddress, morada: text })}
                                type="text" controlValue={true} value={billingAddress.morada}
                                disabled={disabledFields}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPInput label={intl.formatMessage({ id: "facturacao.novaMorada.localidade.label" })}
                                placeholder={intl.formatMessage({ id: "facturacao.novaMorada.localidade" })}
                                onChange={(text) => setNovaMorada({ ...billingAddress, localidade: text })}
                                type="text" controlValue={true} value={billingAddress.localidade}
                                disabled={disabledFields}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPInput label={intl.formatMessage({ id: "facturacao.novaMorada.codigoPostal.label" })}
                                placeholder={intl.formatMessage({ id: "facturacao.novaMorada.codigoPostal" })}
                                onChange={(text) => setNovaMorada({ ...billingAddress, codigoPostal: text })}
                                type="text" controlValue={true} value={billingAddress.codigoPostal}
                                disabled={disabledFields}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPSelect label={intl.formatMessage({ id: "facturacao.novaMorada.id_pais.label" })}
                                options={paisesOptions}
                                placeholderItem={intl.formatMessage({ id: "facturacao.novaMorada.id_pais" })}
                                onChange={(text) => handleAddBillingChangePais(text)}
                                controlValue={true} value={billingAddress.id_pais}
                                disabled={disabledFields}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPInput label={intl.formatMessage({ id: "facturacao.novaMorada.nif.label" })}
                                placeholder={billingAddress.id_pais
                                    ? intl.formatMessage({ id: "facturacao.novaMorada.nif" })
                                    : intl.formatMessage({ id: "facturacao.novaMorada.nif1" })}
                                onChange={(text) => handleAddAddressValidateNif(text)}
                                type="text" controlValue={true} value={billingAddress.nif}
                                disabled={disabledFields}
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <IHTPSelect label={intl.formatMessage({ id: "facturacao.novaMorada.padrao.label" })}
                                options={defaultAddressOptions}
                                hasPlaceholder={false}
                                onChange={(text) => setNovaMorada({ ...billingAddress, principal: text })}
                                controlValue={true} value={billingAddress.principal}
                                width="auto"
                                disabled={disabledFields}
                            />

                            <IHTPSpacer verticalSpace={"20px"} />

                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                <Col xl={6} lg={6} md={8} style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "1rem" }}>
                                    {user.userID != null && typeof user.userID != 'undefined'
                                        ?
                                        <>
                                            <IHTPButton text={intl.formatMessage({ id: "facturacao.novaMorada.addMoradasButton" })} loading={loadingAddAddress} onClick={handleFormSubmit} />

                                            {typeof billingAddresses !== "undefined" && billingAddresses != null && Array.isArray(billingAddresses) && billingAddresses.length !== 0 ? (
                                                <IHTPButton text={intl.formatMessage({ id: "common.button.cancelar" })} buttonStyle="secondary" onClick={handleCleanAddBillingAddress} disabled={loadingAddAddress} />
                                            ) : null}
                                        </>
                                        : null}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>{" "}
            </div>
        </Row>
    )
}
