import React, { Component } from "react";
import { FaInstagram } from "react-icons/fa";
export default class Instagram extends Component {
  render() {
    return (
      <a className="icons" href="https://www.instagram.com/ihavethepowerglobal/" target="_blank">
        <FaInstagram />
      </a>
    );
  }
}