
import React, { useEffect } from 'react'
import { ImCheckmark, ImCross } from 'react-icons/im';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';

export interface PasswordCheckListProps {
    password: string,
    confirmPassword?: string,
    minimumCharacters?: number,
    shouldHaveAtLeastOneLetter?: boolean,
    shouldHaveAtLeastOneNumber?: boolean,
    shouldHaveAtLeastOneCapitalLetter?: boolean,
    shouldHaveAtLeastOneLowercaseLetter?: boolean,
    matchPasswords?: boolean,
    onChange: (isValid: boolean) => void,
    showComponent?: boolean
}

export default function PasswordCheckList(props: PasswordCheckListProps) {

    const PasswordCheckItem = (textId: string, valid: boolean) => {
        return (
            <Row style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                <Col md={1} sm={1} xs={1}>
                    {valid == true ? <ImCheckmark color="green" /> : <ImCross color="red" />}
                </Col>
                <Col style={{ textAlign: "left", fontSize: "13px", fontWeight: 600, opacity: valid == true ? 1 : 0.65 }}>
                    <FormattedMessage id={textId} />
                </Col>
            </Row>
        );
    }

    //Verifica se tem mais do que x caracteres
    const validateCharacters = () => {
        if (typeof props.minimumCharacters != 'undefined') {
            if (typeof props.password == 'undefined') return false;
            if (props.password == null) return false;
            if (props.password.length < props.minimumCharacters) return false;
        }

        return true;
    }

    const validateHasOneLowerCaseLetter = () => {
        if (typeof props.shouldHaveAtLeastOneLowercaseLetter != 'undefined' && props.shouldHaveAtLeastOneLowercaseLetter == true) {
            var checkOneLetter = /^(.*[a-z].*)$/.test(props.password);
            return checkOneLetter;
        }

        return true;
    }

    const validateHasOneLetter = () => {
        if (typeof props.shouldHaveAtLeastOneLetter != 'undefined' && props.shouldHaveAtLeastOneLetter == true) {
            var checkOneLetter = /^(.*[a-zA-Z].*)$/.test(props.password);
            return checkOneLetter;
        }

        return true;
    }

    const validateHasOneCapitalLetter = () => {
        if (typeof props.shouldHaveAtLeastOneCapitalLetter != 'undefined' && props.shouldHaveAtLeastOneCapitalLetter == true) {
            var checkOneLetter = /^(.*[A-Z].*)$/.test(props.password);
            return checkOneLetter;
        }

        return true;
    }

    const validateHasOneNumber = () => {
        if (typeof props.shouldHaveAtLeastOneNumber != 'undefined' && props.shouldHaveAtLeastOneNumber == true) {
            var checkOneLetter = /^(.*\d.*)$/.test(props.password);
            return checkOneLetter;
        }

        return true;
    }

    const validateMatchPasswords = () => {
        if (typeof props.matchPasswords != 'undefined' && props.matchPasswords == true) {
            if (props.password.length <= 0) return false;
            if (props.password != props.confirmPassword) return false;
        }

        return true;
    }

    const verifyIfValid = () => {
        var isValid: boolean = (
            validateCharacters() == true &&
            validateHasOneLowerCaseLetter() == true &&
            validateHasOneLetter() == true &&
            validateHasOneCapitalLetter() == true &&
            validateHasOneNumber() == true &&
            validateMatchPasswords() == true
        );
        props.onChange(isValid);
    }

    useEffect(() => {
        verifyIfValid();
        return () => {

        }
    }, [props.password])

    useEffect(() => {
        verifyIfValid();
        return () => {

        }
    }, [props.confirmPassword])

    if (props.showComponent == false) return null;

    return (
        <div>
            {typeof props.minimumCharacters != 'undefined' && (
                PasswordCheckItem("definirPassword.minimoCaracteres", validateCharacters())
            )}

            {typeof props.shouldHaveAtLeastOneLetter != 'undefined' && props.shouldHaveAtLeastOneLetter == true && (
                PasswordCheckItem("definirPassword.minimo1Letra", validateHasOneLetter())
            )}

            {typeof props.shouldHaveAtLeastOneNumber != 'undefined' && props.shouldHaveAtLeastOneNumber == true && (
                PasswordCheckItem("definirPassword.minimo1Numero", validateHasOneNumber())
            )}

            {typeof props.shouldHaveAtLeastOneLowercaseLetter != 'undefined' && props.shouldHaveAtLeastOneLowercaseLetter == true && (
                PasswordCheckItem("definirPassword.minimo1LetraMinuscula", validateHasOneLowerCaseLetter())
            )}

            {typeof props.shouldHaveAtLeastOneCapitalLetter != 'undefined' && props.shouldHaveAtLeastOneCapitalLetter == true && (
                PasswordCheckItem("definirPassword.minimo1LetraMaiscula", validateHasOneCapitalLetter())
            )}

            {typeof props.matchPasswords != 'undefined' && props.matchPasswords == true && (
                PasswordCheckItem("definirPassword.passwordsIguais", validateMatchPasswords())
            )}
        </div>
    )
}




