import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Modal, Row } from "reactstrap";
import ouibounce from "./../Common/ouibounce";

class DetectLeave extends Component {

    constructor(props) {
        super(props);
        this.state = {
            handler: null,
            open: false
        }

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        const handler = this.setupOuibounce()
        this.setState({ handler })
    }


    setupOuibounce() {
        return ouibounce(false, {
            sensitivity: 100,
            cookieName: "userExitIntentAction",
            cookieExpire: 0.04, //Está assim para expirar em cerca de 1h 
            sitewide: true,
            precondition: () => {

                var pathName = this.props.location?.pathname;
                //Se o caminho for igual ao do Checkout saimos
                if (pathName.toLowerCase() == "/shop/checkout") return false;

                if (pathName.toLowerCase().startsWith("/shop/payment")) return false;

                //Caso tenhamos produtos no carrinho
                if (typeof this.props.cartNumberItems !== 'undefined' && this.props.cartNumberItems > 0) {
                    return true;
                }
                return false;
            },
            callback: () => {

                //Mostramos o modal a afirmar que tem produtos no carrinho
                this.openModal();
            }
        })

    }

    openModal() {
        this.setState(prevState => ({
            ...prevState,
            open: true
        }));
    }

    closeModal() {
        this.setState(prevState => ({
            ...prevState,
            open: false
        }));
    }


    render() {
        var userIsLoggedIn = (typeof this.props.user.userID !== 'undefined' && this.props.user.userID != null);
        return (
            <div>
                <Modal isOpen={this.state.open} toggle={this.closeModal} centered={true}>
                    <div style={{ padding: "20px", fontWeight: "500" }}>
                        <Row style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                            <Col md={8}>
                                <label style={{ fontSize: "25px", fontWeight: "500" }}>
                                    {userIsLoggedIn ? (
                                        <span>
                                            <FormattedMessage id="sairSite.cumprimento" /> {this.props.user.userName}
                                        </span>
                                    ) :
                                        <FormattedMessage id="sairSite.cumprimentoGeral" />
                                    }
                                    < span role="img" aria-label="Smile"> 😊</span>
                                </label>
                            </Col>
                            <Col md={4} style={{ textAlign: "right", fontWeight: "500", fontSize: "25px", color: "darkgray" }}>
                                <label style={{ cursor: "pointer" }} onClick={this.closeModal}>x</label>
                            </Col>
                        </Row>
                        <label><FormattedMessage id="sairSite.esqueceuProdutos" /></label>
                        <Row style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <Col style={{ textAlign: "center" }}>
                                <Button color="primary" onClick={() => {
                                    window.location = "/Carrinho"
                                }}><FormattedMessage id="sairSite.button" /></Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        cartNumberItems: state.cartNumberItems,
        user: state.user
    };
};

export default withRouter(connect(mapStateToProps)(DetectLeave));
