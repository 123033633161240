import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CheckoutDesktop from "../../../components/Checkout/Checkout";
import HeaderDesktop from "../../../components/Desktop/Header/Header";
import Footer from "../../../components/Footer/Footer";

export default class Checkout extends Component {
  render() {
    return (
      <div className="App">
        <HeaderDesktop />
        <Container className="mainContentContainer">
          <Row style={{ textAlign: "center" }}>
            <Col xs="12" sm="12" md="12" lg="12">
              <CheckoutDesktop />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}