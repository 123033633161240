import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage, injectIntl } from "react-intl";

class CartIsEmpty extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <h3><FormattedMessage id="shop.cart.carrinho.carrinhoVazio" /></h3>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <a href={this.props.intl.formatMessage({ id: "header.storeURL" })}>
                            <Button
                                aria-label="Increment"
                                style={{
                                    backgroundColor: "#ca006a",
                                    border: "none",
                                    minWidth: isMobile ? "50%" : "20%"
                                }}>
                                <FormattedMessage id="shop.cart.carrinho.irParaLoja" />
                            </Button>
                        </a>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default injectIntl(CartIsEmpty);