import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";

class SocialMedia extends Component {

  render() {
    return (
      <Row>
        <Col xs="12" sm="12" md="12" lg="12" style={{ padding: "0px" }}>
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              <span className="text404" style={{ display: "block" }}>
                #IHTPGLOBAL
              </span>
              <span className="text404">Explore o que fazemos nas redes sociais
              </span>
            </Col>
          </Row>
          <br />

          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col xs="12" sm="12" md="12" lg="12" xl={8} style={{ padding: "0px" }}>
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="3"
                  lg="3"
                  style={{ marginBottom: "10px" }}
                  onClick={function () {
                    window.open('https://www.facebook.com/IHTPGLOBAL/', '_blank');
                  }}
                  id="socialMedia"
                >
                  <picture>
                    <source srcset="https://doremon.ihavethepower.net/assets/images/homepage/facebook.webp" type="image/webp" />
                    <source srcset="https://doremon.ihavethepower.net/assets/images/homepage/facebook.png" type="image/png" />
                    <img src="https://doremon.ihavethepower.net/assets/images/homepage/facebook.webp"
                      alt="Facebook" style={{ width: "100%" }} />
                  </picture>
                </Col>
                <br />
                <Col
                  xs="12"
                  sm="12"
                  md="3"
                  lg="3"
                  style={{ marginBottom: "10px" }}
                  onClick={function () {
                    window.open('https://www.instagram.com/ihavethepowerglobal', '_blank');
                  }}
                  id="socialMedia"
                >
                  <picture>
                    <source srcset="https://doremon.ihavethepower.net/assets/images/homepage/instagram.webp" type="image/webp" />
                    <source srcset="https://doremon.ihavethepower.net/assets/images/homepage/instagram.png" type="image/png" />
                    <img src="https://doremon.ihavethepower.net/assets/images/homepage/instagram.webp"
                      alt="Instagram" style={{ width: "100%" }} />
                  </picture>
                </Col>
                <Col
                  xs="12"
                  sm="12"
                  md="3"
                  lg="3"
                  style={{ marginBottom: "10px" }}
                  onClick={function () {
                    window.open('https://www.linkedin.com/company/solfut-lda---i-have-the-power', '_blank');
                  }}
                  id="socialMedia"
                >
                  <picture>
                    <source srcset="https://doremon.ihavethepower.net/assets/images/homepage/linkedin.webp" type="image/webp" />
                    <source srcset="https://doremon.ihavethepower.net/assets/images/homepage/linkedin.png" type="image/png" />
                    <img src="https://doremon.ihavethepower.net/assets/images/homepage/linkedin.webp"
                      alt="Linkedin" style={{ width: "100%" }} />
                  </picture>

                </Col>
                <Col
                  xs="12"
                  sm="12"
                  md="3"
                  lg="3"
                  style={{ marginBottom: "10px" }}
                  onClick={function () {
                    window.open('https://www.youtube.com/user/solfut', '_blank');
                  }}
                  id="socialMedia"
                >
                  <picture>
                    <source srcset="https://doremon.ihavethepower.net/assets/images/homepage/youtube.webp" type="image/webp" />
                    <source srcset="https://doremon.ihavethepower.net/assets/images/homepage/youtube.png" type="image/png" />
                    <img src="https://doremon.ihavethepower.net/assets/images/homepage/youtube.webp"
                      alt="Youtube" style={{ width: "100%" }} />
                  </picture>

                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
export default SocialMedia;
