import React, { CSSProperties } from 'react'
import { ImCheckmark } from 'react-icons/im'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'


const advantagesHeader: CSSProperties = {
    color: "green",
    display: "block",
    textAlign: "left",
    marginTop: "1.5rem",
    marginBottom: "0.6rem",
    fontWeight: "500",
    fontSize: "1.05rem"
}

const advantagesLabel: CSSProperties = {
    display: "block",
    textAlign: "left",
    marginLeft: "0.8em",
    fontSize: "13px",
    fontWeight: "500"
}

export default function RegisterBenefits() {

    const user = useSelector((state: any) => state.user);
    const intl = useIntl();

    return (
        <>
            <span style={advantagesHeader}>{intl.formatMessage({ id: "registar.vantagens.titulo" })}</span>
            {
                user.language?.toUpperCase() != "EN" ?
                    <label style={advantagesLabel}><ImCheckmark color="green" />  <FormattedMessage id="registar.vantagens.1" /></label>
                    : <></>
            }
            <label style={advantagesLabel}><ImCheckmark color="green" />  <FormattedMessage id="registar.vantagens.2" /></label>
            <label style={advantagesLabel}><ImCheckmark color="green" />  <FormattedMessage id="registar.vantagens.3" /></label>
            <label style={advantagesLabel}><ImCheckmark color="green" />  <FormattedMessage id="registar.vantagens.4" /></label>
            <label style={advantagesLabel}><ImCheckmark color="green" />  <FormattedMessage id="registar.vantagens.5" /></label>
            <label style={advantagesLabel}><ImCheckmark color="green" />  <FormattedMessage id="registar.vantagens.6" /></label>
            <label style={advantagesLabel}><ImCheckmark color="green" />  <FormattedMessage id="registar.vantagens.7" /></label>
        </>
    )
}
