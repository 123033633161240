import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./Payment.css";

export interface PaymentMBWayProps {
  numtelemovel: string,
  idEncomenda: number
}

export default function PaymentMBWay({ numtelemovel, idEncomenda }: PaymentMBWayProps) {
  return (
    <Row className="payment" >
      <Col xs={5} sm={5} md={5} lg={5} style={{ display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center" }}>
        <img
          src="https://doremon.ihavethepower.net/assets/images/MBWay.png"
          className="logoPayment"
          alt=""
        />
      </Col>
      <Col xs={7} sm={7} md={7} lg={7}>
        <p><FormattedMessage id="pagamento.alterarTipoPagamento.mbWay.p1" /> (<b>{numtelemovel}</b>) <FormattedMessage id="pagamento.alterarTipoPagamento.mbWay.p2" /></p>
        <p style={{ fontSize: "16px", fontWeight: "600" }}><FormattedMessage id="pagamento.alterarTipoPagamento.mbWay.p3" /> - {idEncomenda}</p>
      </Col>
    </Row>
  );
}
