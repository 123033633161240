import React from 'react'

export interface IHTPSpacerProps {
    verticalSpace: string
}

export default function IHTPSpacer(props: IHTPSpacerProps) {
    return (
        <div style={{ display: "block", marginBottom: props.verticalSpace }} />
    )
}
