// window.dataLayer = window.dataLayer || [];

const _ = require("lodash");

const datalayer = {};

// Função para transformar produtos em produtos de analytics
var productsToproductsAnalytics = function (orderProducts) {
  var items = [];
  orderProducts.forEach(product => {
    items.push({
      item_name: product.nome,
      item_id: product.id,
      price: product.preco,
      item_category: product.tipoProduto,
      quantity: product.quantidade
    })
  });

  return items;
};

var productsToproductsAnalytics_Old = function (products) {
  var productAux = {},
    productsAnalytics = [];

  products.map(function (x, index) {
    productAux = {
      // Id de produto
      id: !_.isUndefined(x.ID_Produto) ? "P" + x.ID_Produto : "K" + x.ID_Pack,
      // Nome de produto
      name: x.Descricao,
      // Categoria do produto
      category: !_.isUndefined(x.ID_TipoProduto)
        ? "P-" + x.ID_TipoProduto
        : "K-" + x.ID_TipoPack,
      price: x.price,
      quantity: x.quantity
    };
    // Adicionar ao array de produtos
    productsAnalytics.push(productAux);
  });
  return productsAnalytics;
};

// Adicionar ao carrinho
export function addToCart(product) {
  datalayer.addToCart = {
    event: "add_to_cart",
    ecommerce: {
      items: [{
        item_name: product.Descricao,
        item_id: !_.isUndefined(product.ID_Produto)
          ? "P" + product.ID_Produto
          : "K" + product.ID_Pack,
        price: product.price,
        item_category: !_.isUndefined(product.ID_TipoProduto)
          ? "P-" + product.ID_TipoProduto
          : "K-" + product.ID_TipoPack,
        quantity: product.quantity
      }]
    }
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(datalayer.addToCart);
}

export function addToCart_Old(product) {
  datalayer.addToCart = {
    event: "eec.addToCart",
    ecommerce: {
      add: {
        actionField: {
          list: product.ID_TipoProduto
        },
        products: [
          {
            id: !_.isUndefined(product.ID_Produto)
              ? "P" + product.ID_Produto
              : "K" + product.ID_Pack,
            name: product.Descricao,
            category: !_.isUndefined(product.ID_TipoProduto)
              ? "P-" + product.ID_TipoProduto
              : "K-" + product.ID_TipoPack,
            quantity: product.quantity
          }
        ]
      }
    }
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(datalayer.addToCart);
}

// Remover do carrinho
export function removeFromCart(product) {
  datalayer.removeFromCart = {
    event: "remove_from_cart",
    ecommerce: {
      items: [{
        item_name: product.Descricao,
        item_id: product.ID_Produto !== null
          ? "P" + product.ID_Produto
          : "K" + product.ID_Pack,
        price: product.price,
        item_category: product.ID_TipoProduto !== null
          ? "P-" + product.ID_TipoProduto
          : "K-" + product.ID_TipoPack,
        quantity: product.quantity
      }]
    }
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(datalayer.removeFromCart);
}

export function removeFromCart_Old(product) {
  datalayer.removeFromCart = {
    event: "eec.remove",
    ecommerce: {
      remove: {
        actionField: {
          list: product.ID_TipoProduto
        },
        products: [
          {
            id:
              product.ID_Produto !== null
                ? "P" + product.ID_Produto
                : "K" + product.ID_Pack,
            name: product.Descricao,
            category:
              product.ID_TipoProduto !== null
                ? "P-" + product.ID_TipoProduto
                : "K-" + product.ID_TipoPack,
            quantity: product.quantity
          }
        ]
      }
    }
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(datalayer.removeFromCart);
}

export function beginCheckout(orderProducts) {
  var items = productsToproductsAnalytics(orderProducts);

  datalayer.beginCheckout = {
    event: "begin_checkout",
    ecommerce: {
      items: items
    }
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(datalayer.beginCheckout);
}

// Adicionar morada de envio
export function funnelStep(step) {
  datalayer.checkout = {
    event: "eec.checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: step
        }
        /*products: [
          {
            id:
              product.ID_Produto !== null
                ? "P" + product.ID_Produto
                : "K" + product.ID_Pack,
            name: product.Descricao,
            category:
              product.ID_TipoProduto !== null
                ? "P-" + product.ID_TipoProduto
                : "K-" + product.ID_TipoPack,
            quantity: product.quantity
          }
        ]*/
      }
    }
  };


  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(datalayer.checkout);
}

// Adicionar morada de envio
export function checkout_MoradaEnvio(product) {
  datalayer.checkout_MoradaEnvio = {
    event: "eec.checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: 2
        },
        products: [
          {
            id:
              product.ID_Produto !== null
                ? "P" + product.ID_Produto
                : "K" + product.ID_Pack,
            name: product.Descricao,
            category:
              product.ID_TipoProduto !== null
                ? "P-" + product.ID_TipoProduto
                : "K-" + product.ID_TipoPack,
            quantity: product.quantity
          }
        ]
      }
    }
  };
}

// Adicionar morada de faturação
export function checkout_MoradaFaturacao(product) {
  datalayer.checkout_MoradaFaturacao = {
    event: "eec.checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: 3
        },
        products: [
          {
            id:
              product.ID_Produto !== null
                ? "P" + product.ID_Produto
                : "K" + product.ID_Pack,
            name: product.Descricao,
            category:
              product.ID_TipoProduto !== null
                ? "P-" + product.ID_TipoProduto
                : "K-" + product.ID_TipoPack,
            quantity: product.quantity
          }
        ]
      }
    }
  };
}

// Adicionar meio de envio
export function checkout_MeioEnvio_Old(product) {
  datalayer.checkout_MeioEnvio = {
    event: "eec.checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: 4
        },
        products: [
          {
            id:
              product.ID_Produto !== null
                ? "P" + product.ID_Produto
                : "K" + product.ID_Pack,
            name: product.Descricao,
            category:
              product.ID_TipoProduto !== null
                ? "P-" + product.ID_TipoProduto
                : "K-" + product.ID_TipoPack,
            quantity: product.quantity
          }
        ]
      }
    }
  };
}

// Adicionar meio de envio
export function checkout_MeioEnvio(orderProducts, shippment_type) {
  var items = productsToproductsAnalytics(orderProducts);

  datalayer.checkout_MeioEnvio = {
    event: "add_shipping_info",
    ecommerce: {
      shipping_tier: shippment_type,
      items: items
    }
  };

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(datalayer.checkout_MeioEnvio);
}

// Adicionar metodo de pagamento
export function checkout_MetodoPagamento(orderProducts, paymentType) {
  var items = productsToproductsAnalytics(orderProducts);

  datalayer.checkout_MetodoPagamento = {
    event: "add_payment_info",
    ecommerce: {
      payment_type: paymentType,
      items: items
    }
  };

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(datalayer.checkout_MetodoPagamento);
}

export function checkout_MetodoPagamento_Old(product) {
  datalayer.checkout_MetodoPagamento = {
    event: "eec.checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: 5
        },
        products: [
          {
            id:
              product.ID_Produto !== null
                ? "P" + product.ID_Produto
                : "K" + product.ID_Pack,
            name: product.Descricao,
            category:
              product.ID_TipoProduto !== null
                ? "P-" + product.ID_TipoProduto
                : "K-" + product.ID_TipoPack,
            quantity: product.quantity
          }
        ]
      }
    }
  };
}

// Adicionar metodo de pagamento
export function checkout_Encomendar(order, orderProducts) {
  // Obter os produtos formatados para analytics
  var items = productsToproductsAnalytics(orderProducts);
  // Criação de dataLayer de compra
  datalayer.checkout_Encomendar = {
    event: "purchase",
    ecommerce: {
      currency: "EUR",
      value: order.precoFinal,
      tax: order.precoTaxa,
      affiliation: "Loja Online",
      transaction_id: order.IDEncomenda,
      coupon: order.codigoPromocional,
      items: items
    }
  };

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(datalayer.checkout_Encomendar);
}

export function checkout_Encomendar_Old(order, products) {
  // Obter os produtos formatados para analytics
  var productsAnalytics = productsToproductsAnalytics_Old(products);
  // Criação de dataLayer de compra
  datalayer.checkout_Encomendar = {
    event: "eec.purchase",
    ecommerce: {
      currencyCode: "EUR",
      purchase: {
        actionField: {
          id: order.IDEncomenda,
          affiliation: "Loja Online",
          revenue: order.precoFinal,
          tax: order.precoTaxa,
          shipping: order.precoEnvio,
          coupon: order.CodigoPromocional
        },
        products: productsAnalytics
      }
    }
  };

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(datalayer.checkout_Encomendar);
}

export function view_cart(products) {
  // Obter os produtos formatados para analytics
  var items = productsToproductsAnalytics(products);
  // Criação de dataLayer de compra
  datalayer.view_cart = {
    event: "view_cart",
    ecommerce: {
      items: items
    }
  };

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(datalayer.view_cart);
}