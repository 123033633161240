import RoutesHelper from "../../helpers/Routes";

export const menuSobreNosParte1 = [
    {
        link: RoutesHelper.empresa.empresa,
        labelId: "footer.title.Empresa"
    },
    {
        link: RoutesHelper.empresa.fundador,
        labelId: "footer.title.fundador"
    },
    {
        link: RoutesHelper.empresa.testemunhos,
        labelId: "footer.title.testemunhos"
    },
    {
        link: RoutesHelper.empresa.noticias,
        labelId: "footer.title.noticias"
    },
    {
        link: RoutesHelper.empresa.imprensa,
        labelId: "footer.title.radio"
    }
]

export const menuSobreNosParte2 = [
    {
        link: RoutesHelper.empresa.protecaoDados,
        labelId: "footer.title.protecaodados"
    },
    {
        link: RoutesHelper.empresa.cancelamentoGarantia,
        labelId: "footer.title.cancelamento"
    },
    {
        link: RoutesHelper.empresa.associacao,
        labelId: "footer.title.social"
    },
]

export const menuEmpresas = [
    {
        link: RoutesHelper.empresas.empresas,
        labelId: "footer.empresas.oferta"
    },
    {
        link: RoutesHelper.empresas.solucoes,
        labelId: "footer.empresas.solucoes"
    },
    {
        link: RoutesHelper.empresas.palestras,
        labelId: "footer.empresas.palestras"
    },
    {
        link: RoutesHelper.empresas.coaching,
        labelId: "footer.empresas.coaching"
    },
]

export const menuParticulares = [
    {
        link: RoutesHelper.particulares.oferta,
        labelId: "footer.particulares.oferta"
    },
    {
        link: RoutesHelper.particulares.cursosEventos,
        labelId: "footer.particulares.cursosEventos"
    },
    {
        link: RoutesHelper.particulares.produtos,
        labelId: "footer.particulares.produtos"
    },
    {
        link: RoutesHelper.particulares.coaching,
        labelId: "footer.particulares.coaching"
    },
]

export const menuConsultor = [
    {
        link: RoutesHelper.consultor.oque,
        labelId: "footer.consultor.oque"
    },
    {
        link: RoutesHelper.consultor.beneficios,
        labelId: "footer.consultor.beneficios"
    },
    {
        link: RoutesHelper.consultor.passoAPasso,
        labelId: "footer.consultor.passoAPasso"
    },
]

export const menuRecursos = [
    {
        link: RoutesHelper.recursosGratis.ebooks,
        labelId: "footer.recursosGratis.ebooks"
    },
    {
        link: RoutesHelper.recursosGratis.afiliados,
        labelId: "footer.recursosGratis.afiliados"
    },
    {
        link: RoutesHelper.recursosGratis.desafios,
        labelId: "footer.recursosGratis.desafios"
    },
    {
        link: RoutesHelper.recursosGratis.frases,
        labelId: "footer.recursosGratis.frases"
    },
]