import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getRelatedProductsFromOrder, incrementarProdutoCart } from "../../../actions/miniCartActions";
import Toast from '../../../helpers/Toast';
import useCurrencies from '../../../hooks/useCurrencies';
import RelatedProductItemOrder from './Components/RelatedProductItemOrder';
import "./RelatedProductsToOrder.css";

interface RelatedProductsToOrderProps {
    hashOrder: string
}

interface Product {
    ItemId: number,
    ItemTitle: string,
    ImageUrl: string,
    Price: number,
    PriceToOtherUsers: number,
    PriceWithoutDiscount: number
}

export default function RelatedProductsToOrder({ hashOrder }: RelatedProductsToOrderProps) {
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const numberDefaultProducts = 3;

    const dispatch = useDispatch();
    const intl = useIntl();

    const currencies = useCurrencies();
    const currency = currencies.selectedDescription;

    var existsRelatedProducts = false;

    if (typeof products != 'undefined' && products != null && products.length > 0) {
        existsRelatedProducts = true;
    }


    useEffect(() => {
        loadRelatedProducts();
    }, [])

    const loadRelatedProducts = () => {
        setLoading(true);

        getRelatedProductsFromOrder(hashOrder).then(result => {
            if (result.success === true) {
                var items = result.obj;
                const products: Product[] = items.map(i => {
                    return {
                        ItemTitle: i.description,
                        ImageUrl: i.imageUrl,
                        ItemId: i.id,
                        Price: i.price,
                        PriceToOtherUsers: i.priceToOtherUsers,
                        PriceWithoutDiscount: i.priceWithoutDiscount
                    } as Product
                });


                setLoading(false);
                setProducts(products)
            }
        });
    }

    useEffect(() => {
        loadRelatedProducts();
    }, [currency])

    const renderDefaultProducts = () => {
        var elements: JSX.Element[] = [];
        for (var i = 0; i < numberDefaultProducts; i++) {
            elements.push(<RelatedProductItemOrder Loading={true} Key={i} />);
        }

        return elements;
    }

    const AddProductToCart = (id: number) => {
        dispatch(incrementarProdutoCart(id, "ADD"));
        Toast.Show("success", intl.formatMessage({ id: "shop.cart.produtosRelacionados.mensagemAdicionar" }))
    };


    const renderRelatedProducts = (currency: string) => {
        return (
            products.map(p => {
                return (<RelatedProductItemOrder ItemId={p.ItemId} ItemTitle={p.ItemTitle} ImageURL={p.ImageUrl} Key={p.ItemId}
                    Currency={currency}
                    Price={p.Price} PriceToOtherUsers={p.PriceToOtherUsers} PriceWithoutDiscount={p.PriceWithoutDiscount}
                    OnButtonClick={AddProductToCart}
                    Loading={false}
                />);
            })
        )
    }


    return (
        <div className="relatedProductsContainerOrder">
            {products.length > 0 &&
                <>
                    <div className="relatedProductsTitleParentOrder">
                        <FormattedMessage id="shop.cart.produtosRelacionados.title" />
                    </div>

                    <div className="relatedProductsItemsContainerOrder">

                        {loading === true ? (
                            renderDefaultProducts()
                        ) : (
                            renderRelatedProducts(currency)
                        )}

                    </div>
                </>
            }
        </div>
    )
}