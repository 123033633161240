import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { getEbanxCheckoutURL } from "../../actions/miniCartActions";
import "./Payment.css";

export interface PaymentEbanxProps {
  hash: string
}

export default function PaymentEbanx({ hash }: PaymentEbanxProps) {
  const [checkoutUrl, setCheckoutUrl] = useState<string>("");

  useEffect(() => {
    getEbanxCheckoutURL().then(value => {
      setCheckoutUrl(value);
    })
  }, [])

  return (
    <Row className="payment" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Col xs={6} sm={6} md={6} lg={6}>
        <img
          src="https://doremon.ihavethepower.net/assets/images/ebanx.svg"
          className="logoPayment"
          alt=""
        />
      </Col>
      <Col xs={6} sm={6} md={6} lg={6}>
        <p><FormattedMessage id="pagamento.alterarTipoPagamento.p1" /> </p>
        <a href={checkoutUrl + hash} target="blank">
          <FormattedMessage id="pagamento.alterarTipoPagamento.p2" />
        </a>
      </Col>
    </Row>
  );
}