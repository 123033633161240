import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import RoutesHelper from "../../helpers/Routes";
import SocialMedia from "../SocialMedia/SocialMedia";
import FooterColumn from "./Components/FooterColumn";
import FooterMenuItem from "./Components/FooterMenuItem";
import FooterPartners from "./Components/FooterPartners";
import FooterSocialMedia from "./Components/FooterSocialMedia";
import "./Footer.css";
import "./FooterColumn.css";
import "./FooterPartners.css";
import {
  menuSobreNosParte1, menuSobreNosParte2, menuEmpresas,
  menuParticulares, menuConsultor, menuRecursos
} from "./FooterMenus";


class Footer extends Component {
  render() {
    return (
      <Container className="footer">
        {/* <Row className="footerFirstSectionContainer">
          <Col md={12} className="footerFirstSection">
            <Row className="footerMainContainer">

              <FooterColumn menuItems={menuSobreNosParte1} title={<FormattedMessage id="footer.title.sobrenos" />} />
              <FooterColumn menuItems={menuSobreNosParte2} />
              <FooterColumn menuItems={menuEmpresas} title={<FormattedMessage id="footer.empresas" />} />
              <FooterColumn menuItems={menuParticulares} title={<FormattedMessage id="footer.particulares" />} />
              <FooterColumn menuItems={menuConsultor} title={<FormattedMessage id="footer.consultor" />} />
              <FooterColumn menuItems={menuRecursos} title={<FormattedMessage id="footer.recursosGratis" />} />

              <Col md={12} className="footerColumnContainer">
                <FooterMenuItem title="Home" />
                <FooterMenuItem title="Loja" />
                <FooterMenuItem title="Blog" />

                <FooterMenuItem title="Contactos" customStyle={{ marginTop: "20px" }} >
                  <a style={{ display: "block" }} href="tel:224053270">
                    (+351) <span style={{ fontWeight: "bold" }}>224 053 270</span>
                  </a>
                  <a href="mailto:ihavethepower@solfut.com" style={{ display: "block" }}>
                    ihavethepower@solfut.com
                </a>
                </FooterMenuItem>
              </Col>





              <FooterPartners />

              <Col xs="12" sm="12" md="12" lg="12">
                <FooterSocialMedia />
              </Col>

            </Row>
          </Col>
        </Row> */}
        <Row className="footerSecondSectionContainer">
          <Col md={12} className="footerSecondSection">
            <Row className="footerCopyright">
              <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                <span href="https://solfut.com" className="footerCopyrightText">©SOLFUT LDA. – <FormattedMessage id="footer.direitosReservados" /></span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Footer;

